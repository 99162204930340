import React from 'react';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const PERMISSIONS_TABLE_COLUMNS = [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    defaultSortOrder: 'ascend',
    sorter: (_a, _b) => Number(_a.id) - Number(_b.id),
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: <IntlMessages id='ui-general-name' />,
    dataIndex: 'name',
    width: '200px',
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id='ui-general-display-name' />,
    dataIndex: 'display_name',
    width: '200px',
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id='ui-general-description' />,
    dataIndex: 'description',
    width: '200px',
    render: (value) => <CollapsibleText text={value} />,
  },
];
