export const uniqueKeyValidation = (keysOnCurrentTreeLevel, value, currentKey, isEdit) => {
  if (!value) {
    return 'Key is required';
  }

  if (isEdit) {
    keysOnCurrentTreeLevel = keysOnCurrentTreeLevel.filter((key) => key !== currentKey);
  }

  if (keysOnCurrentTreeLevel.includes(value)) {
    return 'Key with this value is already present in current node. Try other name';
  }

  return null;
};
