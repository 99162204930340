import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';



export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `${Config.VITE_HC_API_VERSION}/login`,
        method: 'POST',
        body: credentials,
      }),
    }),

    refreshUser: builder.mutation({
      query: () => ({
        url: `${Config.VITE_HC_API_VERSION}/profile?include=roles,permissions`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginMutation, useRefreshUserMutation } = authApiSlice;
