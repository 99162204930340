import React from 'react';
import { Dropdown, Col, Divider, Row, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import avatarPlaceholder from '../../../assets/images/memoji/memoji-1.png';
import { logOut, selectUserData } from '../../../domains/Auth/features/authSlice';
import IntlMessages from '../lang/IntlMessages';
import urlPageProfile from '../../../urls/urlPageProfile';



const HeaderUser =  () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: profile } = useSelector(selectUserData);

  const signOut = () => {
    dispatch(logOut());
  };

  const menu = (
    <div className="hp-user-dropdown hp-border-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16">
      <span style={{ marginLeft: 24 }} className="hp-d-block h5 hp-font-weight-500 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-16">
        <IntlMessages id='layout-header-profile-card-title' />
      </span>

      <Button
        type='link'
        onClick={() => {
          navigate(urlPageProfile());
        }}
        className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
      >
        <IntlMessages id='layout-header-profile-card-view-profile' />
      </Button>

      <Divider className="hp-my-4" />

      <Row gutter={[ 0, 24 ]}>
        <Col span={24}>
          <Button
            type='link'
            to="#"
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
            onClick={signOut}
          >
            <IntlMessages id='ui-general-logout' />
          </Button>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col className="hp-ml-8">
      <Dropdown overlay={menu} placement="bottomLeft">
        <div className="hp-border-radius-xl hp-cursor-pointer hp-border-1 hp-border-color-dark-80">
          <div className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex hp-d-flex-justify-center" style={{ minWidth: 32, width: 32, height: 32 }}>
            <img src={profile?.avatar ?? avatarPlaceholder} alt="User" height="100%" style={{ maxWidth: 'none', width: '32px', objectFit: 'cover' }} />
          </div>
        </div>
      </Dropdown>
    </Col>
  );
};

export default HeaderUser;
