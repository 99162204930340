import React from 'react';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';



const Spinner = ({
  spinning = false,
  tip,
  style = {},
  children,
}) => {
  const intl = useIntl();

  return (
    <Spin
      style={style}
      spinning={spinning}
      tip={tip ?? intl.formatMessage({ id: 'ui-general-loading' })}
    >
      {children}
    </Spin>
  );
};

Spinner.propTypes = {
  spinning: PropTypes.bool,
  tip: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Spinner;
