const templates = {
  'templates-breadcrumbs': 'Шаблоны',
  'template-create-breadcrumbs': 'Конструктор',
  'templates-filter-title': 'Фильтр шаблонов',
  'templates-filter-form-id-placeholder': 'Id(s) шаблона',
  'templates-filter-form-title-placeholder': 'Название шаблона',
  'templates-clone-successfully': 'Шаблон клонирован',
  'templates-clone-failed': 'Ошибка при клонировании шаблона',
  'templates-create-form-title-label': 'Название шаблона',
  'templates-create-form-content-type-label': 'Тип контента шаблона',
  'templates-create-form-content-type-placeholder': 'Введите тип контента шаблона',
  'templates-create-form-app-key-label': 'Ключ шаблона',
  'templates-create-form-title-placeholder': 'Название шаблона',
  'templates-create-form-app-key-placeholder': 'Введите ключ шаблона',
  'templates-create-form-app-key-help': 'Slug - это уникальная строка идентификатор, понятная человеку и содержащая символы "-", "0-9", "A-Za-z"',
  'templates-create-form-priority-help': 'Выберите желаемый порядок отображения узлов',
  'templates-create-form-docs': 'Для создания шаблона задайте структуру',
  'templates-create-form-add-structure-root': 'Создать корневой узел структуры',
  'templates-create-form-add-structure-node': 'Создать узел',
  'templates-create-form-field-key': 'Ключ',
  'templates-create-form-field-key-placeholder': 'Введите ключ узла',
  'templates-create-form-field-title-ru': 'Заголовок (рус)',
  'templates-create-form-field-title-en': 'Заголовок (англ)',
  'templates-create-form-field-hint': 'Подсказка',
  'templates-create-form-field-hint-ru': 'Подсказка (рус)',
  'templates-create-form-field-hint-en': 'Подсказка (англ)',
  'templates-create-form-field-hint-placeholder': 'Опишите для чего нужен ключ',
  'templates-create-form-field-is-required': 'Обязательность',
  'templates-create-form-field-use-in-preset': 'Использование в пресете',
  'templates-create-form-field-use-in-preset-tooltip': 'Если не активно, данное поле будет пропущено в пресете',
  'templates-create-form-field-is-main': 'Отображать в основных деталях',
  'templates-create-form-field-not-main': 'Отображается в дополнительныч деталях',
  'templates-create-form-field-dictionary-structure': 'Структура словаря',
  'templates-create-form-field-dictionary-placeholder': 'Выберите структуру словаря',
  'templates-create-form-field-select': 'Список опций',
  'templates-create-form-field-select-placeholder': 'Выберите опцию',
  'templates-create-form-field-subtype': 'Тип значений',
  'templates-create-form-field-subtype-placeholder': 'Выберите тип значений',
  'templates-create-form-field-validation': 'Правила проверки',
  'templates-create-form-field-validation-option': 'Правило',
  'templates-create-form-field-validation-options': 'Правила: ',
  'templates-create-form-field-validation-placeholder': 'Введите правила проверки',
  'templates-create-form-field-default-value': 'Первичное значение ',
  'templates-create-form-field-default-value-placeholder': 'Введите первичное значение',
  'templates-create-form-field-value-boolean': 'булевое',
  'templates-create-form-field-value-boolean-label': 'Булевое',
  'templates-create-form-field-value-integer': 'целое число',
  'templates-create-form-field-value-integer-label': 'Целое число',
  'templates-create-form-field-value-double': 'дробное число',
  'templates-create-form-field-value-double-label': 'Дробное число',
  'templates-create-form-field-value-string': 'строка',
  'templates-create-form-field-value-string-label': 'Строка',
  'templates-create-form-field-value-array': 'список',
  'templates-create-form-field-value-array-label': 'Список',
  'templates-create-form-field-value-image': 'изображение',
  'templates-create-form-field-value-image-label': 'Изображение',
  'templates-create-form-field-value-object': 'объект',
  'templates-create-form-field-value-object-label': 'Объект',
  'templates-create-form-field-value-dictionary': 'структура словаря',
  'templates-create-form-field-value-dictionary-label': 'Структура словаря',
  'templates-create-form-field-value-color': 'цвет',
  'templates-create-form-field-value-color-label': 'Цвет',
  'templates-create-form-field-value-date': 'дата',
  'templates-create-form-field-value-date-label': 'Дата',
  'templates-create-form-field-value-select': 'выбор значения',
  'templates-create-form-field-value-select-label': 'Выбор значения',
  'templates-create-form-field-hint-help': 'Подсказка пояснит создателю сущности по шаблону какое значение необходимо записать в данное поле',
  'templates-create-form-field-key-help': 'Ключ должен быть уникальным в рамках родительского узла',
  'templates-create-form-delete-node-confirm': 'Удаляя данный узел вы удалите все вложенные узлы. Вы уверены?',
  'templates-create-form-field-default-value-help': 'Значение по ум. не задано (пустое). Провзаимодействуйте с полем, чтобы выставить значение по ум.',
  'templates-create-form-add-target': 'Таргет',
  'templates-create-form-targets-min-version-placeholder': 'Мин. версия приложения',
  'templates-create-form-targets-max-version-placeholder': 'Макс. версия приложения',
  'templates-create-form-targets-countries-label-help': 'Таргет прикреплен ко всем странам по ум.',
  'templates-collapse-additional-title': 'Дополнительные данные',
  'templates-form-field-empty-dictionaries-list': 'Отсутствуют доступные словари либо они находятся в статусе "Черновик". Пожалуйста, создайте словарь и возвращайтесь.',
};

export default templates;
