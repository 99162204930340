const achievements = {
  'bonuses-breadcrumbs': 'Бонусы',
  'bonus-details-title': 'Бонус',
  'bonuses-filter-title': 'Фильтр бонусов',
  'bonus-create-sidebar': 'Создание бонуса',
  'bonus-table-content-tooltip-label': 'Нажмите для просмотра всех прикрепленных изображений',
  'bonus-no-attached-images': 'Картинки еще не прикреплены к бонусу',
  'bonus-attach-success': 'Картинка прикреплена к бонусу',
  'bonus-attach-failed': 'Ошибка при прикреплении картинки к бонусу',
  'bonus-attach-confirm-message': 'Вы уверены, что хотите прикрепить эту картинку к бонусу?',
  'bonus-detach-confirm-message': 'Вы уверены, что хотите открепить эту картинку от бонуса?',
  'bonus-edit-notification-note': 'Внимание: Если вы хотите отменить публикацию данного бонуса, пожалуйста, обратитесь к администратору.',
  'bonus-publish-notification-note': 'Публикация бонуса запрещена, в случае когда к бонусу не прикреплено ни одного изображения.',
};

export default achievements;
