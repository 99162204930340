const popups = {
  'popups-breadcrumbs': 'Popups',
  'popups-details-title': 'Popup',
  'popups-filter-title': 'Filter popups',
  'popups-priority-title': 'Set correct order for popups',
  'popups-priority-filter-title': 'Select popups by date',
  'popups-priority-updated-successfully': 'Popups priority updated',
  'popups-priority-updated-failed': 'Popups priority update failed',
};

export default popups;
