const encodeUnsafeCharacters = (str) => {
  return str.replace(/[%;]/g, (match) => {
    return `%${match.charCodeAt(0).toString(16).toUpperCase()}`;
  });
};

const getQueryParams = (query) => {
  const params = {};

  new URLSearchParams(query).forEach((value, key) => {
    let decodedKey = decodeURIComponent(key);

    const decodedValue = decodeURIComponent(encodeUnsafeCharacters(value));

    if (decodedKey.endsWith('[]')) {
      decodedKey = decodedKey.replace('[]', '');
      // eslint-disable-next-line no-unused-expressions
      params[decodedKey] || (params[decodedKey] = []);
      params[decodedKey].push(decodedValue);
    } else {
      params[decodedKey] = decodedValue;
    }
  });

  return params;
};

export default getQueryParams;
