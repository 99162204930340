import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPagePresets from '../../../../../urls/urlPagePresets';
import FormCreateEditPresets from '../../../forms/FormCreateEditPresets';
import { useGetPresetQuery, useUpdatePresetMutation } from '../../../api/presetsApiSlice';
import transformErrors from '../../../../../lib/transformErrors';
import urlPagePresetDetails from '../../../../../urls/urlPagePresetDetails';
import createFileFromURL from '../../../../../lib/createFileFromURL';
import { TYPE_IMAGE } from '../../../const/templates';
import handleResponse from '../../../../../lib/handleResponse';
import isFieldUsingInPreset from '../../../../../lib/isFieldUsingInPreset';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';



const PagePresetsEdit = () => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { presetId } = useParams();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const { data: preset = {}, isFetching: isPresetFetching } = useGetPresetQuery({
    id: presetId,
    include: 'template,user',
  });

  const [ initialValues, setInitialValues ] = useState({ data: [] });
  const [ updatePreset, { isLoading: isUpdatingPreset } ] = useUpdatePresetMutation();

  const onSubmit = (values) => {
    updatePreset({ values, id: preset.id })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => navigate(urlPagePresets()),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [
          () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(transformErrors(error?.data?.errors));
            }
          },
        ],
        error,
      ));
  };

  useEffect(() => {
    const preview = [];

    setInitialValues({
      title: preset?.title ?? '',
      preview,
      template_id: preset?.template?.data?.id ?? null,
      data: {},
    });

    [ 'preview' ].forEach(async () => {
      if (preset.preview) {
        const fileName = preset.preview?.split('/').pop();
        const file = await createFileFromURL(preset?.preview, fileName);

        preview.push({
          uid: '-1',
          name: preset?.preview,
          originFileObj: file,
          thumbUrl: '',
          status: 'done',
          url: preset?.preview,
        });

        setInitialValues((prevState) => {
          return {
            ...prevState,
            preview,
          };
        });
      }
    });

    const data = {};

    preset?.template?.data?.fields?.data.filter((field) => isFieldUsingInPreset(field))
      .forEach(async (field) => {
        let presetField;

        // eslint-disable-next-line fp/no-loops,no-restricted-syntax
        for (const item of Object.values(preset?.data)) {
          if (Number.parseInt(item.template_field_id, 10) === field.id) {
            presetField = item;
          }
        }

        if (field.type === TYPE_IMAGE) {
          if (presetField?.value) {
            const fileName = presetField?.value?.split('/').pop();
            const file = await createFileFromURL(presetField?.value, fileName);

            data[field[`display_name_${language}`]] = {
              value: [ {
                uid: '-1',
                name: presetField?.value,
                thumbUrl: presetField?.value,
                status: 'done',
                originFileObj: file,
                url: presetField?.value,
              } ],
              template_field_id: field.id,
            };
          } else {
            data[field[`display_name_${language}`]] = {
              value: [],
              template_field_id: field.id,
            };
          }
        } else {
          data[field[`display_name_${language}`]] = {
            value: presetField?.value,
            template_field_id: field.id,
          };
        }

        setInitialValues((prevState) => ({
          ...prevState,
          data,
        }));
      });
  }, [ isPresetFetching ]);

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPagePresets()}>
              <IntlMessages id='presets-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={
            HasRights([ Permissions.CONSTRUCTORS.PRESETS.FIND ]) ? (
              <Link to={urlPagePresetDetails({ presetId })}>
                {presetId}
              </Link>
            ) : <span className='inactive-breadcrumb-link'>{presetId}</span>
          }
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card loading={isPresetFetching} className="hp-border-color-black-40 hp-card-6">
            <FormCreateEditPresets
              initialValues={initialValues}
              initialErrors={errors}
              isSubmitting={isUpdatingPreset}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePresetsEdit;
