import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Alert, Col, Collapse, DatePicker, Divider, Row, Typography } from 'antd';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import {
  fieldTypeToValidationRule,
  NEWS_TEMPLATE_CONTENT_TYPE,
  mkValidationSchema,
} from './FormCreateDynamicNews.const';
import { dateFieldFormat, targetPlatformOptions } from '../../../../const/system';
import { countries } from '../../../../const/countries';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Template builder/const/templates';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import mkTemplateDynamicFormData from '../../utils/mkTemplateDynamicFormData';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import isFieldRequired from '../../utils/isFieldRequired';
import DynamicFormDataFields from '../../components/DynamicFormDataFields';
import modifyDefaultValue from '../../../Template builder/utils/modifyDefaultValue';
import Spinner from '../../../../components/Spinner';
import CountrySelector from '../../../../components/CountrySelector';
import TitleWithAutomaticGeneration from '../../../../components/TitleWithAutomaticGeneration';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import PresetPreview from '../../../../components/PresetPreview';
import mkMainAndOptionalTemplateFields from '../../../Template builder/utils/mkMainAndOptionalTemplateFields';
import applyPreset from '../../../../lib/applyPreset';
import isDateDisabled from '../../../../lib/isDateDisabled';
import { useGetPresetsQuery } from '../../../Template builder/api/presetsApiSlice';
import { useGetTemplatesQuery } from '../../../Template builder/api/templatesApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

let validation = {};

const FormCreateDynamicNews = ({
  initialErrors,
  onSubmit,
  isSubmitting,
}) => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { onBlur } = useDatepickerHandler();
  const [ currentTemplate, setCurrentTemplate ] = useState(null);

  if (!isEmpty(initialErrors)) {
    window.scrollTo(0, 0);
  }

  const { data: templates = { data: [] }, isFetching: isTemplatesFetching } = useGetTemplatesQuery({
    queryParams: `limit=0&search=status:1;content_type:${NEWS_TEMPLATE_CONTENT_TYPE}`,
  });

  const {
    data: { data: presets } = { data: [] },
    isFetching: isPresetsFetching,
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${currentTemplate}`,
  }, { skip: !currentTemplate });

  const template = templates?.data?.find((template) => template.id === currentTemplate);
  const modifiedErrors = useModifiedDynamicDataErrors(currentTemplate, template, initialErrors, language);
  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(template?.fields?.data);

  useEffect(() => {
    validation = mkValidationSchema();
  }, [ isPresetsFetching ]);

  const applyTemplate = async (value, setFieldValue) => {
    setCurrentTemplate(value);
    await setFieldValue('preset_id', null);
    await setFieldValue('template_id', value);

    if (!value) {
      setFieldValue('data', {});
      return;
    }

    const data = {};
    const dataSchema = {};
    const template = templates?.data?.find((template) => template.id === value);

    template?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach((field) => {
        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[field[`display_name_${language}`]] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        data[field[`display_name_${language}`]] = {
          value: field.type === TYPE_IMAGE ? [] : modifyDefaultValue(field),
          template_field_id: field.id,
        };
      });

    setFieldValue('data', data);
    setFieldValue('target', {
      min_version: template?.target?.data?.min_version,
      max_version: template?.target?.data?.max_version,
      platform: template?.target?.data?.platform,
      countries: template?.target?.data?.countries,
    });

    validation = mkValidationSchema({
      data: Yup.object().shape(dataSchema),
    });
  };

  const initialValues = {
    is_published: false,
    released_at: null,
    released_to: null,
    template_id: null,
    preset_id: null,
    data: {},
    target: {
      min_version: null,
      max_version: null,
      platform: [],
      countries: [],
    },
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={modifiedErrors}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, template?.fields?.data), resetForm);
      }}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, resetForm }) => {
        const preset = presets?.find((preset) => Number(preset.id) === Number(values.preset_id));

        return (
          <Form layout="vertical" >
            <Spinner spinning={isSubmitting}>
              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: 'structures-source-news' })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-released-at' })}
                        name='released_at'
                        required={values?.is_published}
                      >
                        <DatePicker
                          showToday
                          showTime
                          name='released_at'
                          format={dateFieldFormat}
                          disabledDate={(date) => isDateDisabled(
                            date,
                            [],
                            !isEmpty(values?.released_to)
                            && date.isAfter(moment(values?.released_to)),
                          )}
                          value={values?.released_at ? moment(values.released_at) : null}
                          onChange={async (date, dateString) => {
                            await setFieldValue('released_at', dateString);
                          }}
                          onBlur={async ({ target }) => {
                            await setFieldTouched('released_at', true);
                            await onBlur('released_at', target.value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-released-to' })}
                        name='released_to'
                        required={values?.is_published}
                      >
                        <DatePicker
                          showToday
                          showTime
                          name='released_to'
                          format={dateFieldFormat}
                          disabledDate={(date) => isDateDisabled(
                            date,
                            [],
                            !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                          )}
                          value={values?.released_to ? moment(values.released_to) : null}
                          onChange={async (date, dateString) => {
                            await setFieldValue('released_to', dateString);
                          }}
                          onBlur={async ({ target }) => {
                            await setFieldTouched('released_to', true);
                            await onBlur('released_to', target.value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={isTemplatesFetching}
                          allowClear
                          showSearch
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          options={getTemplateOptions(templates.data)}
                          onChange={async (value) => {
                            await applyTemplate(value, setFieldValue);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={isPresetsFetching}
                          allowClear
                          showSearch
                          disabled={!currentTemplate}
                          name="preset_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                          options={getTemplateOptions(presets)}
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, template?.fields?.data ?? []);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <Collapse accordion className='hp-mb-24'>
                        <Panel
                          key="additional-info"
                          header={
                            <Title className='collapse-item-title' level={5}>
                              {intl.formatMessage({ id: 'ui-general-optional-data' })}
                            </Title>
                          }
                        >
                          <TitleWithAutomaticGeneration useAutomaticGeneration={false} />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              {values?.template_id && (
                <>
                  <Divider />
                  <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-template-data" /></Title>
                  <Row>
                    <Col span={12}>
                      <DynamicFormDataFields
                        setFieldValue={setFieldValue}
                        fields={mainFields}
                        values={values}
                        span={24}
                      />
                      {!!optionalFields.length && (
                        <Collapse>
                          <Panel
                            key="1"
                            header={
                              <Title level={5} className='collapse-item-title'>
                                <IntlMessages id="ui-general-additional-parameters" />
                              </Title>
                            }
                          >
                            <DynamicFormDataFields
                              setFieldValue={setFieldValue}
                              fields={optionalFields}
                              values={values}
                              span={24}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Col>
                  </Row>

                  <Divider />
                  <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-targets" /></Title>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-min-version' })}
                        name='target.min_version'
                      >
                        <Input
                          name="target.min_version"
                          placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-min-version-placeholder' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={12}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-max-version' })}
                        name='target.max_version'
                      >
                        <Input
                          name="target.max_version"
                          placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-max-version-placeholder' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-platform' })}
                        name='target.platform'
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          name="target.platform"
                          options={targetPlatformOptions}
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-platform' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-countries' })}
                        name='target.countries'
                        required
                      >
                        <CountrySelector
                          onSelectAll={async () => {
                            await setFieldValue(
                              'target.countries',
                              countries.map((country) => country.value).sort(),
                            );
                          }}
                          onSelectAllDisabled={values?.target?.countries?.length === countries.length}
                          setFieldValue={setFieldValue}
                          name='target.countries'
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-countries' })}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </>
              )}

              <Alert
                message={<IntlMessages id="features-edit-has-changes" />}
                description={
                  <Row justify='end' className='hp-mt-32'>
                    <Col>
                      <Row>
                        <IntlMessages id="features-edit-publish-to-hc" />
                        <Switch name="is_published" className='hp-ml-16' />
                      </Row>
                    </Col>
                  </Row>
                }
                type="info"
                showIcon
              />

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      setCurrentTemplate(null);
                      resetForm(initialValues);
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateDynamicNews.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormCreateDynamicNews;
