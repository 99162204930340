const news = {
  'news-breadcrumbs': 'Old news',
  'news-preview-title': 'Preview news',
  'news-create-title': 'Create old news',
  'news-update-title': 'Edit old news',
  'news-filter-title': 'Filter old news',
  'news-form-id-placeholder': 'News id',
  'news-form-translations-label': 'Translations',
  'news-form-upload-icons-placeholder': 'Upload Icons (2 files)',
  'news-create-form-target-label': 'Scheme/Url',
  'news-create-form-target-placeholder': '#target',
  'news-create-form-dictionary-placeholder': 'Choose dictionary',
  'news-create-form-icons-label': 'Icons',
  'news-create-form-upload-description-label': 'Click or drag 2 files to this area for uploading',
};

export default news;
