import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageRBACRoleDetails from '../../../../urls/urlPageRBACRoleDetails';



const RoleTag = ({ role }) => {
  if (HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ], true)) {
    return (
      <Link to={urlPageRBACRoleDetails({ roleId: role.id })}>
        <Tag
          className='hp-mb-4'
          color='red'
        >
          {capitalize(role.name)}
        </Tag>
      </Link>
    );
  }

  return (
    <Tag
      className='hp-mb-4'
      color='red'
    >
      {capitalize(role.name)}
    </Tag>
  );
};

RoleTag.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default RoleTag;
