import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions } from 'antd';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const AchievementDetailsCard = ({ data }) => {
  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Descriptions
        layout="vertical"
        bordered
        className="hp-mb-32"
        column={{ md: 4, xs: 2 }}
      >
        <Descriptions.Item label={<IntlMessages id="ui-general-name" />}>
          {data?.name || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-code" />}>
          {data?.code || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-description" />}>
          {data?.description || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
          {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};


AchievementDetailsCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};

export default AchievementDetailsCard;
