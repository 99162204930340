import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { targetValidation } from '../../../../const/targetValidation';
import Yup from '../../../../vendor/yup';



export const makeValidationSchema = (collectionConfig, dynamicData = {}) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string()
    .nullable()
    .min(3, 'Min 3 symbols required')
    .max(2000, 'Max 2000 symbols required'),
  template_id: Yup.string().required('Required to specify template'),
  use_utc_zero_time: Yup.bool(),
  categories: Yup.array().min(1).of(Yup.number()),
  image_type: Yup.string().required('Required to specify image type').oneOf(collectionConfig?.image_types ?? []),
  released_at: Yup.string().required('Required to specify released at date')
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return dayjs(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ),
  ...collectionConfig?.config?.release_range && {
    released_to: Yup.string().required('Required to specify released to date')
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return dayjs(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  target: targetValidation,
  ...dynamicData,
}));
