import Yup from '../../../../vendor/yup';



export const mkValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required to specify title')
    .min(3, 'Min 3 symbols required')
    .max(191, 'Max 191 symbols required'),
  template_id: Yup.string().required('Required to specify template'),
});
