import React, { createContext, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetLanguagesQuery } from '../api/languagesApiSlice';
import { useGetStructuresQuery, useGetStructureVersionsQuery } from '../api/structuresApiSlice';



export const DictionariesContext = createContext(null);
export const DictionariesDispatchContext = createContext(null);

const dictionariesReducer = (data, action) => {
  switch (action.type) {
    case 'SET_SOURCE': {
      return {
        ...data,
        source: action.source,
      };
    }
    case 'SET_STRUCTURE': {
      return {
        ...data,
        structureId: action.structureId,
      };
    }
    case 'SET_SELECTED_STRUCTURE': {
      return {
        ...data,
        selectedStructure: action.selectedStructure,
      };
    }
    case 'UPGRADE_VERSION': {
      return {
        ...data,
        upgradeVersion: action.upgradeVersion,
      };
    }
    default: {
      throw new Error(`Unknown action: ${action.type}`);
    }
  }
};

const initialDictionaries = {
  source: null,
  structureId: null,
  selectedStructure: {},
  upgradeVersion: false,
};

const DictionariesProvider = ({ children, edit = false }) => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const [ data, dispatch ] = useReducer(dictionariesReducer, initialDictionaries);

  const { data: languages } = useGetLanguagesQuery({ queryParams: 'limit=0' });
  const { data: structures = { data: [] }, isLoading } = useGetStructuresQuery({ queryParams: 'limit=0' });

  const sourceOptions = useMemo(() => {
    return structures.data.map((item) => {
      return {
        label: intl.formatMessage({ id: `structures-source-${item.source}` }),
        value: item.id,
      };
    }).sort((first, second) => first.label - second.label);
  }, [ structures.data, language ]);

  const { data: versions, isLoading: isVersionsLoading } = useGetStructureVersionsQuery(data.source, { skip: !data.source });

  const structureOptions = useMemo(() => {
    if (!versions) {
      return [];
    }

    return versions?.data.map((item) => {
      return {
        label: `v${item.version}`,
        value: item.id,
      };
    }).sort((first, second) => first.label - second.label);
  }, [ versions?.data ]);

  const getNewStructure = () => {
    if (edit) {
      return versions?.data?.find((item) => {
        const majorVer = item.version.split('.')[0];
        const curMajorVer = data.selectedStructure.version.split('.')[0];

        return majorVer === curMajorVer;
      }) || {};
    }
    return {};
  };

  const getSelectedStructure = () => {
    if (edit) {
      if (data.upgradeVersion) {
        return versions?.data?.find((item) => {
          const majorVer = item.version.split('.')[0];
          const curMajorVer = data.selectedStructure.version.split('.')[0];

          return majorVer === curMajorVer;
        });
      }

      return data.selectedStructure;
    }

    return versions?.data?.find((item) => item.id === data.structureId) ?? { name: '', source: '', description: '', language_ids: [], fields: { data: [] } };
  };

  const providerData = useMemo(() => {
    return {
      languages: languages?.languages,
      sources: {
        data: structures.data,
        isLoading,
        options: sourceOptions,
      },
      structures: {
        data: versions?.data,
        isLoading: isVersionsLoading,
        options: structureOptions,
      },
      selectedStructure: getSelectedStructure(),
      newStructure: getNewStructure(),
      data,
    };
  }, [ languages, sourceOptions, structureOptions, structures?.data, versions?.data, data ]);

  return (
    <DictionariesContext.Provider value={providerData}>
      <DictionariesDispatchContext.Provider value={dispatch}>
        {children}
      </DictionariesDispatchContext.Provider>
    </DictionariesContext.Provider>
  );
};

DictionariesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  edit: PropTypes.bool,
};

export const useDictionaries = () => {
  return useContext(DictionariesContext);
};

export const useDictionariesDispatch = () => {
  return useContext(DictionariesDispatchContext);
};

export default DictionariesProvider;
