import { MODAL_MODE } from '../domains/Content/pages/Feeds/PageFeeds/PageFeeds.const';



const getModalTitleByModalMode = (modalMode, intl) => {
  switch (modalMode) {
    case MODAL_MODE.VIEW:
      return intl.formatMessage({ id: 'content-image-preview-card-title' });
    case MODAL_MODE.REMOVE:
      return intl.formatMessage({ id: 'ui-general-remove' });
    case MODAL_MODE.UNPUBLISH:
      return intl.formatMessage({ id: 'ui-general-unpublish' });
    case MODAL_MODE.EDIT:
      return intl.formatMessage({ id: 'ui-general-edit' });
    default:
      return intl.formatMessage({ id: 'content-image-preview-card-title' });
  }
};

export default getModalTitleByModalMode;
