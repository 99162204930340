import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../../../assets/images/logo/hc_logo_2.svg';
import logoSmall from '../../../../assets/images/logo/hc_logo_1.svg';
import themeConfig from '../../../../configs/themeConfig';



const MenuLogo  = ({ onClose = () => {}, small = false }) => {
  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-center">
      <Link
        to="../"
        onClick={onClose}
        className="hp-position-relative hp-d-flex"
      >
        {small ? <img className="hp-logo" src={logoSmall} alt="logo-sm" /> : <img className="hp-logo" src={logo} alt="logo-lg" />}
      </Link>

      {!small && (
        <div className="hp-caption hp-font-weight-400 hp-text-color-primary-1">
          v.
          {themeConfig.version}
        </div>
      )}
    </div>
  );
};

MenuLogo.propTypes = {
  onClose: PropTypes.func,
  small: PropTypes.bool,
};

export default MenuLogo;
