import React from 'react';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';
import { useGetDictionaryQuery } from '../../../Translations/api/dictionariesApiSlice';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const DictionaryFieldView = ({ dictionaryId = null }) => {
  const { data: dictionary = {}, isFetching } = useGetDictionaryQuery({ id: dictionaryId }, { skip: !dictionaryId });

  if (isFetching) {
    return <IntlMessages id="ui-general-loading" />;
  }

  if (!dictionary.id) {
    return <span>{EMPTY_VALUE_PLACEHOLDER}</span>;
  }

  return (
    <Space>
      <Link target='_blank' to={urlPageTranslationDetails({ id: dictionaryId })}>
        <Space>
          {dictionary?.name ?? EMPTY_VALUE_PLACEHOLDER}
          <FiExternalLink size={14} />
        </Space>
      </Link>
    </Space>
  );
};

DictionaryFieldView.propTypes = {
  dictionaryId: PropTypes.number,
};

export default DictionaryFieldView;
