import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { TYPE_OBJECT } from '../../../Template builder/const/templates';
import DynamicFormField from '../DynamicFormField';



const DynamicFormDataFields = ({ fields = [], setFieldValue, values, span = 12 }) => {
  const clonedFields = JSON.parse(JSON.stringify(fields));

  return clonedFields.filter((field) => field.type !== TYPE_OBJECT)
    .map((field) => {
      return (
        <Row key={field.id} gutter={16}>
          <Col md={span} xs={24}>
            <DynamicFormField
              field={field}
              setFieldValue={setFieldValue}
              values={values}
            />
          </Col>
        </Row>
      );
    });
};

DynamicFormDataFields.propTypes = {
  fields: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  span: PropTypes.number,
};

export default DynamicFormDataFields;
