// eslint-disable-next-line lodash/import-scope
import { isBoolean } from 'lodash';
import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';
import {
  CHILD_TYPES,
  TEMPLATE_SUBTYPES,
  TEMPLATE_TYPES,
  TEMPLATE_TYPES_WITH_DEFAULT_VALUE,
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_DICTIONARY,
  TYPE_DOUBLE,
  TYPE_INTEGER,
  TYPE_SELECT,
  TYPE_STRING,
} from '../../const/templates';
import Yup from '../../../../vendor/yup';



export const validationSchema = Yup.lazy((values) => {
  return Yup.object().shape({
    key: Yup.string().required('Key is required'),
    type: Yup.string()
      .oneOf(TEMPLATE_TYPES)
      .required('Type is required'),
    title_en: Yup.string()
      .min(1, 'Min 1 symbol required')
      .max(191, 'Max 191 symbol required')
      .when('type', {
        is: (val) => CHILD_TYPES.includes(val),
        then: (schema) => schema
          .required('Value is required.')
          .test(
            'check-default-value-type-title',
            'Title can not be empty',
            (value) => value,
          ),
        otherwise: (schema) => schema.nullable(),
      }),
    title_ru: Yup.string()
      .min(1, 'Min 1 symbol required')
      .max(191, 'Max 191 symbol required')
      .when('type', {
        is: (val) => CHILD_TYPES.includes(val),
        then: (schema) => schema
          .required('Value is required.')
          .test(
            'check-default-value-type-title',
            'Title can not be empty',
            (value) => value,
          ),
        otherwise: (schema) => schema.nullable(),
      }),
    hint_en: Yup.string()
      .min(1, 'Min 1 symbol required')
      .max(191, 'Max 191 symbol required')
      .nullable(),
    hint_ru: Yup.string()
      .min(1, 'Min 1 symbol required')
      .max(191, 'Max 191 symbol required')
      .nullable(),
    is_required: Yup.boolean().default(false),
    use_in_preset: Yup.boolean().default(false),
    subtype: Yup.string()
      .oneOf(TEMPLATE_SUBTYPES)
      .when('type', {
        is: TYPE_ARRAY,
        then: (schema) => schema.required('Subtype is required when type is array.'),
        otherwise: (schema) => schema.nullable(),
      }),
    validation: Yup.array().of(Yup.string()),
    // eslint-disable-next-line no-misleading-character-class
    input_validation: Yup.string().matches(/^[^\u0400-\u04FF\u0500-\u052F\u2DE0-\u2DFF\uA640-\uA69F\u1D2B\u1D78\u4E00\u9FD5]+$/, 'Please follow the rules from documentation').nullable(),
    input_default_value: Yup.mixed().when('subtype', {
      is: TYPE_DOUBLE,
      then: (schema) => schema.nullable().test(
        'check-input-default-value-type',
        'Value should be double',
        (value) => {
          if (!value) {
            return true;
          }

          value = Number(value);

          return parseFloat(value) === value && !Number.isInteger(value);
        },
      ),
    }).when('subtype', {
      is: TYPE_INTEGER,
      then: (schema) => schema.nullable().test(
        'check-input-default-value-type',
        'Value should be integer',
        (value) => {
          if (!value) {
            return true;
          }

          value = Number(value);

          return isInteger(value) && Number(value) === value && value % 1 === 0;
        },
      ),
    }).when('subtype', {
      is: TYPE_STRING,
      then: (schema) => schema.nullable().test(
        'check-input-default-value-type',
        'Value should be string',
        (value) => {
          if (!value) {
            return true;
          }

          return isString(value);
        },
      ),
    }).nullable(),
    default_value: Yup.mixed().when([ 'subtype', 'type' ], {
      is: (subtype, type) => TEMPLATE_TYPES_WITH_DEFAULT_VALUE.some((templateType) => [ subtype, type ].includes(templateType)),
      then: (schema) => schema.nullable().test(
        'check-default-value-type',
        'Value should be the same type as type/subtype',
        (value) => {
          if (!value) {
            return true;
          }

          const typeForCompare = values.type;

          switch (typeForCompare) {
            case TYPE_BOOLEAN:
              return isBoolean(value);
            case TYPE_INTEGER:
              return isInteger(value) && Number(value) === value && value % 1 === 0;
            case TYPE_DOUBLE:
              return parseFloat(value) === value && !Number.isInteger(value);
            case TYPE_STRING:
              return isString(value);
            default:
              return true;
          }
        },
      ),
      otherwise: (schema) => schema.nullable(),
    }),
    value: Yup.mixed()
      .when('type', {
        is: TYPE_DICTIONARY,
        then: (schema) => schema
          .required('Value is required when node type id dictionary structure.')
          .test(
            'check-default-value-type-dict',
            'Dictionary can not be empty',
            (value) => value,
          ),
        otherwise: (schema) => schema.nullable(),
      })
      .when('type', {
        is: TYPE_SELECT,
        then: (schema) => schema
          .required('Please enter select options.').test(
            'check-default-value-type',
            'Enter correct JSON for select options',
            (value) => {
              return value.toString()
                .match(/^\[\s*(\{\s*"label"\s*:\s*".*?"\s*,\s*"value"\s*:\s*(?:"[^"]*"|\d+(?:\.\d+)?|true|false)\s*\}\s*,\s*)*\{\s*"label"\s*:\s*".*?"\s*,\s*"value"\s*:\s*(?:"[^"]*"|\d+(?:\.\d+)?|true|false)\s*\}\s*\]$/);
            },
          ),
        otherwise: (schema) => schema.nullable(),
      }),
  });
});
