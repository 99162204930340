import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Yup from '../../../../vendor/yup';



export const validationSchema = Yup.lazy((values) => {
  return Yup.object().shape({
    id: Yup.string()
      .min(1, 'Minimum 1 symbol required'),
    image_id: Yup.string()
      .min(1, 'Minimum 1 symbol required'),
    source_id: Yup.string()
      .min(1, 'Minimum 1 symbol required'),
    title: Yup.string()
      .min(1, 'Minimum 1 symbol required')
      .max(255, 'Maximum 255 symbols required'),
    released_at: Yup.string().test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return moment(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ),
    released_to: Yup.string().test(
      'test_date_before',
      'Released to date must be same or after than released at',
      (value) => {
        if (!isEmpty(values?.released_at) && !isEmpty(value)) {
          return moment(values?.released_at).isSameOrBefore(value);
        }
        return true;
      },
    ),
  });
});
