const feeds = {
  'feeds-breadcrumbs-list': 'Лента',
  'feeds-breadcrumbs-released-list': 'Опубликовано',
  'feeds-breadcrumbs-archived-list': 'Архив',
  'feeds-breadcrumbs-trash-list': 'Удалено',
  'feeds-form-very-simple-difficulty': 'Очень просто',
  'feeds-form-simple-difficulty': 'Просто',
  'feeds-form-medium-difficulty': 'Средне',
  'feeds-form-hard-difficulty': 'Сложно',
  'feeds-form-difficulty-tooltip': 'Данные опции автозаполяют мин/макс значения тапов',
  'feeds-list-unpublish-disabled-tooltip': 'Снять с публикации запрещено. Изображение прикреплено к коллекциям и/или к сущности выбор редакторов',
  'feeds-trash-modal-remove-text': 'Внимание! Картинка будет полностью удалена, ее нельзя будет найти в панели и использовать.',
  'feeds-archive-modal-trash-text': 'Иображение будет отправлено в корзину, вы уверены, что хотите выполнить действие?',
  'feeds-trash-modal-return-to-draft-confirm': 'Вы уверены, что хотите перевести изображение обратно в черновики?',
  'feeds-unpublish-modal-trash-confirm': 'Вы уверены, что хотите переместить изображение в корзину?',
  'feeds-unpublish-modal-archive-confirm': 'Вы уверены, что хотите архивировать изображение?',
  'feeds-modal-unpublish-archive-text': 'Если нажать на кнопку Архивировать, то картинка будет снята с публикации, но останется доступной для синхронизации.',
  'feeds-modal-unpublish-trash-text': 'Если нажать на кнопку В корзину, то картинка будет полностью снята с публикации и может быть удалена.',
  'feeds-modal-unpublish-image-in-prod': 'Изображение находится в производстве для всего мира или его части.',
  'feeds-modal-edit-image-save-info': 'Мы сообщаем вам о доступности обновлений для мобильного приложения Happy Color. Чтобы успешно опубликовать эти изменения, нажмите на кнопку \'Сохранить и опубликовать\'.',
};

export default feeds;
