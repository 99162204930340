import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';



const ActionButton = ({
  loading = false,
  title = 'button',
  onClick,
  icon = null,
  className = 'hp-mr-sm-8 hp-mr-16',
  isActive = false,
}) => {
  return (
    <Button
      loading={loading}
      type="primary"
      className={className}
      ghost={!isActive}
      onClick={onClick}
      icon={icon}
    >
      {title}
    </Button>
  );
};

ActionButton.propTypes = {
  title: PropTypes.any,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default ActionButton;
