const getInitialImageCategoriesValues = (data) => {
  const initialValues = {
    categories: [],
    main_category: null,
    unique_id: Date.now(),
  };

  data?.categories?.data?.forEach((category) => {
    const currentImageCategory = data?.categories?.data.find((imageCategory) => imageCategory.id === category.id);

    if (currentImageCategory) {
      if (currentImageCategory.is_main) {
        initialValues.main_category = currentImageCategory.id;
      }

      initialValues.categories.push(currentImageCategory.id);
    }
  });

  return initialValues;
};

export default getInitialImageCategoriesValues;
