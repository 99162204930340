import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Tooltip } from 'antd';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const PrioritiesTitle = ({
  title,
  hasPermission = false,
  isDisabled = true,
  onAction,
  tooltipMessage = 'ui-general-restricted',
}) => {
  return (
    <Row className="hp-d-flex hp-d-flex-justify-between hp-mb-0 hp-align-items-center">
      <div className='ant-card-head-title hp-mr-16'>{title}</div>
      {!hasPermission || isDisabled ? (
        <Tooltip title={<IntlMessages id={hasPermission ? tooltipMessage : 'ui-general-restricted'} />}>
          <Button
            type="primary"
            disabled
            onClick={onAction}
          >
            <IntlMessages id="ui-general-save" />
          </Button>
        </Tooltip>
      ) : (
        <Button
          type="primary"
          onClick={onAction}
        >
          <IntlMessages id="ui-general-save" />
        </Button>
      )}
    </Row>
  );
};

PrioritiesTitle.propTypes = {
  isDisabled: PropTypes.bool,
  hasPermission: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  title: PropTypes.any.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default PrioritiesTitle;

