import React from 'react';
import { Space } from 'antd';
import PropTypes from 'prop-types';



const ColorFieldView = ({ color }) => {
  return (
    <Space>
      <div
        style={{
          background: `${color}`,
          height: '20px',
          width: '20px',
          borderRadius: '6px',
          border: '1px solid #ccc',
        }}
      />
      {color}
    </Space>);
};

ColorFieldView.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColorFieldView;
