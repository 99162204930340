import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { PiInfinity } from 'react-icons/pi';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../lib/getFormattedDate';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import PublishedTag from '../../../../components/PublishedTag';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const BonusDetailsCard = ({ data }) => {
  const isMobile = useCheckMobileScreen(600);

  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Descriptions
        title={<IntlMessages id='bonus-details-title' />}
        layout={isMobile ? 'vertical' : 'horizontal'}
        bordered
        column={1}
      >
        <Descriptions.Item
          className='width-20-vw'
          label={<IntlMessages id="ui-general-id" />}
        >
          {data.id}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-code" />}>
          {data?.code || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
          {data?.title || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-description" />}>
          {data?.description || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
          <PublishedTag
            published={data?.is_published || false}
            publishedDate={data?.published_at || null}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
          {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>
          {getFormattedDate(data?.updated_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
          {getFormattedDate(data?.published_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
          {getFormattedDate(data?.released_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
          {!isEmpty(data?.released_to) ? dayjs(data?.released_to).utc().format(DATE_TIME_FORMAT) : <PiInfinity />}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};


BonusDetailsCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    is_published: PropTypes.bool,
    published_at: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    released_at: PropTypes.string,
    released_to: PropTypes.string,
  }),
};

export default BonusDetailsCard;
