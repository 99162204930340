const categories = {
  'categories-breadcrumbs': 'Categories',
  'categories-create-successfully': 'Category created',
  'categories-update-successfully': 'Category updated',
  'categories-create-failed': 'Create category failed',
  'categories-update-failed': 'Update category failed',
  'categories-create-form-field-client-id-help': 'Unique ID for "core" client to identify category',
  'categories-create-form-field-name-placeholder': 'Enter name',
  'categories-create-form-field-description-placeholder': 'Enter description',
  'categories-create-form-field-options': 'Options',
  'categories-create-form-field-options-placeholder': 'Enter options',
  'categories-create-form-field-options-help': 'You may pass different meta options here like target config (min_app_version, core_version etc.) in JSON format',
  'categories-create-form-field-dictionary-placeholder': 'Select dictionary',
  'categories-view-images': 'Images',
  'categories-view-images-empty': 'No images',
  'categories-priorities': 'Priorities',
  'categories-deleted-at': 'Was deleted at',
  'categories-priorities-title': 'Drag categories to set correct priority',
  'categories-priorities-empty': 'There are not categories',
  'categories-priorities-disabled-action': 'Drag categories for update',
};

export default categories;
