import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import encodeUnsafeCharacters from './encodeUnsafeCharacters';



const applyFilter = ({
  values = {},
  searchQueryParams,
  searchFilterFields = [],
  setSearchParams = () => {},
  setSearchField = () => {},
  toggleFilterSidebar = () => {},
  navigationSource = '',
  callbacks = [],
}) => {
  let queryParams = {};

  Array.from(searchQueryParams.entries())
    .forEach(([ key, value ]) => {
      queryParams[key] = key === 'page' ? 1 : value;
    });

  const params = [];

  Object.entries(values).forEach(([ field, value ]) => {
    if (searchFilterFields.includes(field)) {
      let param;

      if ((isArray(value) && !value.length) || value === null || (isNumber(value) ? false : !value)) {
        return;
      }

      if (isString(value)) {
        param = [ 'id', 'client_id', 'image_id', 'folder', 'source_id', 'code', 'bee_id' ].includes(field)
          ? `${field}:${value.split(/[ ,\t]+/)}`
          : `${field}:${encodeUnsafeCharacters(value.trim())}`;
      } else {
        param = `${field}:${value}`;
      }

      params.push(param);
    }
  });

  if (!isEmpty(params)) {
    queryParams.search = params.join(';');
  } else {
    const { search, ...restParams } = queryParams;

    queryParams = restParams;
  }

  if (navigationSource && !queryParams.search.includes(`source:${navigationSource}`)) {
    queryParams.search += `;source:${navigationSource}`;
  }

  setSearchField('');
  setSearchParams(queryParams, { replace: true });
  toggleFilterSidebar();

  callbacks.forEach((callback) => callback());
};

export default applyFilter;
