import React from 'react';
import { Col } from 'antd';
import { ResetButton, SubmitButton } from 'formik-antd';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import { FEEDS_SOURCE_MAP } from '../../pages/Feeds/PageFeeds/PageFeeds.const';



const ImageModalCardFooterEdit = ({ isLoading = false }) => {
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const { isValid, dirty, handleReset, handleSubmit } = useFormikContext();

  return (
    <>
      <Col>
        <ResetButton
          loading={isLoading}
          onClick={handleReset}
        >
          <IntlMessages id='ui-general-reset' />
        </ResetButton>
      </Col>
      <Col>
        <SubmitButton
          loading={isLoading}
          disabled={!isValid || !dirty}
          onClick={handleSubmit}
        >
          <IntlMessages id={[ FEEDS_SOURCE_MAP.RELEASED, FEEDS_SOURCE_MAP.LIST ].includes(feedSource) ? 'ui-general-save-and-publish' : 'ui-general-save'} />
        </SubmitButton>
      </Col>
    </>
  );
};

ImageModalCardFooterEdit.propTypes = {
  isLoading: PropTypes.bool,
};

export default ImageModalCardFooterEdit;
