import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Descriptions, Row, Skeleton } from 'antd';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import getFormattedDate from '../../../../lib/getFormattedDate';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import PublishedTag from '../../../../components/PublishedTag';
import TemplateCollapsibleCards from '../../../../components/TemplateCollapsibleCards';



const DynamicNewsDetailsCard = ({
  data,
  isLoadingCard,
  isFetching,
  newsDictionary,
  languages,
  templateCardsData,
}) => {
  const isMobile = useCheckMobileScreen(600);

  return (
    <Card
      loading={isLoadingCard}
      className="hp-border-color-black-40 hp-card-6 dynamic-news-details"
      title={data?.title}
    >
      {isFetching ? (
        <Row gutter={16}>
          <Col span={4}>
            <Skeleton.Image active />
          </Col>
          <Col span={20}>
            <Skeleton active />
          </Col>
        </Row>
      ) : (
        <Row gutter={[ 24, 24 ]}>
          <Col sm={24} md={6}>
            <img src={data?.preview ?? EMPTY_IMAGE_PLACEHOLDER} alt="preview" className="hp-mb-24" />
          </Col>
          <Col sm={24} md={18}>
            <Descriptions
              title={<IntlMessages id='structures-source-news' />}
              layout={isMobile ? 'vertical' : 'horizontal'}
              bordered
              className="hp-mb-32"
              column={1}
            >
              <Descriptions.Item label="ID" className='width-20-vw'>
                <span style={{ wordBreak: 'keep-all' }}>{data?.id}</span>
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
                {data?.title ?? EMPTY_VALUE_PLACEHOLDER}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
                <PublishedTag
                  published={data?.is_published}
                  publishedDate={data?.published_at}
                />
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
                {getFormattedDate(data.released_at, DATE_TIME_FORMAT)}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
                {getFormattedDate(data.released_to, DATE_TIME_FORMAT)}
              </Descriptions.Item>
            </Descriptions>

            <TemplateCollapsibleCards
              dictionary={newsDictionary}
              languages={languages}
              data={templateCardsData}
              targetData={data?.target?.data}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};


DynamicNewsDetailsCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    preview: PropTypes.string,
    id: PropTypes.number,
    is_published: PropTypes.bool,
    published_at: PropTypes.string,
    released_at: PropTypes.string,
    released_to: PropTypes.string,
    target: PropTypes.object,
  }).isRequired,
  isLoadingCard: PropTypes.bool,
  isFetching: PropTypes.bool,
  newsDictionary: PropTypes.object,
  templateCardsData: PropTypes.object,
  languages: PropTypes.array,
};

export default DynamicNewsDetailsCard;
