import Yup from '../../../../vendor/yup';



export const validationSchema = Yup.object().shape({
  main_category: Yup.number().nullable(),
  categories: Yup.array().of(Yup.number()).nullable(),
  comment: Yup.string().nullable().max(255, 'Maximum number of symbols'),
  labels: Yup.array().nullable(),
  hashtags: Yup.array().nullable(),
});
