import React from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { PiInfinity } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import urlPageTemplateDetails from '../../../../urls/urlPageTemplateDetails';
import urlPageCategoryDetails from '../../../../urls/urlPageCategoryDetails';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { Permissions } from '../../../../const/permissions';
import { HasRights } from '../../../../components/HasRights/HasRights';
import getFormattedDate from '../../../../lib/getFormattedDate';
import getLabelFromUnderscoreString from '../../utils/getLabelFromUnderscoreString';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import PublishedTag from '../../../../components/PublishedTag';
import StatusTag from '../../../../components/StatusTag';
import TemplateCollapsibleCards from '../../../../components/TemplateCollapsibleCards';



const CollectionDetailsCard = ({
  data,
  collectionDictionary,
  languages,
  templateCardsData,
}) => {
  const isMobile = useCheckMobileScreen(600);
  const getCategoryName = (item, addComma) => `${item.name}${addComma ? ', ' : ''}`;

  return (
    <Card className="hp-border-color-black-40 hp-card-6">
      <Descriptions
        title={<IntlMessages id='collection-details-title' />}
        layout={isMobile ? 'vertical' : 'horizontal'}
        bordered
        className="hp-mb-32"
        column={1}
      >
        <Descriptions.Item
          className='width-20-vw'
          label={<IntlMessages id="ui-general-id" />}
        >
          {data.id}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
          {data?.title || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-template" />}>
          {!isEmpty(data?.template?.data?.title) ? (
            <div>
              {HasRights([ Permissions.CONSTRUCTORS.TEMPLATES.FIND ]) ? (
                <Link to={urlPageTemplateDetails({ templateId: data?.template?.data?.id })} target="_blank">
                  {data?.template?.data?.title}
                </Link>
              ) : (
                <div>{data?.template?.data?.title}</div>
              )}
            </div>
          ) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="collection-form-image-type-title" />}>
          {getLabelFromUnderscoreString(data.image_type) || EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-categories" />}>
          {data?.categories?.data?.length > 0 ?
            data.categories.data.map((item, key) => {
              const addComma = key < data.categories.data.length - 1;

              return (
                <span key={item.id}>
                  {HasRights([ Permissions.CONTENT.CATEGORIES.VIEW ]) ? (
                    <Link to={urlPageCategoryDetails({ categoryId: item.id })} target="_blank">
                      {getCategoryName(item, addComma)}
                    </Link>
                  ) : (
                    <span>{getCategoryName(item, addComma)}</span>
                  )}
                </span>
              );
            }) : EMPTY_VALUE_PLACEHOLDER}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-published" />}>
          <PublishedTag
            published={data?.is_published || false}
            publishedDate={data?.published_at || null}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
          {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>
          {getFormattedDate(data?.updated_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
          {getFormattedDate(data?.published_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
          {getFormattedDate(data?.released_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
          {!isEmpty(data?.released_to) ? dayjs(data?.released_to).utc().format(DATE_TIME_FORMAT) : <PiInfinity />}
        </Descriptions.Item>
        <Descriptions.Item label={<IntlMessages id="features-use-utc" />}>
          <StatusTag active={data?.use_utc_zero_time || false} />
        </Descriptions.Item>
      </Descriptions>

      <TemplateCollapsibleCards
        dictionary={collectionDictionary}
        languages={languages}
        data={templateCardsData}
        targetData={data?.target?.data}
      />
    </Card>
  );
};


CollectionDetailsCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    template: PropTypes.object,
    image_type: PropTypes.string,
    categories: PropTypes.object,
    is_published: PropTypes.bool,
    published_at: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    released_at: PropTypes.string,
    released_to: PropTypes.string,
    use_utc_zero_time: PropTypes.bool,
    target: PropTypes.object,
  }).isRequired,
  collectionDictionary: PropTypes.object,
  templateCardsData: PropTypes.object,
  languages: PropTypes.array,
};

export default CollectionDetailsCard;
