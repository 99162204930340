import React from 'react';
import { Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TYPE_COLOR, TYPE_DICTIONARY, TYPE_IMAGE } from '../../const/templates';
import ColorFieldView from '../ColorFieldView';
import DictionaryFieldView from '../DictionaryFieldView';
import DefaultFieldView from '../DefaultFieldView/DefaultFieldView';
import ImageFieldView from '../ImageFieldView';



const PresetDataPreview = ({ preset = {} }) => {
  const customise = useSelector((state) => state.customise);

  const presetField = (field, value) => {
    switch (field.type) {
      case TYPE_COLOR:
        return <ColorFieldView color={value} />;
      case TYPE_DICTIONARY:
        return <DictionaryFieldView dictionaryId={value} />;
      case TYPE_IMAGE:
        return <ImageFieldView src={value} />;
      default:
        return <DefaultFieldView value={value} />;
    }
  };

  return (
    <Descriptions bordered column={1}>
      {Object.entries(preset?.data ?? {})
        .map(([ key, value ]) => {
          const field = preset?.template?.data?.fields?.data?.find((field) => Number(field.id) === Number(value.template_field_id));

          if (!field) {
            return null;
          }

          return (
            <Descriptions.Item key={key} label={field[`title_${customise?.language}`] || field?.name}>
              {presetField(field, value.value)}
            </Descriptions.Item>
          );
        })}
    </Descriptions>
  );
};

PresetDataPreview.propTypes = {
  preset: PropTypes.shape({}),
};

export default PresetDataPreview;
