import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const TagsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Tags' ],
  endpoints: (builder) => {
    return ({
      getTags: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/tags${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        providesTags: [ 'Tags' ],
      }),
    });
  },
});

export const {
  useGetTagsQuery,
} = TagsApiSlice;
