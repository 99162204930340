import { configureStore } from '@reduxjs/toolkit';
import createDebounce from 'redux-debounced';
import { setupListeners } from '@reduxjs/toolkit/query';
import customiseReducer from './features/customise/customiseSlice';
import authReducer from '../domains/Auth/features/authSlice';
import profileReducer from '../domains/Profile/features/profileSlice';
import { apiSlice } from './api/apiSlice';



export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    profile: profileReducer,
    customise: customiseReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat([
        createDebounce(),
        apiSlice.middleware,
      ]);
  },
});

setupListeners(store.dispatch);
