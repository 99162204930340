const presets = {
  'presets-breadcrumbs': 'Presets',
  'presets-filter-title': 'Filter presets',
  'presets-is-not-actual': 'Preset should be updated',
  'presets-table-author-column': 'Author',
  'presets-form-id-placeholder': 'Specify id(s)',
  'presets-form-title-placeholder': 'Specify preset title',
  'presets-form-template-type': 'Template content type',
  'presets-form-template-type-placeholder': 'Enter template content type',
  'presets-template-details': 'Preset data',
  'presets-template-hint': 'Template fields would be filled with selected preset data',
  'presets-is-actual': 'Is actual',
  'presets-form-preview-hint': 'Upload an image which represents how this preset looks in the app',
  'presets-form-preview-label': 'Upload image file',
  'presets-preview-image': '* The image is a rough representation of what it will look like in the application.',
};

export default presets;
