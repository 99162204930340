import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Col, Collapse, DatePicker, Divider, Row, Tooltip, Typography } from 'antd';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../const/colors';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Template builder/const/templates';
import { DATE_FORMAT } from '../../../../const/system';
import { DEFAULT_RELEASE_TIME } from '../FormEditFeature/FormEditFeature.const';
import modifyDefaultValue from '../../../Template builder/utils/modifyDefaultValue';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import isFieldRequired from '../../../Content/utils/isFieldRequired';
import isDateDisabled from '../../../../lib/isDateDisabled';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import mkTemplateDynamicFormData from '../../../Content/utils/mkTemplateDynamicFormData';
import DynamicFormDataFields from '../../../Content/components/DynamicFormDataFields';
import { mkValidationSchema } from './FormCreateFeature.const';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import TitleWithAutomaticGeneration from '../../../../components/TitleWithAutomaticGeneration';
import { fieldTypeToValidationRule } from '../../../Content/forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import mkMainAndOptionalTemplateFields from '../../../Template builder/utils/mkMainAndOptionalTemplateFields';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import PresetPreview from '../../../../components/PresetPreview';
import applyPreset from '../../../../lib/applyPreset';
import Spinner from '../../../../components/Spinner';
import TargetingSettings from '../../../../components/TargetingSettings';
import { useGetTemplatesQuery } from '../../../Template builder/api/templatesApiSlice';
import { useGetFeaturesUnavailableReleaseDatesQuery } from '../../api/featuresApiSlice';
import { useGetPresetsQuery } from '../../../Template builder/api/presetsApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

let validation = {};

const FormCreateFeature = ({
  initialErrors,
  onSubmit,
  onCancel,
  isSubmitting,
  featureSource,
  featureConfig,
}) => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const { onBlur } = useDatepickerHandler();
  const [ currentTemplate, setCurrentTemplate ] = useState(null);

  const defaultConfigUtcValue = featureConfig?.config?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !featureConfig?.config?.publish?.show_utc0_field;
  const useDatePickerTime = featureConfig?.config?.publish?.use_time ?? false;
  const hasReleaseRange = featureConfig?.config?.release_range ?? false;
  const useAutomaticGeneration = featureConfig?.config?.title?.use_automatic_generation ?? true;

  const { data: templates = { data: [] }, isFetching: isTemplatesFetching } = useGetTemplatesQuery({
    queryParams: `limit=0&search=status:1;content_type:${featureSource}`,
  });

  const {
    data: unavailableDates = [],
    isFetching: isDatesFetching,
  } = useGetFeaturesUnavailableReleaseDatesQuery({ featureSource });

  const {
    data: { data: presets } = { data: [] },
    isFetching: isPresetsFetching,
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${currentTemplate}`,
  }, { skip: !currentTemplate });

  const template = templates?.data?.find((template) => Number(template.id) === Number(currentTemplate));
  const modifiedErrors = useModifiedDynamicDataErrors(currentTemplate, template, initialErrors, language);
  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(template?.fields?.data);

  useEffect(() => {
    validation = mkValidationSchema(featureConfig, unavailableDates, {});
  }, [ isPresetsFetching ]);

  const dateCellRender = (current) => {
    const style = {};

    if (unavailableDates.includes(current.format(DATE_FORMAT))) {
      style.background = appColors.smokeGray;
      style.borderRadius = 0;
    }

    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  const applyTemplate = async (value, setFieldValue) => {
    setCurrentTemplate(value);
    await setFieldValue('preset_id', null);
    await setFieldValue('template_id', value);

    if (!value) {
      setFieldValue('data', {});
      return;
    }

    const data = {};
    const dataSchema = {};
    const template = templates?.data?.find((template) => template.id === value);

    template?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach((field) => {
        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[field[`display_name_${language}`]] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        data[field[`display_name_${language}`]] = {
          value: field.type === TYPE_IMAGE ? [] : modifyDefaultValue(field),
          template_field_id: field.id,
        };
      });

    setFieldValue('data', data);
    setFieldValue('target', {
      min_version: template?.target?.data?.min_version,
      max_version: template?.target?.data?.max_version,
      platform: template?.target?.data?.platform,
      countries: template?.target?.data?.countries,
    });

    validation = mkValidationSchema(featureConfig, unavailableDates, {
      data: Yup.object().shape(dataSchema),
    });
  };

  const initialValues = {
    is_in_featured: false,
    use_utc_zero_time: defaultConfigUtcValue,
    released_at: null,
    ...hasReleaseRange && {
      released_to: null,
    },
    template_id: null,
    preset_id: null,
    target: {
      min_version: null,
      max_version: null,
      platform: [],
      countries: [],
    },
    data: {},
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialErrors={modifiedErrors}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, template?.fields?.data), resetForm);
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, resetForm }) => {
        const preset = presets?.find((preset) => Number(preset.id) === Number(values?.preset_id));

        return (
          <Form layout="vertical" >
            <Spinner spinning={isSubmitting || isDatesFetching}>
              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: getFeatureSourceTranslation(featureSource) })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={isTemplatesFetching}
                          allowClear
                          showSearch
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                          options={getTemplateOptions(templates.data)}
                          onChange={async (value) => {
                            await applyTemplate(value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={isPresetsFetching}
                          allowClear
                          showSearch
                          disabled={!currentTemplate}
                          name="preset_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                          options={getTemplateOptions(presets)}
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, template?.fields?.data ?? []);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    {featureConfig?.config?.show_in_featured_enabled && (
                      <Col span={24}>
                        <FormItem
                          name='is_in_featured'
                          label={
                            <Row className='w-full' justify="space-between" align="middle">
                              <IntlMessages id="features-form-show-featured-label" />
                              <Tooltip placement="top" title={<IntlMessages id="features-show-in-features-info" />}>
                                <InfoCircleOutlined className='hp-ml-8' />
                              </Tooltip>
                            </Row>
                          }
                        >
                          <Switch name="is_in_featured" />
                        </FormItem>
                      </Col>
                    )}

                    {hasReleaseRange ? (
                      <>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-at' })}
                            name='released_at'
                            required
                          >
                            <DatePicker
                              name='released_at'
                              showToday
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                unavailableDates,
                                !isEmpty(values?.released_to)
                            && date.isAfter(moment(values?.released_to)),
                              )}
                              value={values?.released_at ? moment(values.released_at) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_at', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_at', true);
                                await onBlur('released_at', target.value, setFieldValue);
                              }}
                              dateRender={dateCellRender}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-to' })}
                            name='released_to'
                            required
                          >
                            <DatePicker
                              name='released_to'
                              showTime={useDatePickerTime
                                ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                                : false}
                              disabledDate={(date) => isDateDisabled(
                                date,
                                [],
                                !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at)),
                              )}
                              value={values?.released_to ? moment(values.released_to) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_to', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_to', true);
                                await onBlur('released_to', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <FormItem
                          label={intl.formatMessage({ id: 'ui-general-released-at' })}
                          name='released_at'
                          required
                        >
                          <DatePicker
                            name='released_at'
                            showToday
                            showTime={useDatePickerTime
                              ? { defaultValue: moment(DEFAULT_RELEASE_TIME, 'HH:mm:ss') }
                              : false}
                            disabledDate={(date) => isDateDisabled(date, unavailableDates, false)}
                            value={values?.released_at ? moment(values.released_at) : null}
                            onChange={async (date, dateString) => {
                              await setFieldValue('released_at', dateString);
                            }}
                            onBlur={async ({ target }) => {
                              await setFieldTouched('released_at', true);
                              await onBlur('released_at', target.value, setFieldValue);
                            }}
                            dateRender={dateCellRender}
                          />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <Collapse accordion className='hp-mb-24'>
                        <Panel
                          key="additional-info"
                          header={
                            <Title className='collapse-item-title' level={5}>
                              {intl.formatMessage({ id: 'ui-general-optional-data' })}
                            </Title>
                          }
                        >
                          <Row gutter={16}>
                            <Col span={24}>
                              <TitleWithAutomaticGeneration
                                rows={2}
                                useAutomaticGeneration={useAutomaticGeneration}
                              />
                            </Col>

                            <Col span={24}>
                              <FormItem
                                className='ant-form-item-col'
                                name="description"
                                label={<IntlMessages id="ui-general-description" />}
                              >
                                <Input.TextArea
                                  name='description'
                                  placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
                                  rows={2}
                                  showCount
                                  maxLength={1000}
                                />
                              </FormItem>
                            </Col>

                            <Col span={24}>
                              <FormItem
                                name='use_utc_zero_time'
                                label={
                                  <Row className='w-full' justify="space-between" align="middle">
                                    <IntlMessages id="features-use-utc" />
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <>
                                          <IntlMessages id="features-use-utc-info" />
                                          <div>
                                            <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                              <u><IntlMessages id="ui-general-documentation-link" /></u>
                                            </Link>
                                          </div>
                                        </>
                                      }
                                    >
                                      <InfoCircleOutlined className='hp-ml-8' />
                                    </Tooltip>
                                  </Row>
                                }
                              >
                                <Switch
                                  name="use_utc_zero_time"
                                  defaultChecked={defaultConfigUtcValue}
                                  disabled={isDisabledUtcField}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              {values?.template_id && (
                <>
                  <Divider />
                  <Title className="hp-mb-24" level={4}>
                    <IntlMessages id="ui-general-template-data" />
                  </Title>
                  <Row gutter={[ 16, 16 ]} className="hp-mb-24">
                    <Col span={24}>
                      <DynamicFormDataFields
                        setFieldValue={setFieldValue}
                        fields={mainFields}
                        values={values}
                        span={24}
                      />
                      {!!optionalFields.length && (
                        <Collapse>
                          <Panel
                            key="1"
                            header={
                              <Title level={5} className='collapse-item-title'>
                                <IntlMessages id="ui-general-additional-parameters" />
                              </Title>
                            }
                          >
                            <DynamicFormDataFields
                              setFieldValue={setFieldValue}
                              fields={optionalFields}
                              values={values}
                              span={24}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Col>
                  </Row>

                  <Divider />

                  <TargetingSettings setFieldValue={setFieldValue} values={values} />
                </>
              )}

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      setCurrentTemplate(null);
                      resetForm(initialValues);
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateFeature.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  featureSource: PropTypes.string.isRequired,
  featureConfig: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormCreateFeature;
