import { typeColors } from '../domains/Content/pages/Images/PageImages/PageImages.const';



const mapImageTypeToColor = (type) => {
  if (!typeColors[type]) {
    return typeColors.cargo;
  }

  return typeColors[type];
};

export default mapImageTypeToColor;
