const typeColors = {
  sharp: '#e83e8c',
  cargo: '#20c997',
  blend: '#00cfe8',
  image: '#6610f2',
  bee: '#ffc107',
};

export {
  typeColors,
};
