import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col } from 'antd';
import IntlMessages from '../lang/IntlMessages';



const BreadCrumbs = (props) => {
  // eslint-disable-next-line react/prop-types
  const { breadCrumbParent, breadCrumbParent2, breadCrumbParent3, breadCrumbActive } = props;

  return (
    <Col>
      <Breadcrumb className="hp-d-flex hp-flex-wrap">
        <Breadcrumb.Item>
          <Link to="/"><IntlMessages id='ui-general-home' /></Link>
        </Breadcrumb.Item>

        {breadCrumbParent && (
          <Breadcrumb.Item>{breadCrumbParent}</Breadcrumb.Item>
        )}

        {breadCrumbParent2 && (
          <Breadcrumb.Item>{breadCrumbParent2}</Breadcrumb.Item>
        )}

        {breadCrumbParent3 && (
          <Breadcrumb.Item>{breadCrumbParent3}</Breadcrumb.Item>
        )}

        <Breadcrumb.Item>{breadCrumbActive}</Breadcrumb.Item>
      </Breadcrumb>
    </Col>
  );
};

export default BreadCrumbs;
