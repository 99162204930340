import React from 'react';
import { Field, FormItem, Input } from 'formik-antd';
import PropTypes from 'prop-types';
import { Button, Col, List, Popconfirm, Row } from 'antd';
import { Trash } from 'iconsax-react';
import { RiErrorWarningLine } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const FormStructureField = ({
  initialValues,
  // eslint-disable-next-line react/prop-types
  index,
  onDeleteField,
}) => {
  return (
    <List.Item>
      <Row gutter={[ 16, 16 ]}>
        <Col>
          <FormItem
            required
            name={`fields[data][${index}][label]`}
            label={<IntlMessages id='ui-general-label' />}
          >
            <Input name={`fields[data][${index}][label]`} defaultValue={initialValues.label} />
          </FormItem>
        </Col>
        <Col>
          <FormItem
            name={`fields[data][${index}][validation][min]`}
            label={<IntlMessages id='ui-general-min' />}
          >
            <Input
              name={`fields[data][${index}][validation][min]`}
              defaultValue={initialValues.validation?.min}
            />
          </FormItem>
        </Col>
        <Col>
          <FormItem
            name={`fields[data][${index}][validation][max]`}
            label={<IntlMessages id='ui-general-max' />}
          >
            <Input
              name={`fields[data][${index}][validation][max]`}
              defaultValue={initialValues.validation?.max}
            />
          </FormItem>
        </Col>
        <Col>

          <FormItem
            name={`fields[data][${index}][validation][required]`}
            label={<IntlMessages id='ui-general-required' />}
          >
            <Field
              type='checkbox'
              name={`fields[data][${index}][validation][required]`}
            />
          </FormItem>
        </Col>
        <Col className="hp-pt-24">
          <Popconfirm
            title={<IntlMessages id="structures-fields-delete-confirm-message" />}
            placement="top"
            onConfirm={() => {
              onDeleteField();
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            okButtonProps={{ danger: true }}
          >
            <Button className="hp-text-color-danger-1" size="small"><Trash /></Button>
          </Popconfirm>
        </Col>
      </Row>
    </List.Item>
  );
};

FormStructureField.propTypes = {
  onDeleteField: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    label: PropTypes.string,
    validation: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }).isRequired,
  formErrors: PropTypes.object,
  values: PropTypes.object,
};

FormStructureField.defaultProps = {
  formErrors: {},
};

export default FormStructureField;
