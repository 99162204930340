const dictionaries = {
  'dictionaries-breadcrumbs': 'Словари',
  'dictionaries-filter-favorite-label': 'Избранные',
  'dictionaries-status-ready': 'Готово',
  'dictionaries-status-draft': 'Черновик',
  'ui-dictionaries-create': 'Создать словарь',
  'ui-dictionaries-source-field': 'Источник',
  'ui-dictionaries-version-field': 'Версия',
  'ui-dictionaries-tags-field': 'Теги',
  'form-dictionaries-source-placeholder': 'Выберите источник',
  'form-dictionaries-structure-placeholder': 'Выберите версию структуры',
  'form-dictionaries-structure-field': 'Версия структуры',
  'form-dictionaries-validation-field': 'Правила валидации',
  'form-dictionaries-upgrade-field': 'Повысить до последней минорной версии',
  'form-dictionaries-field-field': 'Поле',
  'form-dictionaries-field-value-field': 'Значение поля',
  'form-dictionaries-field-placeholder': 'Выберите поле',
  'dictionaries-create-title': 'Создание словаря',
  'dictionaries-edit-title': 'Редактирование словаря',
  'dictionaries-preview-structure-fields': 'Поля',
  'dictionaries-update-status-confirm-message': 'Вы точно хотите сделать словарь доступным для публикации?',
  'dictionaries-update-status-confirm-message-danger': 'После подтверждения действия все пустые поля заполнятся значениями из английского языка',
  'dictionaries-new-version-available': 'Доступна новая минорная версия',
  'dictionaries-filter-title': 'Фильтр словарей',
  'translations-edit-button': 'Редактировать словарь',
  'ui-dictionaries-status-ready': 'Отметить как Готовый',
  'dictionaries-missed-translations': 'Переводы еще не добавлены.',
  'dictionaries-translation-preview': 'Предпросмотр переводов словаря',
  'dictionaries-export-template': 'Экспорт шаблонов',
  'dictionaries-form-file-label': 'Файл',
  'form-dictionaries-version-placeholder': 'Выберите версию',
  'form-dictionaries-version-label': 'Версия',
  'dictionaries-import-failed': 'Ошибка импорта словарей',
  'dictionaries-imported-list': 'Импортированные словари',
  'dictionaries-structure-label': 'Структура',
  'dictionaries-import-success': 'Словари импортированы успешно',
  'dictionaries-import-card-title': 'Импорт словарей',
  'dictionaries-import-page-failed': 'Упс! Что пошло не так!',
  'dictionaries-empty-notification': 'Нельзя просмотреть словарь для данной записи, так-как словарь не был создан',
  'dictionaries-delete-notification': 'Удаление словаря запрещено, так как данный словарь уже используется',
};

export default dictionaries;
