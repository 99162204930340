import React from 'react';
import { Alert, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ImageModalCardHeader from '../ImageModalCardHeader';
import FormEditImage from '../../forms/FormEditImage';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import PreviewImages from '../PreviewImages';



const ImageModalCardBodyEdit = ({ data, categories, hashtags }) => {
  return (
    <>
      <ImageModalCardHeader data={data} />
      <Row gutter={[ 32, 32 ]} justify="center" className='hp-mt-16 hp-border-top-1'>
        <PreviewImages
          icon={data.icon.data.path}
          preview={data.preview.data.path}
          mdSpan={7}
        />
        <Col xs={24} md={10}>
          <FormEditImage
            allCategories={categories}
            allHashtags={hashtags}
          />
        </Col>
        <Col span={24}>
          <Row justify="center" className='hp-mx-0 hp-pb-16'>
            <Alert
              message={<IntlMessages id='feeds-modal-edit-image-save-info' />}
              type="info"
              showIcon
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

ImageModalCardBodyEdit.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  categories: PropTypes.array,
  hashtags: PropTypes.array,
};

export default ImageModalCardBodyEdit;
