import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageRBACRoleDetails from '../../../../../urls/urlPageRBACRoleDetails';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const getRolesListTableColumns = (handleDeleteRole, tableSize) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.09,
    render: (value) => value,
  },
  {
    title: <IntlMessages id="roles-table-role-col" />,
    align: 'center',
    width: tableSize.width * 0.2,
    render: (item) => {
      return (
        <div>
          {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ], true) ? (
            <Link to={urlPageRBACRoleDetails({ roleId: item.id })} target="_blank">{item.name}</Link>
          ) : item.name}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-display-name" />,
    dataIndex: 'display_name',
    align: 'center',
    width: tableSize.width * 0.2,
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-general-description" />,
    dataIndex: 'description',
    align: 'center',
    width: tableSize.width * 0.2,
    render: (value) => <CollapsibleText text={value} />,
  },
  ...(HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW, Permissions.ADMINISTRATION.ROLES.ROLES_LIST.DELETE_ROLE ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.1,
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ], true) && (
              <Tooltip placement="top" title={<IntlMessages id="roles-table-tooltip-edit-btn" />}>
                <div className="hp-text-right">
                  <Link
                    to={urlPageRBACRoleDetails({ roleId: item.id })}
                    target="_blank"
                  >
                    <Eye
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </Link>
                </div>
              </Tooltip>
            )}

            {HasRights([ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.DELETE_ROLE ]) && !item.is_secure && (
              <Popconfirm
                title={<IntlMessages id="roles-table-delete-btn-confirm-message" />}
                placement="top"
                onConfirm={() => handleDeleteRole(item.id)}
                okText={<IntlMessages id="ui-general-yes" />}
                cancelText={<IntlMessages id="ui-general-no" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
              >
                <Tooltip placement="top" title={<IntlMessages id="roles-table-tooltip-delete-btn" />}>
                  <div className="hp-text-right">
                    <Delete
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
