const profile = {
  'profile-change-user-avatar': 'Изменить Аватар',
  'profile-title': 'Профиль',
  'profile-personal-information-page': 'Личные данные',
  'personal-information-last-login-field': 'Дата последнего входа',
  'form-upload-user-avatar-placeholder': 'Загрузить Аватар',
  'form-upload-user-avatar-label': 'Аватар',
  'form-upload-user-avatar-description': 'Нажмите или перетащите файл в эту область для загрузки',
  'form-upload-user-avatar-edit-title': 'Редактирование изображения',
};

export default profile;
