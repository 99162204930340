const roles = {
  'roles-breadcrumbs': 'Роли',
  'role-details-breadcrumbs': 'Детали роли',
  'roles-search-by': 'Поиск по имени либо отображаемому имени...',
  'roles-create-role-title': 'Создать роль',
  'roles-table-role-col': 'Название роли',
  'roles-table-tooltip-edit-btn': 'Детали роли',
  'roles-table-tooltip-delete-btn': 'Удалить роль',
  'roles-table-delete-btn-confirm-message': 'Вы действительно хотите удалить роль?',
  'form-user-roles-name-field': 'Название роли',
  'form-user-role-name-placeholder': 'Укажите название роли',
  'form-user-role-display-name-placeholder': 'Укажите отображаемое название роли',
  'form-user-roles-description-placeholder': 'Укажите описание роли',
  'role-details-card-header': 'Детали роли',
  'role-permissions-card-header': 'Список прав роли',
  'form-user-role-permissions-placeholder': 'Выберите права для роли',
};

export default roles;
