import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { Button, Card, Col, Row, Space } from 'antd';
import { RiArrowLeftLine } from 'react-icons/ri';
import urlPageCollections from '../../../../../urls/urlPageCollections';
import urlPageCollectionEdit from '../../../../../urls/urlPageCollectionEdit';
import handleResponse from '../../../../../lib/handleResponse';
import transformErrors from '../../../../../lib/transformErrors';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreateCollection from '../../../forms/FormCreateCollection';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { useCreateCollectionMutation } from '../../../api/collectionsApiSlice';



const PageCreateCollection = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createCollection, { isLoading: isCreatingCollection } ] = useCreateCollectionMutation();

  const onSubmit = (values) => {
    createCollection({ values })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [ () => navigate(urlPageCollectionEdit({ collectionId: response?.data?.id })) ],
      ))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-create-failed',
          [ () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(transformErrors(error?.data?.errors));
            }
          } ],
          error,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCollections()}>
              <IntlMessages id="collections-breadcrumbs" />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            <Col>
              <Space>
                <Link to={urlPageCollections()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreateCollection
              initialErrors={errors}
              isSubmitting={isCreatingCollection}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageCreateCollection;
