import {
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_DOUBLE,
  TYPE_IMAGE,
  TYPE_INTEGER,
  TYPE_STRING,
  VALIDATION_RULES_FILE,
  VALIDATION_RULES_OPTIONAL,
  VALIDATION_RULES_REQUIRED,
} from '../const/templates';



export const buildValidationRules = (values) => {
  const requiredRule = values.is_required ? VALIDATION_RULES_REQUIRED : VALIDATION_RULES_OPTIONAL;

  const validation = [ requiredRule, ...values.validation ?? [] ];

  let typeRule = '';

  // eslint-disable-next-line default-case
  switch (values.type) {
    case TYPE_BOOLEAN:
      typeRule = TYPE_BOOLEAN;
      break;
    case TYPE_STRING:
      typeRule = TYPE_STRING;
      break;
    case TYPE_INTEGER:
      typeRule = TYPE_INTEGER;
      break;
    case TYPE_DOUBLE:
      typeRule = TYPE_DOUBLE;
      break;
    case TYPE_ARRAY:
      typeRule = TYPE_ARRAY;
      break;
    case TYPE_IMAGE:
      typeRule = VALIDATION_RULES_FILE;
      break;
    case TYPE_COLOR:
      typeRule = TYPE_STRING;
      break;
    case TYPE_DATE:
      typeRule = TYPE_DATE;
      break;
  }

  if (typeRule) {
    validation.push(typeRule);
  }

  return validation;
};
