const pushIcons = {
  'push-icons-breadcrumbs': 'Пуш иконки',
  'push-icons-preview-title': 'Просмотр пуш иконки',
  'push-icons-table-folder-column': 'Папка',
  'push-icons-table-message-column': 'Сообщение',
  'push-icons-table-link-column': 'Ссылка',
  'push-icons-create-title': 'Создать пуш иконку',
  'push-icons-update-title': 'Изменить пуш сообщение',
  'push-icons-search': 'Поиск по id',
  'push-icon-create-form-dropzone-label': 'Файл',
  'push-icon-create-form-dropzone-description': 'Нажмите или перетащите файл в эту область для загрузки. Имя файла должно быть i.(png | jpg | jpeg).',
  'push-icon-create-form-text-label': 'Текст',
  'push-icon-create-form-folder-label': 'Папка',
};

export default pushIcons;
