import capitalize from 'lodash/capitalize';



const imageStatuses = {
  release_on: 'release_on',
  ready_for_release: 'ready_for_release',
  bonus: 'bonus',
  archive: 'archive',
  achievement: 'achievement',
  pack: 'pack',
  daily: 'daily',
  dismissed: 'dismissed',
};

const imageStatusOptions = Object.entries(imageStatuses)
  .map(([ key, value ]) => {
    return { label: capitalize(key), value };
  });

export { imageStatuses, imageStatusOptions };
