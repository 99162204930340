import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const ButtonPrimary = ({
  className = '',
  onClick = () => {},
  text = <IntlMessages id='ui-general-edit' />,
  disabled = false,
}) => {
  return (
    <Button
      disabled={disabled}
      className={className}
      type='primary'
      ghost
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

ButtonPrimary.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.node,
  disabled: PropTypes.bool,
};

export default ButtonPrimary;
