const getBonusesOptions = (bonuses) => {
  let bonusesData = [ ...bonuses ];

  bonusesData = bonusesData.map((item) => {
    return {
      label: item.code,
      value: item.code,
    };
  });

  return bonusesData;
};

export default getBonusesOptions;
