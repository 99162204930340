import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Row, Spin } from 'antd';
import Yup from '../../../../vendor/yup';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makeSelectFilterSort from '../../../../lib/makeSelectFilterSort';



const { Option } = Select;

const validationSchema = Yup.object().shape({
  developer_id: Yup.number().nullable(),
});


const FormTestersMode = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors = [],
  usersList = [],
  isActiveTestMode,
  handleStopTestMode,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={onSubmit}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, values, resetForm, dirty } = props;

        return (
          <Spin spinning={isSubmitting} tip="Applying data. Please wait...">
            <Form layout="vertical" className='w-full'>
              <Alert
                className='hp-mb-24'
                type="warning"
                showIcon
                closable
                message="Note:"
                description='Users test mode is sharing between browser tabs. You can stop test manually or it will be automatically stopped after application re-login.'
              />

              <Col span={24}>
                <FormItem
                  label="User:"
                  name='developer_id'
                >
                  <Select
                    showSearch
                    disabled={isSubmitting}
                    name="developer_id"
                    className='w-full'
                    placeholder="Select user you want to mask with"
                    value={values.developer_id}
                    optionFilterProp="children"
                    filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
                  >
                    {usersList.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>

              <Row gutter={[ 16, 16 ]} justify='center'>
                {isActiveTestMode && (
                  <Col>
                    <Button
                      className='w-full'
                      danger
                      type='secondary'
                      disabled={isSubmitting}
                      onClick={() => {
                        resetForm();
                        handleStopTestMode();
                      }}
                    >
                      Stop test
                    </Button>
                  </Col>
                )}
                <Col>
                  <SubmitButton
                    className='w-full'
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    Run test
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

FormTestersMode.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleStopTestMode: PropTypes.func.isRequired,
  usersList: PropTypes.array,
  isActiveTestMode: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.array,
  values: PropTypes.shape({
    developer_id: PropTypes.number,
  }),
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormTestersMode;
