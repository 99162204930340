import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import Yup from '../vendor/yup';



export const immutableField = (originalValue) => {
  return Yup.mixed().test(
    'immutable',
    'This field is immutable',
    (value) => {
      if (isArray(value)) {
        return isEqual(sortBy(value), sortBy(originalValue));
      }

      return value === originalValue;
    },
  );
};
