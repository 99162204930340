const news = {
  'news-breadcrumbs': 'Старые новости',
  'news-preview-title': 'Просмотр новости',
  'news-create-title': 'Создать старую новость',
  'news-update-title': 'Редактировать старую новость',
  'news-filter-title': 'Фильтр старых новостей',
  'news-form-id-placeholder': 'ID новости',
  'news-form-translations-label': 'Переводы',
  'news-form-upload-icons-placeholder': 'Загрузка иконок (2 файла)',
  'news-create-form-target-label': 'Схема/URL',
  'news-create-form-target-placeholder': '#target',
  'news-create-form-dictionary-placeholder': 'Выберите словарь',
  'news-create-form-icons-label': 'Иконки',
  'news-create-form-upload-description-label': 'Нажмите или перетащите в эту область 2 файла для загрузки',
};

export default news;
