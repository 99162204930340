import antdRU from 'antd/lib/locale-provider/ru_RU';
import saMessages from '../locales/russian';



const ruLang = {
  messages: {
    ...saMessages,
  },
  antd: antdRU,
  locale: 'ru-RU',
};

export default ruLang;
