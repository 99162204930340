import isString from 'lodash/isString';



const cleanupHtmlTags = (value) => {
  if (!isString(value)) {
    return value;
  }

  return value?.replace(/<("[^"]*"|'[^']*'|[^'">])*?>/g, '');
};

export default cleanupHtmlTags;
