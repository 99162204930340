import { RiCloseFill } from 'react-icons/ri';
import { Modal } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import FormTestersMode from '../forms/FormTestersMode';



const ModalTestersMode = ({
  isActiveModal,
  onCancel,
  initialValues = {},
  formErrors = [],
  data = [],
  isActiveTestMode,
  handleApplyTestMode,
  handleStopTestMode,
  isSubmitting = false,
}) => {
  return (
    <Modal
      title="Testers mode"
      width={440}
      centered
      destroyOnClose
      visible={isActiveModal}
      onCancel={onCancel}
      footer={null}
      closeIcon={
        <RiCloseFill className="remix-icon text-color-black-100" size={24} />
      }
    >
      <FormTestersMode
        initialValues={initialValues}
        formErrors={formErrors}
        usersList={data}
        isActiveTestMode={isActiveTestMode}
        onSubmit={handleApplyTestMode}
        handleStopTestMode={handleStopTestMode}
        isSubmitting={isSubmitting}
      />
    </Modal>
  );
};

ModalTestersMode.propTypes = {
  isActiveModal: PropTypes.bool.isRequired,
  isActiveTestMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleStopTestMode: PropTypes.func.isRequired,
  handleApplyTestMode: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  formErrors: PropTypes.array,
  data: PropTypes.array,
  isSubmitting: PropTypes.bool,
};

export default ModalTestersMode;
