import React from 'react';
import { Outlet } from 'react-router-dom';
import { PrivateRoute } from '../../components/HasRights/HasRights';
import { Permissions } from '../../const/permissions';
import { FEATURE_SHOW_OLD_NEWS } from '../../const/features';
import { FEATURES_SOURCE_MAP } from '../../domains/Featured/pages/PageFeatures/PageFeatures.const';
import { FEEDS_SOURCE_MAP } from '../../domains/Content/pages/Feeds/PageFeeds/PageFeeds.const';
import urlPageLogin from '../../urls/urlPageLogin';
import urlPageProfile from '../../urls/urlPageProfile';
import urlPageRBACUsers from '../../urls/urlPageRBACUsers';
import urlPageRBACUserDetails from '../../urls/urlPageRBACUserDetails';
import urlPageRBACPermissions from '../../urls/urlPageRBACPermissions';
import urlPageRBACRoles from '../../urls/urlPageRBACRoles';
import urlPageRBACRoleDetails from '../../urls/urlPageRBACRoleDetails';
import urlPageLanguages from '../../urls/urlPageLanguages';
import urlPageStructures from '../../urls/urlPageStructures';
import urlPageStructuresEdit from '../../urls/urlPageStructuresEdit';
import urlPageDictionaryCreate from '../../urls/urlPageDictionaryCreate';
import urlPageDictionaries from '../../urls/urlPageDictionaries';
import urlPageDictionaryEdit from '../../urls/urlPageDictionaryEdit';
import urlPageTranslationDetails from '../../urls/urlPageTranslationDetails';
import urlPageDictionariesImport from '../../urls/urlPageDictionariesImport';
import urlPageDictionariesImportSuccess from '../../urls/urlPageDictionariesImportSuccess';
import urlPagePushIcons from '../../urls/urlPagePushIcons';
import urlPageDeeplinks from '../../urls/urlPageDeeplinks';
import urlPageNews from '../../urls/urlPageNews';
import urlPageImages from '../../urls/urlPageImages';
import urlPageNewsPriority from '../../urls/urlPageNewsPriority';
import urlPageTemplates from '../../urls/urlPageTemplates';
import urlPageCreateTemplate from '../../urls/urlPageCreateTemplate';
import urlPageDynamicNews from '../../urls/urlPageDynamicNews';
import urlPageDynamicNewsDetails from '../../urls/urlPageDynamicNewsDetails';
import urlPageEditTemplate from '../../urls/urlPageEditTemplate';
import urlPageTemplateDetails from '../../urls/urlPageTemplateDetails';
import urlPageDynamicNewsCreate from '../../urls/urlPageDynamicNewsCreate';
import urlPageDynamicNewsEdit from '../../urls/urlPageDynamicNewsEdit';
import urlPageDynamicNewsPriority from '../../urls/urlPageDynamicNewsPriority';
import urlPageCategories from '../../urls/urlPageCategories';
import urlPageAchievements from '../../urls/urlPageAchievements';
import urlPageCategoryDetails from '../../urls/urlPageCategoryDetails';
import urlPageCategoriesPriorities from '../../urls/urlPageCategoriesPriorities';
import urlPageAchievementsEdit from '../../urls/urlPageAchievementsEdit';
import urlPageAchievementsDetails from '../../urls/urlPageAchievementsDetails';
import urlPageFeatures from '../../urls/urlPageFeatures';
import urlPageFeatureCreate from '../../urls/urlPageFeatureCreate';
import urlPageFeatureDetails from '../../urls/urlPageFeatureDetails';
import urlPageFeaturesEdit from '../../urls/urlPageFeaturesEdit';
import urlPageFeaturePriorities from '../../urls/urlPageFeaturePriorities';
import urlPagePresets from '../../urls/urlPagePresets';
import urlPagePresetDetails from '../../urls/urlPagePresetDetails';
import urlPageCreatePreset from '../../urls/urlPageCreatePreset';
import urlPagePresetEdit from '../../urls/urlPagePresetEdit';
import urlPagePopups from '../../urls/urlPagePopups';
import urlPagePopupsDetails from '../../urls/urlPagePopupsDetails';
import urlPagePopupCreate from '../../urls/urlPagePopupCreate';
import urlPagePopupEdit from '../../urls/urlPagePopupEdit';
import urlPagePopupsPriority from '../../urls/urlPagePopupsPriority';
import urlPageBonuses from '../../urls/urlPageBonuses';
import urlPageBonusDetails from '../../urls/urlPageBonusDetails';
import urlPageBonusEdit from '../../urls/urlPageBonusEdit';
import urlPageRBACLogs from '../../urls/urlPageRBACLogs';
import urlPageRBACLogDetails from '../../urls/urlPageRBACLogDetails';
import urlPageCollections from '../../urls/urlPageCollections';
import urlPageCollectionCreate from '../../urls/urlPageCollectionCreate';
import urlPageCollectionEdit from '../../urls/urlPageCollectionEdit';
import urlPageCollectionDetails from '../../urls/urlPageCollectionDetails';
import urlPageCollectionPriority from '../../urls/urlPageCollectionPriority';
import urlPageFeeds from '../../urls/urlPageFeeds';
import Error404 from '../../components/Errors/404';
import PageLogin from '../../domains/Auth/pages/PageLogin';
import VerticalLayout from '../../layout/VerticalLayout';
import RequireAuth from '../../domains/Auth/components/RequireAuth';
import PageUsers from '../../domains/RBAC/pages/Users/PageUsers';
import PageProfile from '../../domains/Profile/pages/PageProfile';
import PagePersonalInformation from '../../domains/Profile/pages/PagePersonalInformation';
import PageUserDetails from '../../domains/RBAC/pages/Users/PageUserDetails';
import PagePermissions from '../../domains/RBAC/pages/Permissions/PagePermissions';
import PageRoles from '../../domains/RBAC/pages/Roles/PageRoles';
import PageRoleDetails from '../../domains/RBAC/pages/Roles/PageRoleDetails';
import PageLanguages from '../../domains/Translations/pages/Languages/PageLanguages/PageLanguages';
import PageStructures from '../../domains/Translations/pages/Structures/PageStructures';
import PageStructuresDetails from '../../domains/Translations/pages/Structures/PageStructuresDetails';
import PageDictionaries from '../../domains/Translations/pages/Dictionaries/PageDictionaries';
import PageDictionaryCreate from '../../domains/Translations/pages/Dictionaries/PageDictionaryCreate';
import PageTranslationsDetails from '../../domains/Translations/pages/Translations/PageTranslationDetails';
import PageDictionaryEdit from '../../domains/Translations/pages/Dictionaries/PageDictionaryEdit';
import PageDictionariesImport from '../../domains/Translations/pages/Dictionaries/PageDictionariesImport';
import PageDictionariesImportSuccess from '../../domains/Translations/pages/Dictionaries/PageDictionariesImportSuccess';
import PagePushIcons from '../../domains/Other/pages/Push icons/PagePushIcons';
import PageImages from '../../domains/Content/pages/Images/PageImages';
import PageDeeplinks from '../../domains/Other/pages/Deeplinks/PageDeeplinks';
import PageNews from '../../domains/Content/pages/Old news/PageNews';
import PageNewsPriority from '../../domains/Content/pages/Old news/PageNewsPriority';
import PageImagesCompressed from '../../domains/Content/pages/Images/PageImagesCompressed';
import PageTemplates from '../../domains/Template builder/pages/Templates/PageTemplates';
import PageTemplateDetails from '../../domains/Template builder/pages/Templates/PageTemplateDetails';
import PageCreateEditTemplate from '../../domains/Template builder/pages/Templates/PageCreateEditTemplate';
import PageDynamicNews from '../../domains/Content/pages/News/PageDynamicNews';
import PageDynamicNewsDetails from '../../domains/Content/pages/News/PageDynamicNewsDetails';
import PageDynamicNewsCreate from '../../domains/Content/pages/News/PageDynamicNewsCreate';
import PageDynamicNewsEdit from '../../domains/Content/pages/News/PageDynamicNewsEdit';
import PageDynamicNewsPriority from '../../domains/Content/pages/News/PageDynamicNewsPriority';
import PageCategories from '../../domains/Content/pages/Categories/PageCategories';
import PageAchievements from '../../domains/Content/pages/Achievements/PageAchievements';
import PageCategoryDetails from '../../domains/Content/pages/Categories/PageCategoryDetails';
import PageCategoriesPriorities from '../../domains/Content/pages/Categories/PageCategoriesPriorities';
import PageAchievementsEdit from '../../domains/Content/pages/Achievements/PageAchievementsEdit';
import PageAchievementDetails from '../../domains/Content/pages/Achievements/PageAchievementDetails';
import PageFeatures from '../../domains/Featured/pages/PageFeatures';
import PageFeatureCreate from '../../domains/Featured/pages/PageFeatureCreate';
import PageFeatureDetails from '../../domains/Featured/pages/PageFeatureDetails';
import PageFeaturesEdit from '../../domains/Featured/pages/PageFeaturesEdit';
import PageFeaturesPriority from '../../domains/Featured/pages/PageFeaturesPriority';
import PagePresets from '../../domains/Template builder/pages/Presets/PagePresets';
import PagePresetsCreate from '../../domains/Template builder/pages/Presets/PagePresetsCreate';
import PagePresetsEdit from '../../domains/Template builder/pages/Presets/PagePresetsEdit';
import PagePresetDetails from '../../domains/Template builder/pages/Presets/PagePresetDetails';
import PagePopups from '../../domains/Other/pages/Popups/PagePopups';
import PagePopupsDetails from '../../domains/Other/pages/Popups/PagePopupsDetails';
import PagePopupCreate from '../../domains/Other/pages/Popups/PagePopupCreate';
import PagePopupEdit from '../../domains/Other/pages/Popups/PagePopupEdit';
import PagePopupsPriority from '../../domains/Other/pages/Popups/PagePopupsPriority';
import PageBonuses from '../../domains/Content/pages/Bonuses/PageBonuses';
import PageBonusDetails from '../../domains/Content/pages/Bonuses/PageBonusDetails';
import PageBonusEdit from '../../domains/Content/pages/Bonuses/PageBonusEdit';
import PageLogs from '../../domains/RBAC/pages/Logs/PageLogs';
import PageLogDetails from '../../domains/RBAC/pages/Logs/PageLogDetails';
import PageCollections from '../../domains/Content/pages/Collections/PageCollections';
import PageCreateCollection from '../../domains/Content/pages/Collections/PageCreateCollection';
import PageEditCollection from '../../domains/Content/pages/Collections/PageEditCollection';
import PageCollectionDetails from '../../domains/Content/pages/Collections/PageCollectionDetails';
import PageCollectionPriority from '../../domains/Content/pages/Collections/PageCollectionPriority';
import PageFeeds from '../../domains/Content/pages/Feeds/PageFeeds';



const routes = [
  {
    path: urlPageLogin(),
    element: <PageLogin />,
  },
  {
    path: '/',
    element: <RequireAuth><VerticalLayout /></RequireAuth>,
    children: [
      {
        path: urlPageImages(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.CONTENT.IMAGES.LIST ]}>
            <PageImages />
          </PrivateRoute>),
      },
      {
        path: urlPageCategoryDetails(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.CONTENT.CATEGORIES.VIEW ]}>
            <PageCategoryDetails />
          </PrivateRoute>
        ),
      },
      {
        path: urlPageCategoriesPriorities(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.CONTENT.CATEGORIES.PRIORITIES.LIST ]}>
            <PageCategoriesPriorities />
          </PrivateRoute>
        ),
      },
      {
        path: urlPageCategories(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.CONTENT.CATEGORIES.LIST ]}>
            <PageCategories />
          </PrivateRoute>),
      },
      {
        path: urlPageBonuses(),
        element: <Outlet />,
        children: [
          {
            path: urlPageBonuses(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.BONUSES.LIST ]}>
                <PageBonuses />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageBonusEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.BONUSES.EDIT ]}>
                <PageBonusEdit />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageBonusDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.BONUSES.VIEW ]}>
                <PageBonusDetails />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: urlPageAchievements(),
        element: <Outlet />,
        children: [
          {
            path: urlPageAchievements(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.ACHIEVEMENTS.LIST ]}>
                <PageAchievements />
              </PrivateRoute>),
          },
          {
            path: urlPageAchievementsDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.ACHIEVEMENTS.VIEW ]}>
                <PageAchievementDetails />
              </PrivateRoute>),
          },
          {
            path: urlPageAchievementsEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.ACHIEVEMENTS.EDIT ]}>
                <PageAchievementsEdit />
              </PrivateRoute>),
          },
        ],
      },
      {
        path: urlPageCollections(),
        element: <Outlet />,
        children: [
          {
            path: urlPageCollections(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.LIST ]}>
                <PageCollections />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCollectionDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.VIEW ]}>
                <PageCollectionDetails />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCollectionCreate(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.CREATE ]}>
                <PageCreateCollection />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCollectionEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.EDIT ]}>
                <PageEditCollection />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCollectionPriority(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.PRIORITIES.LIST ]}>
                <PageCollectionPriority />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: '/compressed/cargo',
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.CONTENT.IMAGES.LIST ]}>
            <PageImagesCompressed />
          </PrivateRoute>),
      },
      {
        path: urlPageProfile(),
        element: <PageProfile />,
        children: [
          {
            path: urlPageProfile(),
            element: <PagePersonalInformation />,
          },
        ],
      },
      {
        path: urlPageRBACUsers(),
        element: <Outlet />,
        children: [
          {
            path: urlPageRBACUsers(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE ]}>
                <PageUsers />
              </PrivateRoute>),
          },
          {
            path: urlPageRBACUserDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]}>
                <PageUserDetails />
              </PrivateRoute>),
          },
        ],
      },
      {
        path: urlPageRBACRoles(),
        element: <Outlet />,
        children: [
          {
            path: urlPageRBACRoles(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE ]}>
                <PageRoles />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageRBACRoleDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.VIEW ]}>
                <PageRoleDetails />
              </PrivateRoute>),
          },
        ],
      },
      {
        path: urlPageRBACPermissions(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST ]}>
            <PagePermissions />
          </PrivateRoute>),
      },
      {
        path: urlPageRBACLogs(),
        element: <Outlet />,
        children: [
          {
            path: urlPageRBACLogs(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.LOGS.LIST ]}>
                <PageLogs />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageRBACLogDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.ADMINISTRATION.LOGS.DETAILS ]}>
                <PageLogDetails />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: urlPageLanguages(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.LANGUAGES.LANGUAGES_LIST.VIEW_PAGE ]}>
            <PageLanguages />
          </PrivateRoute>),
      },
      {
        path: urlPageStructures(),
        element: <Outlet />,
        children: [
          {
            path: urlPageStructures(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.STRUCTURES.LIST.VIEW_PAGE ]}>
                <PageStructures />
              </PrivateRoute>),
          },
          {
            path: urlPageStructuresEdit(),
            element: (
              <PrivateRoute
                allowedPermissions={[
                  Permissions.TRANSLATIONS.STRUCTURES.DETAILS.EDIT,
                  Permissions.TRANSLATIONS.STRUCTURES.DETAILS.GET,
                ]}
              >
                <PageStructuresDetails />
              </PrivateRoute>),
          },
        ],
      },
      {
        path: urlPageDictionaries(),
        element: <Outlet />,
        children: [
          {
            path: urlPageDictionaries(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE ]}>
                <PageDictionaries />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDictionaryCreate(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.CREATE ]}>
                <PageDictionaryCreate />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDictionaryEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.EDIT ]}>
                <PageDictionaryEdit />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageTranslationDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
                <PageTranslationsDetails />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDictionariesImport(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.IMPORT ]}>
                <PageDictionariesImport />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDictionariesImportSuccess(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.IMPORT ]}>
                <PageDictionariesImportSuccess />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: urlPagePushIcons(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.OTHER.PUSH_ICONS.LIST.VIEW ]}>
            <PagePushIcons />
          </PrivateRoute>
        ),
      },
      {
        path: urlPageDeeplinks(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.OTHER.DEEPLINKS.LIST ]}>
            <PageDeeplinks />
          </PrivateRoute>
        ),
      },
      {
        path: urlPagePopups(),
        element: <Outlet />,
        children: [
          {
            path: urlPagePopups(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.OTHER.POPUPS.LIST ]}>
                <PagePopups />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePopupCreate(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.OTHER.POPUPS.CREATE ]}>
                <PagePopupCreate />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePopupsDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.OTHER.POPUPS.FIND ]}>
                <PagePopupsDetails />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePopupEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.OTHER.POPUPS.EDIT ]}>
                <PagePopupEdit />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePopupsPriority(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.OTHER.POPUPS.PRIORITY.LIST ]}>
                <PagePopupsPriority />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: urlPageDynamicNews(),
        element: <Outlet />,
        children: [
          {
            path: urlPageDynamicNews(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.LIST ]}>
                <PageDynamicNews />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDynamicNewsCreate(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.CREATE ]}>
                <PageDynamicNewsCreate />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDynamicNewsDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.FIND ]}>
                <PageDynamicNewsDetails />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDynamicNewsEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.EDIT ]}>
                <PageDynamicNewsEdit />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageDynamicNewsPriority(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.PRIORITY ]}>
                <PageDynamicNewsPriority />
              </PrivateRoute>
            ),
          },
        ],
      },
      ...FEATURE_SHOW_OLD_NEWS ? [ {
        path: urlPageNews(),
        element: <Outlet />,
        children: [
          {
            path: urlPageNews(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.LIST ]}>
                <PageNews />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageNewsPriority(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONTENT.NEWS.PRIORITY ]}>
                <PageNewsPriority />
              </PrivateRoute>
            ),
          },
        ],
      } ] : [],

      ...Object.values(FEEDS_SOURCE_MAP).map((feedSource, index) => {
        const sourceKey = Object.keys(FEEDS_SOURCE_MAP)[index];

        return {
          path: urlPageFeeds({ feedSource }),
          element: <Outlet />,
          children: [
            {
              path: urlPageFeeds({ feedSource }),
              element: (
                <PrivateRoute allowedPermissions={[ Permissions.CONTENT.FEEDS[sourceKey] ]}>
                  <PageFeeds />
                </PrivateRoute>
              ),
            },
          ],
        };
      }),

      ...Object.values(FEATURES_SOURCE_MAP).map((featureSource, index) => {
        const sourceKey = Object.keys(FEATURES_SOURCE_MAP)[index];

        return {
          path: urlPageFeatures({ featureSource }),
          element: <Outlet />,
          children: [
            {
              path: urlPageFeatures({ featureSource }),
              element: (
                <PrivateRoute allowedPermissions={[ Permissions.FEATURED.FEATURES[sourceKey].LIST ]}>
                  <PageFeatures />
                </PrivateRoute>
              ),
            },
            {
              path: urlPageFeatureCreate({ featureSource }),
              element: (
                <PrivateRoute allowedPermissions={[ Permissions.FEATURED.FEATURES[sourceKey].CREATE ]}>
                  <PageFeatureCreate />
                </PrivateRoute>
              ),
            },
            {
              path: urlPageFeatureDetails({ featureSource }),
              element: (
                <PrivateRoute allowedPermissions={[ Permissions.FEATURED.FEATURES[sourceKey].FIND ]}>
                  <PageFeatureDetails />
                </PrivateRoute>
              ),
            },
            {
              path: urlPageFeaturesEdit({ featureSource }),
              element: (
                <PrivateRoute allowedPermissions={[ Permissions.FEATURED.FEATURES[sourceKey].EDIT ]}>
                  <PageFeaturesEdit />
                </PrivateRoute>
              ),
            },
          ],
        };
      }),
      {
        path: urlPageFeaturePriorities(),
        element: (
          <PrivateRoute allowedPermissions={[ Permissions.FEATURED.FEATURES.PRIORITY.LIST ]}>
            <PageFeaturesPriority />
          </PrivateRoute>
        ),
      },
      {
        path: urlPagePresets(),
        element: <Outlet />,
        children: [
          {
            path: urlPagePresets(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.LIST ]}>
                <PagePresets />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCreatePreset(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.CREATE ]}>
                <PagePresetsCreate />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePresetEdit(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.EDIT ]}>
                <PagePresetsEdit />
              </PrivateRoute>
            ),
          },
          {
            path: urlPagePresetDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.FIND ]}>
                <PagePresetDetails />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: urlPageTemplates(),
        element: <Outlet />,
        children: [
          {
            path: urlPageTemplates(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.LIST ]}>
                <PageTemplates />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageCreateTemplate(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.CREATE ]}>
                <PageCreateEditTemplate />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageTemplateDetails(),
            element: (
              <PrivateRoute allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.FIND ]}>
                <PageTemplateDetails />
              </PrivateRoute>
            ),
          },
          {
            path: urlPageEditTemplate(),
            element: (
              <PrivateRoute
                allowedPermissions={[
                  Permissions.CONSTRUCTORS.TEMPLATES.FIND,
                  Permissions.CONSTRUCTORS.TEMPLATES.EDIT,
                ]}
              >
                <PageCreateEditTemplate />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  { path: '*', element: <Error404 /> },
];

export default routes;
