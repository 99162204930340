const templates = {
  'templates-breadcrumbs': 'Templates',
  'template-create-breadcrumbs': 'Constructor',
  'templates-filter-title': 'Filter templates',
  'templates-filter-form-id-placeholder': 'Template Id(s)',
  'templates-filter-form-title-placeholder': 'Template title',
  'templates-clone-successfully': 'Template cloned',
  'templates-clone-failed': 'Template failed',
  'templates-create-form-title-label': 'Template title',
  'templates-create-form-content-type-label': 'Template content type',
  'templates-create-form-content-type-placeholder': 'Enter template content type',
  'templates-create-form-app-key-label': 'Template app key',
  'templates-create-form-title-placeholder': 'Template title',
  'templates-create-form-app-key-placeholder': 'Enter app key',
  'templates-create-form-app-key-help': 'Slug - unique identifier, human readable string that contains symbols "-", "0-9", "A-Za-z"',
  'templates-create-form-priority-help': 'Set desired order of the nodes',
  'templates-create-form-docs': 'Create structure of the template',
  'templates-create-form-add-structure-root': 'Create the root node of structure',
  'templates-create-form-add-structure-node': 'Create node',
  'templates-create-form-field-key': 'App key',
  'templates-create-form-field-key-placeholder': 'Enter node key',
  'templates-create-form-field-title-ru': 'Title (ru)',
  'templates-create-form-field-title-en': 'Title (en)',
  'templates-create-form-field-hint': 'Hint',
  'templates-create-form-field-hint-ru': 'Hint (ru)',
  'templates-create-form-field-hint-en': 'Hint (en)',
  'templates-create-form-field-hint-placeholder': 'Describe necessity of the node',
  'templates-create-form-field-is-required': 'Is required',
  'templates-create-form-field-use-in-preset': 'Use in preset',
  'templates-create-form-field-use-in-preset-tooltip': 'If not active, the field will be skipped in preset',
  'templates-create-form-field-is-main': 'Show in main details',
  'templates-create-form-field-not-main': 'Show in additional details',
  'templates-create-form-field-dictionary-structure': 'Dictionary structure',
  'templates-create-form-field-dictionary-placeholder': 'Choose dictionary structure',
  'templates-create-form-field-select': 'List of options',
  'templates-create-form-field-select-placeholder': 'Choose option',
  'templates-create-form-field-subtype': 'Items type',
  'templates-create-form-field-subtype-placeholder': 'Choose items type',
  'templates-create-form-field-validation': 'Validation rules',
  'templates-create-form-field-validation-option': 'Validation rule',
  'templates-create-form-field-validation-options': 'Rules: ',
  'templates-create-form-field-validation-placeholder': 'Enter validation rules',
  'templates-create-form-field-default-value': 'Default value ',
  'templates-create-form-field-default-value-placeholder': 'Enter default value',
  'templates-create-form-field-value-boolean': 'boolean',
  'templates-create-form-field-value-boolean-label': 'Boolean',
  'templates-create-form-field-value-integer': 'integer',
  'templates-create-form-field-value-integer-label': 'Integer',
  'templates-create-form-field-value-double': 'double',
  'templates-create-form-field-value-double-label': 'Double',
  'templates-create-form-field-value-string': 'string',
  'templates-create-form-field-value-string-label': 'String',
  'templates-create-form-field-value-array': 'array',
  'templates-create-form-field-value-array-label': 'Array',
  'templates-create-form-field-value-image': 'image',
  'templates-create-form-field-value-image-label': 'Image',
  'templates-create-form-field-value-object': 'object',
  'templates-create-form-field-value-object-label': 'Object',
  'templates-create-form-field-value-dictionary': 'dictionary structure',
  'templates-create-form-field-value-dictionary-label': 'Dictionary structure',
  'templates-create-form-field-value-color': 'color',
  'templates-create-form-field-value-color-label': 'Color',
  'templates-create-form-field-value-date': 'date',
  'templates-create-form-field-value-date-label': 'Date',
  'templates-create-form-field-value-select': 'select',
  'templates-create-form-field-value-select-label': 'Select',
  'templates-create-form-field-hint-help': 'This hint will help creator to write appropriate content in that field',
  'templates-create-form-field-key-help': 'Key value should be unique within current parent node',
  'templates-create-form-delete-node-confirm': 'Removing this node you will lose all nested nodes. Are you sure?',
  'templates-create-form-field-default-value-help': 'Value set to null by default. Touch it to set non-zero default value',
  'templates-create-form-add-target': 'Target',
  'templates-create-form-targets-min-version-placeholder': 'Minimum app version',
  'templates-create-form-targets-max-version-placeholder': 'Maximum app version',
  'templates-create-form-targets-countries-label-help': 'Target will be applied to all countries by default',
  'templates-collapse-additional-title': 'Additional data',
  'templates-form-field-empty-dictionaries-list': 'There are no available dictionaries or current dictionaries have "Draft" status. Please create one and go back.',
};

export default templates;
