import Yup from '../vendor/yup';



export const targetValidation = Yup.object().shape({
  min_version: Yup.string().nullable().matches(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.?(0|[1-9]\d*)?$/, 'Min version has incorrect format. Try semantic version like 1.0 or 1.0.0'),
  max_version: Yup.string().nullable().matches(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.?(0|[1-9]\d*)?$/, 'Max version has incorrect format. Try semantic version like 1.0 or 1.0.0'),
  platform: Yup.array().of(Yup.string()).min(1, 'Enter at least 1 target platform').required('Target platform is required'),
  countries: Yup.array().of(Yup.string()).min(1, 'Enter at least 1 target country').required('Target countries is required'),
});
