import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdOpenInNew } from 'react-icons/md';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const ImageFieldView = ({ src = null }) => {
  if (!src) {
    return <span>{EMPTY_VALUE_PLACEHOLDER}</span>;
  }

  return (
    <Link to={src} target="_blank">
      <img height={45} src={src} alt="preview" />
      <MdOpenInNew className='hp-ml-8' size={16} />
    </Link>
  );
};

ImageFieldView.propTypes = {
  src: PropTypes.string,
};

export default ImageFieldView;
