import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DATE_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../const/system';



dayjs.extend(utc);


const getFormattedDate = (date, format = DATE_FORMAT, isLocalTime = false) => {
  if (!date) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  return dayjs(date).utc(isLocalTime).format(format);
};

export default getFormattedDate;
