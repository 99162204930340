import React, { useEffect } from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Checkbox, Col, Row, Button } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { useDictionaries, useDictionariesDispatch } from '../../providers/DictionariesProvider';
import urlPageTranslationDetails from '../../../../urls/urlPageTranslationDetails';



const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Min 4 symbols required')
    .max(50, 'Max 50 symbols required')
    .required('Name field is required'),
  structure_id: Yup.string()
    .required('Structure field is required'),
});

const FormEditDictionary = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
}) => {
  const intl = useIntl();
  const { newStructure } = useDictionaries();
  const dispatch = useDictionariesDispatch();
  const navigate = useNavigate();

  if (!dispatch) {
    throw new Error('DictionariesDispatchContext is required');
  }

  useEffect(() => {
    if (initialValues.structure_id) {
      dispatch({
        type: 'SET_SOURCE',
        source: initialValues.structure_id,
      });
      dispatch({
        type: 'SET_SELECTED_STRUCTURE',
        selectedStructure: initialValues.structure.data,
      });
    }
  }, [ initialValues ]);

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit({
          name: values.name.trim(),
          upgrade_version: values.upgrade_version,
        });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty, setFieldValue } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label={<IntlMessages id="ui-general-name" />}
              required
            >
              <Input
                name='name'
                placeholder={intl.formatMessage({ id: 'ui-general-name' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="code"
              label={<IntlMessages id="ui-dictionaries-source-field" />}
            >
              <Input
                disabled
                name='code'
                value={initialValues?.structure?.data?.source
                  ? intl.formatMessage({ id: `structures-source-${initialValues?.structure?.data?.source}` })
                  : null}
                placeholder={intl.formatMessage({ id: 'form-dictionaries-source-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="structure_id"
              label={<IntlMessages id="form-dictionaries-structure-field" />}
            >
              <Input
                disabled
                name='structure_id'
                value={initialValues?.structure?.data?.version && `v${initialValues?.structure?.data?.version}`}
                placeholder={intl.formatMessage({ id: 'form-dictionaries-source-placeholder' })}
              />
            </FormItem>

            {initialValues.is_upgradable && (
              <FormItem
                className='ant-form-item-col'
                name="upgrade_version"
                label={
                  <>
                    <IntlMessages id="form-dictionaries-upgrade-field" />
                    {newStructure.version && `(v${newStructure.version})`}
                  </>
                }
              >
                <Checkbox
                  onChange={async ({ target: { checked } }) => {
                    await setFieldValue('upgrade_version', checked);
                    dispatch({
                      type: 'UPGRADE_VERSION',
                      upgradeVersion: checked,
                    });
                  }}
                />
              </FormItem>
            )}

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Button
                  onClick={() => navigate(
                    urlPageTranslationDetails({ id: initialValues.id }),
                    { state: { key: 'prev_dictionary_edit' }, replace: true },
                  )}
                >
                  <IntlMessages id='ui-general-back' />
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditDictionary.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    id: PropTypes.number,
    is_upgradable: PropTypes.bool,
    name: PropTypes.string,
    status: PropTypes.string,
    structure_id: PropTypes.number,
    structure: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
        languages_ids: PropTypes.array,
        source: PropTypes.string,
        version: PropTypes.string,
        fields: PropTypes.object,
      }),
    }),
  }).isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormEditDictionary.defaultProps = {
  formErrors: {},
};

export default FormEditDictionary;
