import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteTranslationField from '../AutocompleteTranslationField';
import TranslationField from '../TranslationField';
import TranslationTextArea from '../TranslationTextArea';
import useToggle from '../../../../app/hooks/useToggle';



const TranslationCell = ({
  language,
  field,
  min,
  max,
  dictionaryDetails,
  availableLanguages,
  handleChange,
  handleSelect,
}) => {
  const [ isExpand, toggleExpand ] = useToggle();

  const renderTranslationTextArea = () => (
    <TranslationTextArea
      field={field}
      min={min}
      max={max}
      handleUpdateValue={handleChange}
      handleExpand={toggleExpand}
    />
  );

  const renderAutocompleteTranslationField = () => (
    <AutocompleteTranslationField
      field={field}
      min={min}
      max={max}
      availableLanguages={availableLanguages}
      handleUpdateValue={handleChange}
      dictionaryDetails={dictionaryDetails}
      handleExpand={toggleExpand}
      handleSelect={handleSelect}
    />
  );

  const renderTranslationField = () => (
    <TranslationField
      field={field}
      min={min}
      max={max}
      handleUpdateValue={handleChange}
      handleExpand={toggleExpand}
    />
  );

  return (
    isExpand
      ? renderTranslationTextArea()
      : language.code === 'ENG'
        ? renderAutocompleteTranslationField()
        : renderTranslationField()
  );
};


TranslationCell.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.any,
    language_id: PropTypes.number,
    structure_field_id: PropTypes.number,
    dictionary_id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  language: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  progress: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  dictionaryDetails: PropTypes.object,
  availableLanguages: PropTypes.array,
};

TranslationCell.defaultProps = {
  progress: true,
  min: 0,
  max: 0,
};

export default TranslationCell;
