import getLabelFromUnderscoreString from '../../domains/Content/utils/getLabelFromUnderscoreString';



const useImageTypeOptions = (imageTypes = []) => imageTypes.map((item) => {
  return {
    label: getLabelFromUnderscoreString(item),
    value: item,
  };
});


export default useImageTypeOptions;
