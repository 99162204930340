import React, { useState } from 'react';
import { Col, Row, Card, Table, Input, Space, Typography, Modal } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import basePagination from '../../../../../const/pagination';
import { Permissions } from '../../../../../const/permissions';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { COLLECTIONS_SOURCE, getCollectionsTableColumns } from './PageCollections.const';
import urlPageCollectionCreate from '../../../../../urls/urlPageCollectionCreate';
import urlPageCollectionEdit from '../../../../../urls/urlPageCollectionEdit';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import useElementSize from '../../../../../app/hooks/useElementSize';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import useCategoriesOptions from '../../../../../app/hooks/useCategoriesOptions';
import useImageTypeOptions from '../../../../../app/hooks/useImageTypeOptions';
import handleResponse from '../../../../../lib/handleResponse';
import getConfigurations from '../../../../../lib/getConfigurations';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import DocumentationAlert from '../../../../../components/DocumentationAlert/DocumentationAlert';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import FormCollectionsFilter from '../../../forms/FormCollectionsFilter';
import PreviewDictionaryTranslation from '../../../../Translations/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import {
  useDeleteCollectionMutation,
  useGetCollectionsQuery,
} from '../../../api/collectionsApiSlice';



const { Title } = Typography;

const PageCollections = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ openDictionaryModal, toggleDictionaryModal ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ previewDictionaryId, setPreviewDictionaryId ] = useState({});

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'template', 'categories', 'released_at', 'released_to', 'is_published', 'image_type' ];

  const source = COLLECTIONS_SOURCE;
  const collectionsConfig = getConfigurations(source);

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useQueryParams({ searchFields, searchFilterFields });

  const {
    data = { data: [], pagination: {}, categories: [], image_types: [] },
    isFetching,
    error: formErrors = {},
  } = useGetCollectionsQuery({ queryParams: searchParams.toString() });

  const categoriesOptions = useCategoriesOptions(data.categories);
  const imageTypeOptions = useImageTypeOptions(data.image_types);

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });
  const [ deleteCollection, { isLoading: isDeletingCollection } ] = useDeleteCollectionMutation();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setResetManuallyTriggered(false);
  };

  const handlePreviewDictionary = (dictionaryId) => {
    setPreviewDictionaryId(dictionaryId);
    toggleDictionaryModal();
  };

  const handleDelete = (collectionId) => {
    deleteCollection(collectionId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleEdit = (collectionId) => {
    navigate(urlPageCollectionEdit({ collectionId }));
  };

  const isLoading = isFetching || isDeletingCollection;

  return (
    <>
      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openDictionaryModal}
        onCancel={toggleDictionaryModal}
        footer={null}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewDictionaryTranslation
          dictionaryId={previewDictionaryId}
          languages={languages?.languages}
        />
      </Modal>

      <DocumentationAlert link='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/951844867/Collections' />
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='collections-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.CREATE ]}>
                  <ActionButton
                    onClick={() => navigate(urlPageCollectionCreate())}
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                    title={<IntlMessages id='ui-general-create' />}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={source}
          title={<Title level={5}>{intl.formatMessage({ id: 'collections-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormCollectionsFilter
            initialValues={initFilterValues}
            formErrors={formErrors}
            isSubmitting={isFetching}
            imageTypeOptions={imageTypeOptions}
            categoriesOptions={categoriesOptions}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                setSearchTerm,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isLoading)}
                rowKey="id"
                columns={
                  getCollectionsTableColumns(
                    handlePreviewDictionary,
                    handleEdit,
                    handleDelete,
                    collectionsConfig,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageCollections;
