import React from 'react';
import { Col } from 'antd';



const HeaderText = () => {
  return (
    <Col xl={16} lg={14} className="hp-header-left-text hp-d-flex-center" />
  );
};

export default HeaderText;
