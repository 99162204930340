import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../domains/Auth/features/authSlice';



export const AppContext = React.createContext({});


const AppContextProvider = ({ children }) => {
  const user = useSelector(selectUserData);

  const memoizedValue = useMemo(() => ({
    userId: user?.user?.id,
    userPermissions: user?.permissions ?? [],
    userRoles: user?.roles ?? [],
  }), [ user ]);

  return (
    <AppContext.Provider value={memoizedValue}>
      {children}
    </AppContext.Provider>
  );
};


AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useApp = () => useContext(AppContext);

export default AppContextProvider;
