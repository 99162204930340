import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';



const Footer = ({ hasPrivacy = false, hasTerms = false }) => {
  const showFooterLinks = hasPrivacy || hasTerms;

  return (
    <Col className="hp-my-48 hp-px-24">
      <p className="hp-p1-body hp-text-center hp-text-color-black-60 hp-mb-8">
        Copyright ©
        {new Date().getFullYear()}
        X-Flow, All rights Reserved.
      </p>
      {showFooterLinks && (
        <Row align="middle" justify="center" gutter={[ 16, 16 ]}>
          {hasPrivacy && (
            <Col>
              <a href="#123" className="hp-text-color-black-80 hp-text-color-dark-40">
                Privacy Policy
              </a>
            </Col>
          )}

          {hasTerms && (
            <Col>
              <a href="#123" className="hp-text-color-black-80 hp-text-color-dark-40">
                Term of use
              </a>
            </Col>
          )}
        </Row>
      )}
    </Col>
  );
};

Footer.propTypes = {
  hasPrivacy: PropTypes.bool,
  hasTerms: PropTypes.bool,
};

export default Footer;
