import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import urlPageCollections from '../../../../../urls/urlPageCollections';
import { Permissions } from '../../../../../const/permissions';
import { DATE_FORMAT } from '../../../../../const/system';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import applyFilter from '../../../../../lib/applyFilter';
import useCheckMobileScreen from '../../../../../app/hooks/useCheckMobileScreen';
import handleResponse from '../../../../../lib/handleResponse';
import getPriorityUpdateButtonTooltipMessage from '../../../../../lib/getPriorityUpdateButtonTooltipMessage';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import PrioritiesTitle from '../../../../../components/PrioritiesTitle';
import PriorityFilter from '../../../../../components/PriorityFilter';
import CollectionPriorityList from '../../../components/CollectionPriorityList';
import {
  useGetCollectionsPriorityQuery,
  useUpdateCollectionsPriorityMutation,
} from '../../../api/collectionsApiSlice';



const PageCollectionPriority = () => {
  const intl = useIntl();
  const [ collectionsOrder, setCollectionsOrder ] = useState([]);
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const isMobile = useCheckMobileScreen();

  const searchFilterFields = [ 'date' ];

  const { data: collections = { data: [], pagination: {} }, isFetching } = useGetCollectionsPriorityQuery({
    queryParams: searchQueryParams.get('search') ? `search=${searchQueryParams.get('search')}` : '',
  });

  const [ updateCollectionsPriority, { isLoading: isUpdatingCollectionsPriority } ] = useUpdateCollectionsPriorityMutation();

  const today = dayjs().format(DATE_FORMAT);
  const search = searchQueryParams.get('search');
  const date = search ? search.split(':')[1] : today;
  const hasNoOrderChanges = !collectionsOrder.length;
  const isPrevPriority = dayjs(date).isBefore(dayjs().subtract(2, 'day'));


  const onCollectionsOrderChange = (order) => {
    setCollectionsOrder(order);
  };

  const submitCollectionsPriority = () => {
    const order = collectionsOrder.map((item) => {
      const position = Number(item) - 1;

      return collections.data[position]?.id;
    });

    updateCollectionsPriority({ order, date })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully', [ () => setCollectionsOrder([]) ]))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCollections()}>
              <IntlMessages id='collections-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-priority' />}
        />
      </Row>

      <Row gutter={[ 16, 16 ]} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
        <Col sm={24} md={16}>
          <Card
            className="hp-border-color-black-40 hp-card-6"
            title={(
              <PrioritiesTitle
                isDisabled={hasNoOrderChanges}
                hasPermission={HasRights([ Permissions.CONTENT.COLLECTIONS.PRIORITIES.EDIT ])}
                title={intl.formatMessage({ id: 'collections-priority-title' })}
                onAction={submitCollectionsPriority}
                tooltipMessage={getPriorityUpdateButtonTooltipMessage(hasNoOrderChanges, isPrevPriority)}
              />
            )}
          >
            <Spinner spinning={isFetching || isUpdatingCollectionsPriority}>
              <CollectionPriorityList
                data={collections.data}
                isLoading={isFetching || isUpdatingCollectionsPriority}
                onOrderChange={onCollectionsOrderChange}
                selectedDate={date}
              />
            </Spinner>
          </Card>
        </Col>
        <Col sm={24} md={8}>
          <Card
            title={intl.formatMessage({ id: 'collections-priority-filter-title' })}
            className="hp-border-color-black-40 hp-card-6"
          >
            <PriorityFilter
              isSubmitting={isFetching || isUpdatingCollectionsPriority}
              initialValues={searchQueryParams.get('search') ? { date } : {}}
              onCancel={() => {}}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  callbacks: [ () => setCollectionsOrder([]) ],
                });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageCollectionPriority;
