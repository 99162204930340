import url from './url';



export default url(({ id = ':id', source = null, multipleSources = null }) => {
  if (source && multipleSources) {
    return `/dictionaries/${id}?source=${source}&multipleSources=${multipleSources}`;
  }

  return source
    ? `/dictionaries/${id}?source=${source}`
    : `/dictionaries/${id}`;
});
