const collections = {
  'collections-breadcrumbs': 'Collections',
  'collection-details-title': 'Collection',
  'collections-filter-title': 'Filter collections',
  'collection-create-sidebar': 'Create collection',
  'collection-table-content-tooltip-label': 'Click to view all attached images',
  'collection-form-categories-title': 'Categories',
  'collection-form-image-type-title': 'Image\'s type',
  'collection-form-specify-image-type': 'Specify image\'s type',
  'collection-no-attached-images': 'Images does not attached to current collection',
  'collection-attach-success': 'Image attached to collection',
  'collection-detach-success': 'Image detached from collection',
  'collection-attach-failed': 'Attach image to collection failed',
  'collection-attach-confirm-message': 'Are you sure you want to attach this image to collection?',
  'collection-detach-confirm-message': 'Are you sure you want to detach this image from collection?',
  'collection-edit-notification-note': 'Note: If you want to unpublish current collection, please contact the administrator.',
  'collection-publish-notification-note': 'Collection publish is not allowed in case when there is no any attached images.',
  'collections-priority-title': 'Set correct order for collections',
  'collections-priority-filter-title': 'Select collections by date',
  'collections-delete-notification': 'Unable to delete, recent data is already published or has attached images',
  'collection-autoupdate-image-release-dates-info': 'You have attached images with customized release dates. Image dates will be changed with new collection release range. Do you wish to proceed?',
};

export default collections;
