const modifyImageCategoriesValues = (values) => {
  const modifiedCategories = [];

  if (values.categories && values.categories.length > 0) {
    values.categories.forEach((categoryId) => {
      modifiedCategories.push({
        category_id: categoryId,
        is_main: false,
      });
    });
  }

  if (values.main_category) {
    const mainCategory = modifiedCategories?.find((category) => category.category_id === values.main_category);

    if (mainCategory) {
      mainCategory.is_main = true;
    } else {
      modifiedCategories.push({
        category_id: values.main_category,
        is_main: true,
      });
    }
  }

  return modifiedCategories;
};

export default modifyImageCategoriesValues;
