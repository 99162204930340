import React from 'react';
import { Col, Row, Tag, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { PiHandTap } from 'react-icons/pi';
import { HiMiniPaintBrush } from 'react-icons/hi2';
import PreviewInfo from '../PreviewInfo';
import PreviewImages from '../PreviewImages';
import mapImageTypeToColor from '../../../../lib/mapImageTypeToColor';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import Spinner from '../../../../components/Spinner';
import UpdateCollectionImageModalForm from '../../forms/UpdateCollectionImageModalForm';



const EditCollectionImageCard = ({
  imageInitialValues,
  collectionDetails,
  data,
  needToAttachImageWithUpdate,
  onModalClose = () => {},
  isLoading = false,
}) => {
  const intl = useIntl();
  const canViewBeepanel = HasRights([ Permissions.CONTENT.IMAGES.VIEW_BEEPANEL ]);

  let beeLink = <span>{data.source.data.source_item_id}</span>;

  if (canViewBeepanel) {
    beeLink = <a href={data.source.data.link} target="_blank" rel="noreferrer">{data.source.data.source_item_id}</a>;
  }

  return (
    <Spinner spinning={isLoading}>
      <Row gutter={[ 16, 16 ]} justify="space-around">
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-id' })}
            value={data.id}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-bee-id' })}
            value={beeLink}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-format' })}
            value={(
              <Tag style={{ marginRight: 0 }} color={mapImageTypeToColor(data.source.data.type_content)}>
                {data.source.data.type_content}
              </Tag>
            )}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'content-image-preview-taps-label' })}
            value={(
              <div className="hp-d-flex hp-align-items-center">
                <Tooltip placement="top" title={<IntlMessages id="content-image-taps" />}>
                  <span className="content-stat-info">
                    <PiHandTap />
                    {data.data?.data[0]?.taps}
                  </span>
                </Tooltip>
                /
                <Tooltip placement="top" title={<IntlMessages id="content-image-colors-amount" />}>
                  <span className="content-stat-info">
                    <HiMiniPaintBrush />
                    {data.data?.data[0]?.colors}
                  </span>
                </Tooltip>
              </div>
            )}
          />
        </Col>
        <Col>
          <PreviewInfo
            title={intl.formatMessage({ id: 'ui-general-created-at' })}
            value={data.created_at}
          />
        </Col>
      </Row>
      <Row gutter={[ 32, 32 ]} justify="center" className='hp-my-16 hp-border-top-1'>
        <PreviewImages
          icon={data.icon.data.path}
          preview={data.preview.data.path}
          mdSpan={8}
        />
        <Col xs={24} md={8}>
          <UpdateCollectionImageModalForm
            initialValues={imageInitialValues}
            imageId={data?.id}
            collectionDetails={collectionDetails}
            isImageAttaching={needToAttachImageWithUpdate}
            onModalClose={onModalClose}
          />
        </Col>
      </Row>
    </Spinner>
  );
};

EditCollectionImageCard.propTypes = {
  imageInitialValues: PropTypes.object,
  collectionDetails: PropTypes.object,
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    comment: PropTypes.string,
    labels: PropTypes.array,
    tags: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  onModalClose: PropTypes.func,
  isLoading: PropTypes.bool,
  needToAttachImageWithUpdate: PropTypes.bool,
};

export default EditCollectionImageCard;
