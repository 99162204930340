const deeplinks = {
  'deeplinks-breadcrumbs': 'Глубинные ссылки',
  'deeplinks-create-title': 'Создать глубинную ссылку',
  'deeplinks-table-short-link-column': 'Короткая ссылка',
  'deeplinks-table-deeplink-column': 'Глубинная ссылка',
  'deeplinks-table-open-times-column': 'Лимит открытия ссылки',
  'deeplinks-form-code-label': 'Кастомный код',
  'deeplinks-form-open-limit-label': 'Лимит открытия ссылки',
  'deeplinks-form-image-label': 'Картинка',
  'deeplinks-form-pack-label': 'Пак',
  'deeplinks-form-category-label': 'Категория',
  'deeplinks-form-theme-label': 'Тема',
  'deeplinks-form-bonus-code-label': 'Бонус код',
  'deeplinks-form-destination-label': 'Пункт назначения',
  'deeplinks-form-external-destination-label': 'Внеш. пункт назначения',
  'deeplinks-form-image-placeholder': 'Перенаправить к изображению',
  'deeplinks-form-pack-placeholder': 'Перенаправить в пак',
  'deeplinks-form-category-placeholder': 'Перенаправить в категорию',
  'deeplinks-form-theme-placeholder': 'Выберите графическую тему',
  'deeplinks-form-bonus-code-placeholder': 'Выберите бонусный код',
  'deeplinks-form-destination-placeholder': 'Перенаправить к настройкам',
  'deeplinks-form-external-destination-placeholder': 'Переход на внешнюю страницу (https://fb.com)',
  'deeplinks-form-custom-code-placeholder': 'Введите пользовательский код',
  'deeplinks-form-warning': 'Ссылка будет доступна через 5 минут',
  'deeplinks-popover-title': 'Создание глубинной ссылки',
  'deeplinks-popover-text-1': 'Чтобы создать глубинную ссылку, вам следует сначала ее настроить.',
  'deeplinks-popover-text-2': 'Пожалуйста, выберите все подходящие варианты в форме и нажмите кнопку "Сохранить".',
  'deeplinks-popover-description': 'Значения:',
  'deeplinks-popover-description-text': 'Вам предстоит выбрать необходимое значение в форме (картинку\\пак\\категорию) или вписать его руками для соответствующих полей',
  'deeplinks-popover-order': 'Порядок:',
  'deeplinks-popover-order-text': 'Вы также можете изменить порядок действий, которые будут применяться после перехода по ссылке. Просто перетащите нужный вариант вверх или вниз по списку.',
};

export default deeplinks;
