import React from 'react';
import { Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormItem, Input } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const TitleWithAutomaticGeneration = ({ rows = 1, useAutomaticGeneration = false }) => {
  const intl = useIntl();

  return (
    <FormItem
      name='title'
      label={
        <Row className='w-full' justify="space-between" align="middle">
          <IntlMessages id="ui-general-title" />
          <Tooltip
            placement="top"
            title={<IntlMessages
              id={useAutomaticGeneration
                ? 'features-title-use-automatic-generation-info'
                : 'features-title-info'}
            />}
          >
            <InfoCircleOutlined className='hp-ml-8' />
          </Tooltip>
        </Row>
      }
    >
      <Input.TextArea
        disabled={useAutomaticGeneration}
        name="title"
        placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
        rows={rows}
        showCount
        maxLength={2000}
      />
    </FormItem>
  );
};

TitleWithAutomaticGeneration.propTypes = {
  rows: PropTypes.number,
  useAutomaticGeneration: PropTypes.bool,
};

export default TitleWithAutomaticGeneration;
