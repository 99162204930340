const deeplinks = {
  'deeplinks-breadcrumbs': 'Deeplinks',
  'deeplinks-create-title': 'Create deeplink',
  'deeplinks-table-short-link-column': 'Short link',
  'deeplinks-table-deeplink-column': 'Deeplink',
  'deeplinks-table-open-times-column': 'Link open limit',
  'deeplinks-form-code-label': 'Custom code',
  'deeplinks-form-open-limit-label': 'Link open limit',
  'deeplinks-form-image-label': 'Image',
  'deeplinks-form-pack-label': 'Pack',
  'deeplinks-form-category-label': 'Category',
  'deeplinks-form-theme-label': 'Theme',
  'deeplinks-form-bonus-code-label': 'Bonus code',
  'deeplinks-form-destination-label': 'Destination',
  'deeplinks-form-external-destination-label': 'External destination',
  'deeplinks-form-image-placeholder': 'Direct to the Image',
  'deeplinks-form-pack-placeholder': 'Direct to the Pack',
  'deeplinks-form-category-placeholder': 'Direct to the Category',
  'deeplinks-form-theme-placeholder': 'Choose a graphic theme',
  'deeplinks-form-bonus-code-placeholder': 'Choose Bonus code',
  'deeplinks-form-destination-placeholder': 'Direct to the Settings',
  'deeplinks-form-external-destination-placeholder': 'Direct to the external page. Ex: https://fb.com',
  'deeplinks-form-custom-code-placeholder': 'Enter custom code',
  'deeplinks-form-warning': 'You can access the link in 5 minutes',
  'deeplinks-popover-title': 'Creating deeplink',
  'deeplinks-popover-text-1': 'To create a deep link, you should set it up first.',
  'deeplinks-popover-text-2': 'Please choose all applicable options and tap the button on the left-hand side.',
  'deeplinks-popover-description': 'Description:',
  'deeplinks-popover-description-text': 'Please select the appropriate description (e.g picture/pack/category) or type it in manually, if you wish to add an extra tag',
  'deeplinks-popover-order': 'Order:',
  'deeplinks-popover-order-text': 'You can also rearrange the order of actions that will be applied after following the link. Simply drag the desired option up or down the list.',
};

export default deeplinks;
