const createFileFromURL = async (url, filename) => {
  try {
    const response = await fetch(url, {
      cache: 'no-cache',
    });

    const data = await response.blob();

    return new File([ data ], filename, {
      type: data.type,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export default createFileFromURL;
