import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FullscreenExitOutlined } from '@ant-design/icons';
import FieldProgress from '../FieldProgress';
import appColors from '../../../../const/colors';



const TranslationTextArea = ({ field, handleExpand, handleUpdateValue, progress, min, max }) => {
  const key = `${field.language_id}:${field.structure_field_id}`;

  return (
    <>
      <div className='hp-position-relative'>
        <Input.TextArea
          value={field.text}
          key={key}
          onChange={(event) => handleUpdateValue(event.target.value, field)}
        />
        <span className="ant-input-suffix hp-position-absolute-top-right hp-mt-4 hp-mr-4">
          <FullscreenExitOutlined onClick={() => handleExpand(key, field)} style={{ color: appColors.darkGray }} />
        </span>
      </div>
      {progress && (min || max) ? (
        <FieldProgress text={field.text} min={min} max={max} />
      ) : ''}
    </>
  );
};


TranslationTextArea.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.any,
    language_id: PropTypes.number,
    structure_field_id: PropTypes.number,
    dictionary_id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  progress: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  handleExpand: PropTypes.func,
  handleUpdateValue: PropTypes.func,
};

TranslationTextArea.defaultProps = {
  progress: true,
  min: 0,
  max: 0,
};

export default TranslationTextArea;
