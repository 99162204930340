import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';



const useModifiedDynamicDataErrors = (currentTemplateId, template, initialErrors, language) => {
  return useMemo(() => {
    if (!currentTemplateId || isEmpty(template) || !initialErrors?.data) {
      return initialErrors;
    }

    const modifiedErrors = { ...initialErrors };

    template?.fields?.data
      .forEach((field) => {
        if (modifiedErrors.data[field[`display_name_${language}`]]?.value) {
          const regex = /(The\s)(.*?)(\sfield)/;
          const replacement = field.title_en;

          modifiedErrors.data[field[`display_name_${language}`]].value = modifiedErrors
            .data[field[`display_name_${language}`]]
            .value
            .replace(regex, `$1${replacement}$3`);
        }
      });

    return modifiedErrors;
  }, [ currentTemplateId, template, initialErrors, language ]);
};

export default useModifiedDynamicDataErrors;
