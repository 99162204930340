const profile = {
  'profile-change-user-avatar': 'Change Avatar',
  'profile-title': 'Profile',
  'profile-personal-information-page': 'Personal Information',
  'personal-information-last-login-field': 'Last login',
  'form-upload-user-avatar-placeholder': 'Upload Avatar',
  'form-upload-user-avatar-label': 'Avatar',
  'form-upload-user-avatar-description': 'Click or drag file to this area to upload',
  'form-upload-user-avatar-edit-title': 'Edit image',
};

export default profile;
