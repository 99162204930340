const prepareFormData = (values, skippedKeys = []) => {
  const formData = new FormData();

  const isNotSkipped = (key) => !skippedKeys.includes(key);
  const isArrayOfFiles = (value) => {
    return Array.isArray(value) && value.every((item) => {
      return 'originFileObj' in item;
    });
  };

  Object.entries(values)
    .forEach(([ key, value ]) => {
      if (isNotSkipped(key)) {
        if (isArrayOfFiles(value)) {
          value.forEach((file) => formData.append(`${key}[]`, file.originFileObj, file.name));
        } else if (Array.isArray(value)) {
          value.forEach((item) => formData.append(`${key}[]`, item));
        } else if (value === '') {
          formData.append(key, null);
        } else {
          formData.append(key, value);
        }
      }
    });

  return formData;
};

export default prepareFormData;
