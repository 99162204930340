import url from './url';



export default url(({ source = null, multipleSources = null }) => {
  if (source && multipleSources) {
    return `/dictionaries?search=source:${multipleSources}&source=${source}&multipleSources=${multipleSources}`;
  }

  return source
    ? `/dictionaries?search=source:${source}&source=${source}`
    : '/dictionaries';
});
