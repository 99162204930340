import React from 'react';
import { Drawer } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import MenuLogo from '../logo';
import MenuItem from '../item';



const MenuMobile = ({ onClose = () => {}, visible = false }) => {
  return (
    <Drawer
      title={
        <MenuLogo onClose={onClose} />
      }
      width={256}
      className="hp-mobile-sidebar hp-sidebar"
      placement="left"
      closable
      onClose={onClose}
      visible={visible}
      closeIcon={
        <RiCloseFill
          className="remix-icon hp-text-color-black-80"
          size={20}
        />
      }
    >
      <MenuItem onClose={onClose} />
    </Drawer>
  );
};


MenuMobile.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MenuMobile;
