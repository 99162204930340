const achievements = {
  'achievements-breadcrumbs': 'Achievements',
  'achievements-title': 'Achievements',
  'achievements-attach-success': 'Image attached to achievement',
  'achievements-attach-failed': 'Attach image to achievement failed',
  'achievements-attach-confirm-message': 'Are you sure you want to attach this image to achievement?',
  'achievements-detach-confirm-message': 'Are you sure you want to detach this image from achievement?',
  'achievements-create-sidebar': 'Create achievement',
  'achievements-filter-sidebar': 'Filter achievements',
  'achievements-attached-images': 'Attached images',
  'achievements-no-attached-images': 'Images does not attached to achievement',
};

export default achievements;
