import React from 'react';
import { Card, Col, Input, Row, Space, Table } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import CreateDeeplinkTitle from '../../../components/CreateDeeplinkTitle';
import handleResponse from '../../../../../lib/handleResponse';
import useToggle from '../../../../../app/hooks/useToggle';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import FormCreateDeeplink from '../../../forms/FormCreateDeeplink';
import { getDeeplinksTableColumns } from './PageDeeplinks.const';
import { useCreateDeeplinkMutation, useGetDeeplinksQuery } from '../../../api/deeplinksApiSlice';



const PageDeeplinks = () => {
  const intl = useIntl();
  const [ isVisibleCreateSidebar, toggleSidebar ]  = useToggle();
  const searchFields = [ 'deeplink' ];
  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields });

  const { data = { data: [], pagination: {} }, isFetching } = useGetDeeplinksQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const [ createDeeplink, { isLoading: isCreatingDeeplink } ] = useCreateDeeplinkMutation();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value?.replace('coloring://', ''));
  };

  const closeSidebar = () => {
    toggleSidebar();
  };

  const handleCreateDeeplink = (data) => {
    toggleSidebar();
    createDeeplink(data)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-created-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-create-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='deeplinks-breadcrumbs' />} />

        <Col md={12} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title={<IntlMessages id='ui-general-create' />}
                  icon={<RiAddLine className="btn-icon-mr-1" />}
                  onClick={toggleSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={<CreateDeeplinkTitle />}
          visible={isVisibleCreateSidebar}
          toggleSidebar={closeSidebar}
          width={600}
        >
          <FormCreateDeeplink
            isSubmitting={isCreatingDeeplink}
            onCancel={() => {}}
            onSubmit={handleCreateDeeplink}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isFetching || isCreatingDeeplink)}
              rowKey="id"
              columns={getDeeplinksTableColumns()}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDeeplinks;
