import React from 'react';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { Formik } from 'formik';
import { Alert, Col, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import Spinner from '../../../../components/Spinner';
import useDictionaryOptions from '../../../../app/hooks/useDictionaryOptions';
import { SOURCE_CATEGORIES } from '../../pages/Categories/PageCategories/PageCategories.const';



const { TextArea } = Input;

const validationSchema = Yup.object().shape({
  client_id: Yup.number().required('Category id is required'),
  name: Yup.string().max(255).required('Category name is required'),
  dictionary_id: Yup.number().required('Category dictionary is required'),
  status: Yup.bool().required('Category status is required').default(false),
  description: Yup.string().max(5000).nullable(),
  options: Yup.string().max(5000).nullable(),
});

const FormCreateEditCategories = ({
  initialValues,
  onSubmit,
  isSubmitting,
  initialErrors,
  handleResetErrors,
  currentDictionaryId,
}) => {
  const intl = useIntl();
  const [ dictionaryOptions = [], isDictionariesLoading, showWarning ] = useDictionaryOptions(
    SOURCE_CATEGORIES,
    currentDictionaryId,
    true,
  );

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm();
      }}
    >
      {({ isValid, dirty, handleReset, setErrors }) => {
        return (
          <Form layout="vertical" className='w-full'>
            <Spinner spinning={isSubmitting || isDictionariesLoading}>
              {showWarning && (
                <Alert
                  className='hp-mb-8'
                  message={intl.formatMessage({ id: 'templates-form-field-empty-dictionaries-list' })}
                  type="warning"
                  showIcon
                />
              )}
              <FormItem
                className='ant-form-item-col stretched'
                name="client_id"
                required
                label={
                  <Row className='w-full' justify="space-between" align="middle">
                    <IntlMessages id="ui-general-id" />
                    <Tooltip placement="topRight" title={<IntlMessages id="categories-create-form-field-client-id-help" />}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                }
              >
                <Input
                  type='number'
                  name='client_id'
                  placeholder={intl.formatMessage({ id: 'ui-general-id-placeholder' })}
                />
              </FormItem>

              <FormItem
                className='ant-form-item-col'
                name="name"
                label={intl.formatMessage({ id: 'ui-general-name' })}
                required
              >
                <Input
                  name='name'
                  placeholder={intl.formatMessage({ id: 'categories-create-form-field-name-placeholder' })}
                />
              </FormItem>

              <FormItem
                className='ant-form-item-col'
                name="dictionary_id"
                label={<IntlMessages id="ui-general-dictionary" />}
                required
              >
                <Select
                  name="dictionary_id"
                  options={dictionaryOptions}
                  loading={isDictionariesLoading}
                  placeholder={<IntlMessages id="categories-create-form-field-dictionary-placeholder" />}
                />
              </FormItem>

              <FormItem
                label={intl.formatMessage({ id: 'ui-general-status' })}
                name='status'
              >
                <Switch
                  name="status"
                  checkedChildren={intl.formatMessage({ id: 'ui-general-on' })}
                  unCheckedChildren={intl.formatMessage({ id: 'ui-general-off' })}
                />
              </FormItem>

              <FormItem
                label={intl.formatMessage({ id: 'ui-general-description' })}
                name='description'
              >
                <TextArea
                  rows={5}
                  placeholder={intl.formatMessage({ id: 'categories-create-form-field-description-placeholder' })}
                  name='description'
                />
              </FormItem>

              {HasRights([ Permissions.CONTENT.CATEGORIES.OPTIONS.EDIT ]) && (
                <FormItem
                  className='ant-form-item-col stretched'
                  name='options'
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="categories-create-form-field-options" />
                      <Tooltip placement="topRight" title={<IntlMessages id="categories-create-form-field-options-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <TextArea
                    rows={5}
                    placeholder={intl.formatMessage({ id: 'categories-create-form-field-options-placeholder' })}
                    name='options'
                  />
                </FormItem>
              )}

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton
                    loading={isSubmitting}
                    onClick={async () => {
                      await setErrors({});
                      handleReset();
                      handleResetErrors();
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditCategories.propTypes = {
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleResetErrors: PropTypes.func.isRequired,
  currentDictionaryId: PropTypes.number,
};

export default FormCreateEditCategories;
