import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Yup from '../../../../vendor/yup';
import { targetValidation } from '../../../../const/targetValidation';



export const mkValidationSchema = (
  popupConfig,
  dynamicData = {},
  currentReleasedAt = null,
  currentReleasedTo = null,
  allowUpdateInThePast = false,
) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string().nullable()
    .min(3, 'Min 3 symbols required')
    .max(2000, 'Max 2000 symbols required'),
  template_id: Yup.number().required('Required to specify template ID'),
  is_published: Yup.bool().nullable(),
  released_at: Yup.date()
    .when('is_published', {
      is: false,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required('Required to specify released at date'),
    })
    .test(
      'test_day_in_the_past',
      'Released at date must be same or after than yesterday',
      (value) => {
        if (allowUpdateInThePast && dayjs(currentReleasedAt).isSame(value)) {
          return true;
        }

        return !dayjs(value).isBefore(
          dayjs().subtract(1, 'days').startOf('day').utc(false),
        );
      },
    )
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return dayjs(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ),
  ...popupConfig?.release_range && {
    released_to: Yup.string().nullable()
      .test(
        'test_day_in_the_past',
        'Released to date must be same or after than yesterday',
        (value) => {
          if (allowUpdateInThePast && dayjs(currentReleasedTo).isSame(value)) {
            return true;
          }

          return !dayjs(value).isBefore(dayjs()
            .subtract(1, 'days').startOf('day')
            .utc(false));
        },
      )
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return dayjs(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  target: targetValidation,
  ...dynamicData,
}));
