const dynamicNews = {
  'dynamic-news-breadcrumbs': 'News',
  'dynamic-news-preview-breadcrumbs': 'News details',
  'dynamic-news-create-breadcrumbs': 'Create news',
  'dynamic-news-update-breadcrumbs': 'Update news',
  'dynamic-news-filter-title': 'Filter news',
  'dynamic-news-form-id-placeholder': 'News ID',
  'dynamic-news-form-title-placeholder': 'News title',
  'dynamic-news-form-template-key-value-label': 'Key value',
  'dynamic-news-form-template-key-value-placeholder': 'Value',
  'dynamic-news-preview-targets-label': 'Targets',
  'dynamic-news-create-form-title-placeholder': 'News title',
  'dynamic-news-create-form-status-placeholder': 'News status',
  'dynamic-news-create-form-targets-min-version-placeholder': 'Minimum app version',
  'dynamic-news-create-form-targets-max-version-placeholder': 'Maximum app version',
  'dynamic-news-create-form-upload-description-label': 'Click or drag file to this area for uploading',
  'dynamic-news-template-validation-error': 'Template field has error in a validation rules.',
  'dynamic-news-delete-notification': 'Unable to delete, current data is already published',
  'dynamic-news-fields-priority': 'Fields priority',
  'dynamic-news-form-main-attribute': 'Main attribute',
};

export default dynamicNews;
