const achievementSettings = {
  previewItems: 5,
};

const bonusesSettings = {
  previewItems: 5,
};

export {
  achievementSettings,
  bonusesSettings,
};
