import isString from 'lodash/isString';
import map from 'lodash/map';
import trim from 'lodash/trim';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../const/system';



const parseDate = (value) => {
  const dateRegexPattern = /^\d{4}-\d{2}-\d{2}$/;

  if (dateRegexPattern.test(value)) {
    const parsedDate = dayjs(value, DATE_FORMAT, true);

    return parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : null;
  }
  return null;
};

const parseNumber = (value) => {
  const trimmedValue = value ? value.trim() : null;

  return !isNaN(trimmedValue) && trimmedValue !== '' ? parseFloat(trimmedValue) : null;
};

const parseBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return null;
};

const parseArrayFromString = (value) => {
  return isString(value) && value.includes(',') ? map(value.split(','), trim) : null;
};

const convertStringsToNumbersInArray = (arr) => {
  return arr.map((item) => !isNaN(item) && item.trim() !== '' ? parseFloat(item) : item);
};

const parseQueryParam = (value) => {
  const parsers = [
    parseBoolean,
    parseArrayFromString,
    parseDate,
    parseNumber,
  ];

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const parser of parsers) {
    const parsedValue = parser(value);

    if (parsedValue !== null) {
      return parser === parseArrayFromString ? convertStringsToNumbersInArray(parsedValue) : parsedValue;
    }
  }
  return value;
};

export default parseQueryParam;
