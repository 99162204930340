import url from './url';



export default url(({ source = null, multipleSources = null }) => {
  if (source && multipleSources) {
    return `/dictionaries/import/success?source=${source}&multipleSources=${multipleSources}`;
  }

  return source
    ? `/dictionaries/import/success?source=${source}`
    : '/dictionaries/import/success';
});
