import { Select } from 'formik-antd';
import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import { countries } from '../../const/countries';
import makeSelectFilterOption from '../../lib/makeSelectFilterOption';



const CountrySelector = ({ name = null, placeholder = null, onSelectAllDisabled = false, onSelectAll = () => {}, disabled = false, isView = false, setFieldValue = () => {} }) => {
  return (
    <div className="hp-d-flex">
      <Select
        disabled={disabled}
        className="single-line-select"
        mode="multiple"
        name={name}
        options={countries}
        placeholder={placeholder}
        allowClear
        showSearch
        onChange={async (value) => {
          await setFieldValue(value.sort());
        }}
        filterOption={(input, option) => makeSelectFilterOption(option?.label ?? '', input)}
      />
      {!isView && (
        <Button
          disabled={onSelectAllDisabled}
          style={{ minWidth: '135px' }}
          className="hp-ml-8"
          type="outline"
          onClick={onSelectAll}
        >
          <IntlMessages id="ui-general-select-all" />
        </Button>
      )}
    </div>
  );
};

CountrySelector.propTypes = {
  name: PropTypes.string.isRequired,
  onSelectAllDisabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.any.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isView: PropTypes.bool,
};

export default CountrySelector;
