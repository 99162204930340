import { createSlice } from '@reduxjs/toolkit';
import makePreparedAuthData from '../../../lib/makePreparedAuthData';
import Config from '../../../configs/appConfig';



const tokenName = 'token';
const userData = JSON.parse(localStorage.getItem('user'));

const clearLocalStorage = () => {
  localStorage.removeItem(tokenName);
  localStorage.removeItem('user');
  localStorage.removeItem('app_version');
  localStorage.removeItem('featured_sources');
  localStorage.removeItem('feeds_options');
  localStorage.removeItem('configs');
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem(tokenName) ?? null,
    userData: {
      permissions: userData?.permissions || [],
      roles: userData?.roles || [],
      user: userData?.user || {},
    },
  },
  reducers: {
    setCredentials: (state, action) => {
      const { data, meta } = action.payload;
      const userData = makePreparedAuthData(data);

      if (meta?.custom) {
        state.token = meta.custom.access_token;
        localStorage.setItem(tokenName, meta.custom.access_token);
        localStorage.setItem('featured_sources', JSON.stringify(meta.custom.sources));
        localStorage.setItem('feeds_options', JSON.stringify(meta.custom.feeds));
        localStorage.setItem('configs', JSON.stringify(meta.custom.configs));
      }

      if (data) {
        state.userData = userData;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('app_version', JSON.stringify(Config.LAST_COMMIT_HASH));
      }
    },
    refreshUserData: (state, action) => {
      const { data } = action.payload;
      const userData = makePreparedAuthData(data);

      if (data) {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('app_version', JSON.stringify(Config.LAST_COMMIT_HASH));
        state.userData = userData;
      }
    },
    logOut: (state) => {
      state.userData = {
        permissions: [],
        roles: [],
        user: {},
      };
      state.token = null;
      clearLocalStorage();
    },
  },
});

export const {
  setCredentials,
  refreshUserData,
  logOut,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectUserData = (state) => state.auth.userData;

