import React from 'react';
import { Card, Col, Row, Skeleton } from 'antd';
import { Link, useParams } from 'react-router-dom';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import urlPageTemplates from '../../../../../urls/urlPageTemplates';
import DictionariesProvider from '../../../../Translations/providers/DictionariesProvider';
import FormCreateEditTemplate from '../../../forms/FormCreateEditTemplates';
import { useGetTemplateQuery } from '../../../api/templatesApiSlice';
import { useGetAllVersionsStructuresQuery } from '../../../../Translations/api/structuresApiSlice';



const PageTemplateDetails = () => {
  const { templateId } = useParams();
  const searchParams = localStorage.getItem('searchParams');
  const { data = { data: {} }, isFetching } = useGetTemplateQuery(templateId);
  const { data: structures, isLoading: isStructuresLoading } = useGetAllVersionsStructuresQuery();

  const template = data?.data ?? {};

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageTemplates({ searchParams })}>
              <IntlMessages id='templates-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={template?.title}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <DictionariesProvider>
              <Spinner spinning={isFetching}>
                {isStructuresLoading ? <Skeleton /> : (
                  <FormCreateEditTemplate
                    initialValues={{
                      app_key: template?.app_key,
                      title: template?.title,
                      is_active: template?.is_active,
                      content_type_id: template?.content_type?.data.id,
                      target: {
                        min_version: template?.target?.data.min_version,
                        max_version: template?.target?.data.max_version,
                        platform: template?.target?.data.platform,
                        countries: template?.target?.data.countries,
                      },
                    }}
                    formErrors={[]}
                    treeData={template?.fields?.data ?? []}
                    structures={structures}
                    isStructuresLoading={isStructuresLoading}
                    isView
                  />
                )}
              </Spinner>
            </DictionariesProvider>
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageTemplateDetails;

