import dayjs from 'dayjs';
import { DATE_FORMAT } from '../const/system';



export default (date, unavailableDates = [], isDateBeforeOrAfterBoundary) => {
  return (dayjs(date).isBefore(dayjs().subtract(1, 'day').startOf('day')))
    || unavailableDates.includes(dayjs(date).format(DATE_FORMAT))
    || isDateBeforeOrAfterBoundary;
};
