import React from 'react';
import { Col } from 'antd';



const Footer = () => {
  return (
    <Col span={24} className="hp-py-32">
      <p className="hp-mb-8 hp-p1-body hp-text-color-black-60">
        COPYRIGHT ©
        {new Date().getFullYear()}
        {' '}
        X-Flow, All rights Reserved
      </p>
    </Col>
  );
};

export default Footer;
