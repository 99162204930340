const logs = {
  'logs-breadcrumbs': 'Логи',
  'log-details-breadcrumbs': 'Детали лога',
  'logs-filter-title': 'Фильтр логов',
  'logs-table-event-col': 'Событие',
  'logs-table-message-col': 'Сообщение',
  'logs-form-user-placeholder': 'Выберите пользователя',
  'logs-form-event-placeholder': 'Выберите событие',
  'logs-form-message-placeholder': 'Выберите сообщение',
  'log-details-title': 'Детали лога',
  'log-details-user-name': 'Имя пользователя',
  'log-details-content-id': 'ID контента',
  'log-details-log-id': 'ID лога',
  'log-details-model': 'Модель',
  'log-details-diff': 'Разница',
  'log-details-diff-before': 'До:',
  'log-details-diff-after': 'После:',
};

export default logs;
