import { useCallback, useState } from 'react';



const useCopyToClipboard = () => {
  const [ copiedText, setCopiedText ] = useState(null);

  const copy = useCallback(async (text) => {
    if (!navigator?.clipboard) {
      // eslint-disable-next-line no-console
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  }, []);

  return [ copiedText, copy ];
};

export default useCopyToClipboard;
