import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const PacksApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Packs' ],
  endpoints: (builder) => {
    return ({
      getPacks: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/packs${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response?.meta?.pagination,
          };
        },
        providesTags: [ 'Packs' ],
      }),
    });
  },
});

export const {
  useGetPacksQuery,
} = PacksApiSlice;
