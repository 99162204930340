import {Button, Tooltip} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';


const ButtonDanger = ({
  className = '',
  onClick = () => {},
  text = <IntlMessages id='ui-general-unpublish' />,
  disabled = false,
  hasTooltip = false,
}) => {
  return hasTooltip ? (
      <Tooltip
        className={`${className}-tooltip`}
        placement="topRight"
        title={<IntlMessages id="feeds-list-unpublish-disabled-tooltip"/>}
      >
        <Button disabled={disabled} danger onClick={onClick}>
          {text}
        </Button>
      </Tooltip>
    ) : (
      <Button disabled={disabled} className={className} danger onClick={onClick}>
        {text}
      </Button>
    );
};

ButtonDanger.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.node,
  disabled: PropTypes.bool,
  hasTooltip: PropTypes.bool,
};

export default ButtonDanger;
