import { useEffect, useRef } from 'react';



const useScrollToImagesOnSearch = (images, searchQueryParams) => {
  const imagesRef = useRef(null);

  useEffect(() => {
    if (images.length > 0 && searchQueryParams.get('search')) {
      imagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ images, searchQueryParams ]);

  return imagesRef;
};

export default useScrollToImagesOnSearch;
