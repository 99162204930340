import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';



const ImageModalCardBodyRemove = ({ preview, removeText }) => {
  return (
    <Row gutter={[ 32, 32 ]} justify="left" className='hp-mb-24'>
      <Col xs={24} md={12}>
        <img alt="preview" src={preview} />
      </Col>
      <Col xs={24} md={12}>
        {removeText}
      </Col>
    </Row>
  );
};

ImageModalCardBodyRemove.propTypes = {
  preview: PropTypes.string.isRequired,
  removeText: PropTypes.node.isRequired,
};

export default ImageModalCardBodyRemove;
