import React from 'react';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



export const statusOptions = [ {
  label: <IntlMessages id="ui-general-on" />,
  value: 'on',
}, {
  label: <IntlMessages id="ui-general-off" />,
  value: 'off',
} ];

export const typeOptions = [ {
  label: 'External link',
  value: 'external link',
}, {
  label: 'App action',
  value: 'app action',
} ];
