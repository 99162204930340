import { FEATURES_SOURCE_MAP } from '../../pages/PageFeatures/PageFeatures.const';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const getFeaturedSourceTagColor = (source) => {
  switch (source) {
    case FEATURES_SOURCE_MAP.DAILY: return 'cyan';
    case FEATURES_SOURCE_MAP.EDITOR_CHOICE: return 'gold';
    case FEATURES_SOURCE_MAP.EXTERNAL_PROMO: return 'purple';

    default: return EMPTY_VALUE_PLACEHOLDER;
  }
};
