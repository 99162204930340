import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { PiDotsThreeOutlineFill, PiInfinity, PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { IoEarth } from 'react-icons/io5';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageCollectionDetails from '../../../../../urls/urlPageCollectionDetails';
import urlPageTemplateDetails from '../../../../../urls/urlPageTemplateDetails';
import { HasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import getDictionaryIdFromDynamicData from '../../../../../lib/getDictionaryIdFromDynamicData';
import CollapsibleText from '../../../../../components/CollapsibleText';
import PublishedTag from '../../../../../components/PublishedTag';
import {
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PREVIEW,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../../const/system';



dayjs.extend(isSameOrBefore);
dayjs.extend(utc);


export const COLLECTIONS_SOURCE = 'collections';
export const COLLECTIONS_SOURCES = 'Collections,Charity,Stories';
export const COLLECTION_TEMPLATE_CONTENT_TYPE = 'collection';

export const getCollectionsTableColumns = (
  handlePreviewDictionary,
  handleEdit,
  handleDelete,
  collectionsConfig,
  tableSize,
) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.08,
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-published" />,
    align: 'center',
    dataIndex: 'is_published',
    sorter: true,
    width: tableSize.width * 0.10,
    render: (value, item) => <PublishedTag published={Boolean(value)} publishedDate={item.published_at} />,
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    width: tableSize.width * 0.12,
    align: 'center',
    render: (item, value) => {
      const rows = item.split('\n');

      return (
        rows.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <CollapsibleText text={content} />
            &nbsp;
            {index === 0 && value?.is_published && dayjs(value.updated_at).isAfter(dayjs(value.published_at)) && (
              <Tooltip placement="right" title={<IntlMessages id="ui-general-unpublished-tooltip-title" />}>
                &nbsp;
                <ExclamationCircleOutlined className="hp-text-color-warning-1" />
              </Tooltip>
            )}
          </div>
        ))
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-template" />,
    align: 'center',
    width: tableSize.width * 0.14,
    render: (item) => {
      return (
        HasRights([ Permissions.CONSTRUCTORS.TEMPLATES.FIND ]) ? (
          <Link style={{ wordBreak: 'break-all' }} to={urlPageTemplateDetails({ templateId: item?.template?.data?.id })} target="_blank">
            {item?.template?.data?.title}
            <FiExternalLink size={14} className="hp-ml-4" />
          </Link>
        ) : (
          <div>{item?.template?.data?.title}</div>
        )
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-content" />,
    align: 'center',
    width: tableSize.width * 0.14,
    render: (value) => {
      const imagesLength = value.images?.data.length;
      const allowedImgCount = Math.floor((tableSize.width * 0.14) / 70);
      const hasCollapsedItems = imagesLength > allowedImgCount;

      return (
        <div className="hp-d-flex">
          {imagesLength > 0 ? (
            <>
              {value.images?.data?.slice(0, allowedImgCount).map((image) => {
                return (
                  <LazyLoadImage
                    className='lazy-load-img-list'
                    effect='blur'
                    alt="picture preview"
                    key={image.id}
                    src={image?.preview?.data?.path ?? EMPTY_IMAGE_PREVIEW}
                  />
                );
              })}
              {hasCollapsedItems && (
                <div
                  className='hp-align-self-center hp-cursor-pointer'
                  style={{ width: '30px' }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="collection-table-content-tooltip-label" />}>
                    <a
                      href={`${urlPageCollectionDetails({ collectionId: value.id })}#attached-images`}
                      className="hp-text-color-black-80 hp-text-color-dark-60"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PiDotsThreeOutlineFill size='14' />
                    </a>
                  </Tooltip>
                </div>
              )}
            </>
          ) : (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-no-attached-images" />}>
              <LazyLoadImage
                className='lazy-load-img-list'
                effect='blur'
                alt="empty preview"
                src={EMPTY_IMAGE_PREVIEW}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-released-at" />,
    dataIndex: 'released_at',
    align: 'center',
    sorter: true,
    width: tableSize.width * 0.12,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...collectionsConfig?.release_range ? (
    [ {
      title: <IntlMessages id="ui-general-released-to" />,
      dataIndex: 'released_to',
      align: 'center',
      sorter: true,
      width: tableSize.width * 0.12,
      render: (value, item) => {
        if (!item.released_to) {
          return item.released_at ? <PiInfinity /> : EMPTY_VALUE_PLACEHOLDER;
        }
        return getFormattedDate(value, DATE_TIME_FORMAT);
      },
    } ]
  ) : [],
  ...(HasRights([
    Permissions.CONTENT.COLLECTIONS.VIEW,
    Permissions.CONTENT.COLLECTIONS.EDIT,
    Permissions.CONTENT.COLLECTIONS.DELETE,
  ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      width: tableSize.width * 0.13,
      render: (item) => {
        const dictionaryId = getDictionaryIdFromDynamicData(item?.dynamic_data?.data);
        const canEditPastItem = collectionsConfig?.publish?.allow_update_past ?? true;
        const canEditItem = canEditPastItem ? true : dayjs().isSameOrBefore(dayjs(item?.released_at), 'day');

        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.VIEW ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Link
                    to={urlPageCollectionDetails({ collectionId: item.id })}
                    target="_blank"
                  >
                    <Eye
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </Link>
                </div>
              </Tooltip>
            </PrivateComponent>

            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.EDIT ]}>
              {canEditItem && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                  <div className="hp-text-right">
                    <PiPencil
                      onClick={() => handleEdit(item.id)}
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>

            <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.DELETE ]}>
              {(!item.is_published && item.images?.data.length === 0) ? (
                <Popconfirm
                  title={<IntlMessages id="ui-general-delete-confirm-message" />}
                  placement="top"
                  onConfirm={() => handleDelete(item.id)}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip placement="top" title={<IntlMessages id="collections-delete-notification" />}>
                  <div className="hp-text-right">
                    <Delete
                      disabled
                      size={20}
                      className="hp-opacity-4 hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
              {dictionaryId && (
                <Tooltip placement="top" title={<IntlMessages id="ui-general-dictionary" />}>
                  <div className="hp-text-right">
                    <IoEarth
                      size={20}
                      onClick={() => {
                        handlePreviewDictionary(dictionaryId);
                      }}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              )}
            </PrivateComponent>
          </Row>
        );
      },
    } ] : []
  ),
];

