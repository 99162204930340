import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
};

const UserStatus = ({ status }) => {
  const getTagColor = (status) => status ? 'green' : 'red';

  if (isUndefined(status)) {
    return <span>{EMPTY_VALUE_PLACEHOLDER}</span>;
  }

  return (
    <Tag
      style={{ minWidth: 'fit-content' }}
      color={getTagColor(status)}
    >
      { status ? USER_STATUS.ACTIVE : USER_STATUS.BLOCKED }
    </Tag>
  );
};

UserStatus.propTypes = {
  status: PropTypes.number,
};

export default UserStatus;
