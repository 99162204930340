import React from 'react';
import IntlMessages from '../../../layout/components/lang/IntlMessages';



export const DOCS_EXTERNAL_LINK = 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/622854248';
export const TEMPLATE_TYPES_DOCS_EXTERNAL_LINK = 'https://x-flow-ltd.atlassian.net/wiki/x/AQBiJQ';
export const TEMPLATE_FIELD_SELECT_DOCS_EXTERNAL_LINK = 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/627179521/Fields+type#Select';
export const TEMPLATE_VALIDATION_RULES_DOCS_EXTERNAL_LINK = 'https://x-flow-ltd.atlassian.net/wiki/x/TABbJQ';

export const TYPE_BOOLEAN = 'boolean';
export const TYPE_INTEGER = 'integer';
export const TYPE_DOUBLE = 'double';
export const TYPE_DICTIONARY = 'dictionary';
export const TYPE_ARRAY = 'array';
export const TYPE_STRING = 'string';
export const TYPE_OBJECT = 'object';
export const TYPE_IMAGE = 'image';
export const TYPE_COLOR = 'color';
export const TYPE_DATE = 'date';
export const TYPE_SELECT = 'select';

export const CHILD_TYPES = [ TYPE_BOOLEAN, TYPE_INTEGER, TYPE_DOUBLE, TYPE_DICTIONARY, TYPE_ARRAY, TYPE_STRING, TYPE_IMAGE, TYPE_COLOR, TYPE_DATE, TYPE_SELECT ];

export const VALIDATION_RULES_FILE = 'file';
export const VALIDATION_RULES_REQUIRED = 'required';
export const VALIDATION_RULES_OPTIONAL = 'nullable';

export const TEMPLATE_TYPES = [
  TYPE_BOOLEAN,
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_DICTIONARY,
  TYPE_ARRAY,
  TYPE_STRING,
  TYPE_OBJECT,
  TYPE_IMAGE,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_SELECT,
];

export const TEMPLATE_SUBTYPES = [
  TYPE_BOOLEAN,
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_STRING,
  TYPE_OBJECT,
  TYPE_IMAGE,
];

export const TEMPLATE_TYPES_WITH_VALIDATION = [
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_STRING,
  TYPE_ARRAY,
  TYPE_IMAGE,
];

export const TEMPLATE_TYPES_WITH_DEFAULT_VALUE = [
  TYPE_BOOLEAN,
  TYPE_INTEGER,
  TYPE_DOUBLE,
  TYPE_STRING,
  TYPE_ARRAY,
  TYPE_COLOR,
  TYPE_DATE,
];

export const DRAGGED_ITEM_STYLE = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 8,
  paddingLeft: 12,
  paddingRight: 12,
  margin: '0 0 1px 0',

  background: isDragging ? '#e2ecf7' : 'white',

  ...draggableStyle,
});

export const DRAGGED_LIST_STYLE = {
  background: '#f5f5f5',
  padding: 0,
};

export const TEMPLATE_TYPE_OPTIONS = [
  {
    value: TYPE_BOOLEAN,
    label: <IntlMessages id="templates-create-form-field-value-boolean-label" />,
  },
  {
    value: TYPE_INTEGER,
    label: <IntlMessages id="templates-create-form-field-value-integer-label" />,
  },
  {
    value: TYPE_DOUBLE,
    label: <IntlMessages id="templates-create-form-field-value-double-label" />,
  },
  {
    value: TYPE_STRING,
    label: <IntlMessages id="templates-create-form-field-value-string-label" />,
  },
  {
    value: TYPE_ARRAY,
    label: <IntlMessages id="templates-create-form-field-value-array-label" />,
  },
  {
    value: TYPE_OBJECT,
    label: <IntlMessages id="templates-create-form-field-value-object-label" />,
  },
  {
    value: TYPE_IMAGE,
    label: <IntlMessages id="templates-create-form-field-value-image-label" />,
  },
  {
    value: TYPE_DICTIONARY,
    label: <IntlMessages id="templates-create-form-field-value-dictionary-label" />,
  },
  {
    value: TYPE_COLOR,
    label: <IntlMessages id="templates-create-form-field-value-color-label" />,
  },
  {
    value: TYPE_DATE,
    label: <IntlMessages id="templates-create-form-field-value-date-label" />,
  },
  {
    value: TYPE_SELECT,
    label: <IntlMessages id="templates-create-form-field-value-select-label" />,
  },
];

export const TEMPLATE_SUBTYPE_OPTIONS = [
  {
    value: TYPE_INTEGER,
    label: <IntlMessages id="templates-create-form-field-value-integer-label" />,
  },
  {
    value: TYPE_DOUBLE,
    label: <IntlMessages id="templates-create-form-field-value-double-label" />,
  },
  {
    value: TYPE_STRING,
    label: <IntlMessages id="templates-create-form-field-value-string-label" />,
  },
];

export const AUTOFOCUS_INPUT_SELECTOR = '.hc-select-dropdown__form-input';

export const PLATFORM_OPTIONS = [ {
  label: 'gp',
  value: 'gp',
}, {
  label: 'ios',
  value: 'ios',
}, {
  label: 'amazon',
  value: 'amazon',
} ];
