import { Col, Row, Typography } from 'antd';
import React from 'react';
import { FormItem, Input, Select } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { targetPlatformOptions } from '../../const/system';
import CountrySelector from '../CountrySelector';
import { countries } from '../../const/countries';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const { Title } = Typography;

const TargetingSettings = ({ setFieldValue, values }) => {
  const intl = useIntl();

  return (
    <>
      <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-targets" /></Title>

      <Row gutter={[ 16, 16 ]}>
        <Col span={12}>
          <FormItem
            label={intl.formatMessage({ id: 'ui-general-min-version' })}
            name='target.min_version'
          >
            <Input
              name="target.min_version"
              placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-min-version-placeholder' })}
            />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            label={intl.formatMessage({ id: 'ui-general-max-version' })}
            name='target.max_version'
          >
            <Input
              name="target.max_version"
              placeholder={intl.formatMessage({ id: 'dynamic-news-create-form-targets-max-version-placeholder' })}
            />
          </FormItem>
        </Col>

        <Col span={24}>
          <FormItem
            label={intl.formatMessage({ id: 'ui-general-platform' })}
            name='target.platform'
          >
            <Select
              mode="multiple"
              allowClear
              name="target.platform"
              options={targetPlatformOptions}
              placeholder={intl.formatMessage({ id: 'ui-general-choose-platform' })}
            />
          </FormItem>
        </Col>

        <Col span={24}>
          <FormItem
            label={intl.formatMessage({ id: 'ui-general-countries' })}
            name='target.countries'
            required
          >
            <CountrySelector
              onSelectAll={async () => {
                await setFieldValue('target.countries', countries.map((country) => country.value).sort());
              }}
              onSelectAllDisabled={values?.target?.countries?.length === countries.length}
              setFieldValue={setFieldValue}
              name='target.countries'
              placeholder={intl.formatMessage({ id: 'ui-general-choose-countries' })}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

TargetingSettings.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TargetingSettings;
