import React from 'react';
import { Form, FormItem, Input, Radio, ResetButton, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import getNotEmptyObjectValues from '../../../../lib/getNotEmptyObjectValues';
import Spinner from '../../../../components/Spinner';



const FormBonusesFilter = ({
  initialValues,
  formErrors = {},
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(getNotEmptyObjectValues(values));
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ isValid, dirty, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form layout="vertical">
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    name='id'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="id"
                      placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    name='code'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-code" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-code-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="code"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-codes' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-title' })}
                    name='title'
                  >
                    <Input
                      name="title"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-title' })}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]}>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-released-at' })}
                    name='released_at'
                  >
                    <DatePicker
                      name='released_at'
                      format={DATE_FORMAT}
                      showToday
                      value={values?.released_at ? moment(values.released_at) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('released_at', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('released_at', true);
                        await onBlur('released_at', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-released-to' })}
                    name='released_to'
                  >
                    <DatePicker
                      name='released_to'
                      format={DATE_FORMAT}
                      value={values?.released_to ? moment(values.released_to) : null}
                      onChange={async (date, dateString) => {
                        await setFieldValue('released_to', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await setFieldTouched('released_to', true);
                        await onBlur('released_to', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='start'>
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-published' })}
                    name='is_published'
                  >
                    <Radio.Group
                      size='small'
                      name='is_published'
                      defaultValue={null}
                    >
                      <Radio.Button value={null}>{intl.formatMessage({ id: 'ui-general-all' })}</Radio.Button>
                      <Radio.Button value={1}>{intl.formatMessage({ id: 'ui-general-yes' })}</Radio.Button>
                      <Radio.Button value={0}>{intl.formatMessage({ id: 'ui-general-no' })}</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormBonusesFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormBonusesFilter;
