import { useState, useRef, useEffect } from 'react';



const useElementSize = () => {
  const elementRef = useRef();
  const [ size, setSize ] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return;
      }
      if (!entries.length) {
        return;
      }

      const entry = entries[0];

      setSize({
        width: Math.round(entry.contentRect.width),
        height: Math.round(entry.contentRect.height),
      });
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, [ elementRef ]);

  return [ elementRef, size ];
};

export default useElementSize;
