export const typeOptions = [
  {
    label: 'External link',
    value: 'external link',
  }, {
    label: 'App action',
    value: 'app action',
  },
];

export const STRUCTURE_SOURCE_NEWS = 'news';
