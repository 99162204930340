import antdEN from 'antd/lib/locale-provider/en_US';
import enMessages from '../locales/english';



const enLang = {
  messages: {
    ...enMessages,
  },
  antd: antdEN,
  locale: 'en-US',
};

export default enLang;
