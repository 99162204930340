import modifyDefaultValue from './modifyDefaultValue';
import {
  TEMPLATE_TYPES_WITH_VALIDATION,
  TYPE_DICTIONARY,
  VALIDATION_RULES_FILE,
  VALIDATION_RULES_OPTIONAL,
  VALIDATION_RULES_REQUIRED,
} from '../const/templates';



const formatTemplateFieldValues = (values, structures) => {
  values.default_value = modifyDefaultValue(values);

  if (values.value && values.type === TYPE_DICTIONARY) {
    values.value = structures.data.find((structure) => structure.id === Number(values.value))?.id;
  }

  values.is_required = values.validation.includes(VALIDATION_RULES_REQUIRED);

  values.validation = values.validation.filter((rule) => {
    return ![
      VALIDATION_RULES_REQUIRED,
      VALIDATION_RULES_OPTIONAL,
    ].includes(rule) && ![
      ...TEMPLATE_TYPES_WITH_VALIDATION,
      VALIDATION_RULES_FILE,
    ].includes(rule);
  });

  return values;
};

export default formatTemplateFieldValues;
