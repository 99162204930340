import { useCallback, useState } from 'react';



const useToggle = (initState = false) => {
  const [ isActive, setIsActive ] = useState(initState);

  const toggle = useCallback(() => {
    setIsActive((prevState) => !prevState);
  }, [ isActive ]);

  return [ isActive, toggle ];
};


export default useToggle;
