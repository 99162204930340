import React from 'react';
import { Button } from 'antd';
import { FormItem, Input } from 'formik-antd';
import isEmpty from 'lodash/isEmpty';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { AUTOFOCUS_INPUT_SELECTOR, TYPE_IMAGE, TYPE_OBJECT, TYPE_STRING } from '../const/templates';



const SelectDropdown = ({ value, inputValue, setFieldValue, values, btnMessage, errors, customInputType, className }) => {
  const disabled = (!isEmpty(values[inputValue] &&
      values[value]) ? values[value].includes(values[inputValue]) : false) ||
    (errors ? !isEmpty(errors?.[inputValue]) : false) ||
    isEmpty(values[inputValue]);

  const inputType = customInputType ? [ TYPE_STRING, TYPE_IMAGE, TYPE_OBJECT ].includes(values.subtype) ? 'text' : 'number' : 'text';

  const handleClick = () => {
    setFieldValue(value, [ ...values[value], values[inputValue] ], false);
    setFieldValue(inputValue, '', true);
    document.querySelector(AUTOFOCUS_INPUT_SELECTOR).focus();
  };

  const handleChange = (event) => {
    setFieldValue(inputValue, event.target.value, true);
  };

  return (
    <div>
      <div className={className}>
        <FormItem
          className='ant-form-item-col ant-form-item-custom-option hc-select-dropdown__form-item'
          name={inputValue}
        >
          <Input
            type={inputType}
            className="hc-select-dropdown__form-input"
            fast
            size='small'
            name={inputValue}
            onKeyDown={(event) => event.stopPropagation()}
            value={values[inputValue]}
            onChange={handleChange}
          />
        </FormItem>
        <Button
          className="hc-select-dropdown__button"
          type="primary"
          size='small'
          disabled={disabled}
          onClick={handleClick}
        >
          <PlusOutlined />
          {btnMessage}
        </Button>
      </div>
    </div>
  );
};

SelectDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  btnMessage: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  errors: PropTypes.object,
  customInputType: PropTypes.bool,
  className: PropTypes.string,
};

SelectDropdown.defaultProps = {
  customInputType: false,
  className: 'hc-select-dropdown',
};

export default SelectDropdown;
