import React from 'react';
import { EMPTY_IMAGE_PLACEHOLDER } from '../const/system';



const getImagesOptions = (images) => {
  const imagesData = [ ...images.data ];

  return imagesData.map((item) => ({
    label: (
      <div className="hp-d-flex hp-align-items-center">
        <img height={35} src={item?.preview?.data?.path ?? EMPTY_IMAGE_PLACEHOLDER} alt="preview img" />
        <span className="hp-ml-4">{item.id}</span>
      </div>
    ),
    value: item.id,
  }));
};

export default getImagesOptions;

