import enLang from './entries/en_US';
import ruLang from './entries/ru_RU';



const AppLocale = {
  en: enLang,
  ru: ruLang,
};

export default AppLocale;
