import isArray from 'lodash/isArray';



export default (errors) => {
  const transformedErrors = {
    id: new Date().getTime(), // hack to rerender errors in case of same errors
  };

  Object.entries(errors)
    .forEach(([ key, value ]) => {
      const [ first, second, third ] = key.split('.');

      if (first && second && third) {
        if (!transformedErrors[first]) {
          transformedErrors[first] = [];
        }

        if (!transformedErrors[first][second]) {
          transformedErrors[first][second] = [];
        }

        transformedErrors[first][second][third] = isArray(value) ? value.join(' ') : value;
      } else if (first && second && !third) {
        if (!transformedErrors[first]) {
          transformedErrors[first] = [];
        }

        transformedErrors[first][second] = isArray(value) ? value.join(' ') : value;
      } else if (first && !second && !third) {
        transformedErrors[first] = isArray(value) ? value.join(' ') : value;
      }
    });

  return transformedErrors;
};
