import { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { selectCurrentToken } from '../../domains/Auth/features/authSlice';
import Config from '../../configs/appConfig';
import { makeNotification, notificationTypes } from '../../lib/makeNotification';
import getTestModeParams from '../../lib/getTestModeParams';



const useDownload = (options = { method: 'GET' }) => {
  const token = useSelector(selectCurrentToken);
  const testersModeParams = getTestModeParams();
  const [ loading, setLoading ] = useState(false);
  const requestInit = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/*',
      'Content-Type': 'application/json',
    },
    method: options.method,
  };

  if (!isEmpty(testersModeParams)) {
    const { is_developer, developer_id } = testersModeParams;

    requestInit.headers['x-api-developer'] = is_developer;
    requestInit.headers['x-api-developer-id'] = developer_id;
  }

  const download = (url, data = null) => {
    const path = url.startsWith('/') ? url.substring(1) : url;

    setLoading(true);

    if (data) {
      requestInit.body = JSON.stringify(data);
    }

    fetch(
      `${Config.VITE_HC_API_URL}/${Config.VITE_HC_API_VERSION}/${path}`,
      requestInit,
    )
      .then(async (response) => {
        const filename = response.headers
          .get('content-disposition')
          .split('=')[1];
        const file = await response.blob();

        const url = window.URL.createObjectURL(new Blob([ file ]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        let message = 'Download failed.';

        if (error?.data?.message) {
          message += `\n ${error.data.message}`;
        }

        makeNotification(notificationTypes.error, message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { download, isLoading: loading };
};

export default useDownload;
