import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { targetValidation } from '../../../../const/targetValidation';
import { immutableField } from '../../../../lib/immutableField';
import Yup from '../../../../vendor/yup';



export const makeValidationSchema = (collectionConfig, originalCollection = {}, dynamicData = {}) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string()
    .nullable()
    .min(3, 'Min 3 symbols required')
    .max(2000, 'Max 2000 symbols required'),
  template_id: Yup.string().required('Required to specify template'),
  use_utc_zero_time: Yup.bool(),
  is_published: Yup.bool().nullable(),
  categories: immutableField(originalCollection.categories.data.map((category) => category.id)),
  image_type: immutableField(originalCollection.image_type),
  released_at: Yup.string().required('Required to specify released at date')
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return dayjs(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ),
  ...collectionConfig?.config?.release_range && {
    released_to: Yup.string().required('Required to specify released to date')
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return dayjs(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  target: targetValidation,
  ...dynamicData,
}));

export const isOutImageOfReleaseDates = (releasedImages, initialValues, releasedAt, releasedTo) => {
  return releasedImages.some((image) => {
    const outOfRangeArr = [];

    if (!isEmpty(releasedAt) && !isEmpty(image?.published_at)) {
      outOfRangeArr.push(moment(image?.published_at).isBefore(moment(releasedAt)));
    }

    if (!isEmpty(releasedTo)) {
      if (!isEmpty(image?.published_to)) {
        outOfRangeArr.push(moment(image?.published_to).isAfter(moment(releasedTo)));
      }

      if (isEmpty(image?.published_to) && !moment(initialValues.released_to).isSame(moment(releasedTo))) {
        outOfRangeArr.push(true);
      }
    }

    if ((isEmpty(initialValues.released_to) && isEmpty(image?.published_to) && !isEmpty(releasedTo)) ||
      (!isEmpty(initialValues.released_to) && !isEmpty(image?.published_to) && isEmpty(releasedTo))) {
      outOfRangeArr.push(true);
    }

    return outOfRangeArr.includes(true);
  });
};
