import React, { useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import isObject from 'lodash/isObject';
import urlPageTranslationDetails from '../../../../../urls/urlPageTranslationDetails';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageDictionaries from '../../../../../urls/urlPageDictionaries';
import urlPageFeatures from '../../../../../urls/urlPageFeatures';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormEditDictionary from '../../../forms/FormEditDictionary';
import PreviewDictionaryStructure from '../../../components/PreviewDictionaryStructure';
import DictionariesProvider from '../../../providers/DictionariesProvider';
import getFeatureSourceTranslation from '../../../../Featured/utils/getFeatureSourceTranslation';
import Spinner from '../../../../../components/Spinner';
import { useGetDictionaryQuery, useUpdateDictionaryMutation } from '../../../api/dictionariesApiSlice';



const PageDictionaryEdit = () => {
  const { id } = useParams();
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const multipleSources = searchQueryParams.get('multipleSources') ?? null;
  const navigate = useNavigate();
  const [ dictionaryErrors, setEditDictionaryErrors ] = useState(null);

  const { data, isLoading } = useGetDictionaryQuery({ id, include: 'structure' });
  const [ updateDictionary, { isLoading: isDictionaryUpdating } ] = useUpdateDictionaryMutation();

  const handleUpdateDictionary = async (values) => {
    updateDictionary({ dictionaryId: id, values }).unwrap().then(({ data: dictionary }) => {
      navigate(urlPageTranslationDetails({ id: dictionary.id, source: navigationSource, multipleSources }));
    }).catch((error) => {
      if (isObject(error?.data)) {
        setEditDictionaryErrors(error.data.errors);
      }
    });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbParent2={
            <Link to={urlPageDictionaries({ source: navigationSource, multipleSources })}>
              <IntlMessages id='dictionaries-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />
      </Row>
      <Row gutter={32}>
        <Col span={24}>
          <Spinner spinning={isLoading}>
            <Card title={<IntlMessages id='dictionaries-edit-title' />} className="hp-border-color-black-40 hp-card-6">
              <DictionariesProvider edit>
                <Row gutter={24}>
                  <Col span={11}>
                    <FormEditDictionary
                      initialValues={data || {}}
                      isSubmitting={isDictionaryUpdating}
                      formErrors={dictionaryErrors}
                      onSubmit={handleUpdateDictionary}
                    />
                  </Col>
                  <Col span={11} offset={2}>
                    <PreviewDictionaryStructure />
                  </Col>
                </Row>
              </DictionariesProvider>
            </Card>
          </Spinner>
        </Col>
      </Row>
    </>
  );
};

export default PageDictionaryEdit;
