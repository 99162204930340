import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';



export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    overrideExisting: true,
    uploadAvatar: builder.mutation({
      query: (data) => {
        return {
          url: `/${Config.VITE_HC_API_VERSION}/users/${data.userId}/avatars`,
          method: 'POST',
          body: data.formData,
        };
      },
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id }, { type: 'User' } ],
    }),
  }),
});

export const {
  useUploadAvatarMutation,
} = profileApiSlice;
