import { TYPE_OBJECT } from '../const/templates';



const mkMainAndOptionalTemplateFields = (templateFields = []) => {
  const sortByPosition = (_a, _b) => _a.position - _b.position;

  const filterFields = (isMain) => templateFields.length > 0 ? templateFields
    .filter(({ is_main, type }) => is_main === isMain && type !== TYPE_OBJECT)
    .sort(sortByPosition) : [];

  return {
    mainFields: filterFields(true),
    optionalFields: filterFields(false),
  };
};

export default mkMainAndOptionalTemplateFields;
