import React from 'react';
import PropTypes from 'prop-types';
import { Col, Progress, Row } from 'antd';



const FIELD_STATUS = {
  success: 1,
  normal: 2,
  exception: 3,
};

const FieldProgress = ({ text, min, max }) => {
  const countLetters = (text ? text.length : 0);
  const percent = (countLetters / max) * 100;

  const getStatus = () => {
    if (countLetters < min || countLetters > max) {
      return FIELD_STATUS.exception;
    }

    if (percent >= 80) {
      return FIELD_STATUS.normal;
    }

    return FIELD_STATUS.success;
  };

  const getProgressStatus = () => {
    switch (getStatus()) {
      case FIELD_STATUS.success:
        return 'success';
      case FIELD_STATUS.normal:
        return 'normal';
      case FIELD_STATUS.exception:
        return 'exception';
      default:
        return 'success';
    }
  };

  const getProgressColor = () => {
    switch (getStatus()) {
      case FIELD_STATUS.success:
        return '#00f7bf';
      case FIELD_STATUS.normal:
        return '#0010f7';
      case FIELD_STATUS.exception:
        return '#ff0022';
      default:
        return '#00f7bf';
    }
  };

  return (min || max) && (
    <Row justify="space-between">
      <Col span={20}>
        <Progress
          percent={percent}
          status={getProgressStatus()}
          size="small"
          showInfo={false}
        />
      </Col>
      <Col span={4}>
        <div
          style={{
            fontSize: '0.8rem',
            color: getProgressColor(),
            textAlign: 'end',
          }}
        >
          {countLetters}
        </div>
      </Col>
    </Row>
  );
};


FieldProgress.propTypes = {
  text: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
};

FieldProgress.defaultProps = {
  text: '',
  min: 0,
  max: 0,
};

export default FieldProgress;
