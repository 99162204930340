import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Divider } from 'antd';
import { selectUserData } from '../../../Auth/features/authSlice';
import { DATE_TIME_FORMAT } from '../../../../const/system';
import getFormattedDate from '../../../../lib/getFormattedDate';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const PagePersonalInformation = () => {
  const listTitle = 'hp-p1-body';
  const listResult = 'hp-mt-sm-4 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0';
  const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80';
  const userData = useSelector(selectUserData);

  return (
    <div>
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <h3><IntlMessages id="profile-personal-information-page" /></h3>
        </Col>
        <Divider className={dividerClass} />
        <Col
          span={24}
          className="hp-profile-content-list hp-mt-8 hp-pb-sm-0 hp-pb-120"
        >
          <ul>
            <li className="hp-mt-18">
              <span className={listTitle}>
                <IntlMessages id="personal-information-last-login-field" />
                :
              </span>
              <span className={listResult}>{ getFormattedDate(userData?.user?.last_login, DATE_TIME_FORMAT) }</span>
            </li>
            <li className="hp-mt-18">
              <span className={listTitle}>
                <IntlMessages id="ui-general-created-at" />
                :
              </span>
              <span className={listResult}>{ getFormattedDate(userData?.user?.created_at, DATE_TIME_FORMAT) }</span>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default PagePersonalInformation;
