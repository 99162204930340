import isString from 'lodash/isString';
import { TYPE_ARRAY, TYPE_BOOLEAN, TYPE_DOUBLE, TYPE_INTEGER } from '../const/templates';



const modifyDefaultValue = (values) => {
  let { default_value } = values;

  if (default_value) {
    if (values.type === TYPE_BOOLEAN) {
      default_value = Boolean(Number(values.default_value));
    }

    if (values.type === TYPE_DOUBLE) {
      default_value = parseFloat(values.default_value);
    }

    if (values.type === TYPE_INTEGER) {
      default_value = parseInt(values.default_value, 10);
    }

    if (values.type === TYPE_ARRAY && isString(values.default_value)) {
      default_value = values.default_value.split(',');
    }
  }

  return default_value;
};

export default modifyDefaultValue;
