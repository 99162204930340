import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Typography, Pagination, Modal, Card } from 'antd';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { RiArrowLeftLine, RiCloseFill } from 'react-icons/ri';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageCategories from '../../../../../urls/urlPageCategories';
import { SOURCE_CATEGORIES } from '../PageCategories/PageCategories.const';
import { useGetCategoryQuery } from '../../../api/categoriesApiSlice';
import Spinner from '../../../../../components/Spinner';
import useDictionaryOptions from '../../../../../app/hooks/useDictionaryOptions';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import ContentCard from '../../../components/ContentCard';
import CategoryDetailsCard from '../../../components/CategoryDetailsCard';
import TotalLabel from '../../../../../components/TotalLabel';
import ImageModalCardBodyView from '../../../components/ImageModalCardBodyView';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { useGetImagesQuery } from '../../../api/imagesApiSlice';



const { Title } = Typography;

const PageCategoryDetails = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ dictionaryOptions = [], isStructuresLoading ] = useDictionaryOptions(SOURCE_CATEGORIES, null);

  const indexPageSearchParams = localStorage.getItem('indexSearchParams');

  const {
    data: { data: category } = { data: {} },
    isFetching: isCategoryFetching,
  } = useGetCategoryQuery(categoryId);

  const categoryDictionaryId = category?.dictionary?.data?.id;
  const categoryDictionary = dictionaryOptions?.find((dictionaryOption) => dictionaryOption.value === categoryDictionaryId)?.label;

  const {
    pagination: pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({
    paginationInit: { page: 1, limit: 12 } });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching: isImagesFetching } = useGetImagesQuery({
    queryParams: `${searchParams.toString()}&search=category:${categoryId}`,
  });

  const showTotal = (total) => <TotalLabel total={total} />;

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const isMainInfoLoading = isStructuresLoading || isCategoryFetching || isImagesFetching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCategories({ indexPageSearchParams })}>
              <IntlMessages id='categories-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={category.name}
        />

        <Col>
          <ActionButton
            icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
            onClick={() => {
              navigate(urlPageCategories());
            }}
            title={<IntlMessages id='ui-general-back-to-list' />}
          />
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Spinner spinning={isMainInfoLoading}>
            <CategoryDetailsCard
              data={category}
              dictionary={categoryDictionary}
              dictionaryId={categoryDictionaryId}
            />
          </Spinner>
        </Col>
      </Row>

      <Row className='hp-mt-32'>
        <Col span={24}>
          <Title level={4}>
            <IntlMessages id="categories-view-images" />
          </Title>
        </Col>

        <Modal
          width={1200}
          title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
          centered
          visible={previewModalOpen}
          onCancel={handlePreviewModalClose}
          footer={false}
          closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
          <ImageModalCardBodyView data={previewImage} />
        </Modal>

        <Col span={24}>
          <Spinner style={{ minHeight: '400px' }} spinning={isImagesFetching}>
            {!isEmpty(images) && (
              <Row gutter={[ 16, 32 ]} className='w-full'>
                {images.map((item) => {
                  return (
                    <Col className='gutter-row' key={item.id} xs={24} sm={12} md={8} lg={6} xxl={4}>
                      <ContentCard
                        data={item}
                        onClick={handleCardClick}
                        allowImageClick
                      />
                    </Col>
                  );
                })}
                <Col span={24}>
                  {dataPagination.total && (
                    <Pagination
                      total={dataPagination.total}
                      showTotal={showTotal}
                      pageSize={pagination.limit}
                      current={pagination.page}
                      pageSizeOptions={[ '12', '24', '48' ]}
                      onChange={(page, size) => {
                        handleChangeTableParams({
                          current: page,
                          pageSize: size,
                        }, {}, {});
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
            {!isImagesFetching && isEmpty(images) && (
              <Card>
                <EmptyDataPlaceholder placeholder={<IntlMessages id="categories-view-images-empty" />} />
              </Card>
            )}
          </Spinner>
        </Col>
      </Row>
    </>
  );
};

export default PageCategoryDetails;
