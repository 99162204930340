const mkRandomCode = () => {
  const allowedCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-';
  const minLength = 3;
  const maxLength = 4;
  const length = minLength + Math.floor(Math.random() * (maxLength - minLength + 1));

  return Array.from({ length }, () => allowedCharacters.charAt(Math.floor(Math.random() * allowedCharacters.length))).join('');
};

export default mkRandomCode;
