export const themeOptions = [ {
  label: 'Salmon',
  value: '0',
}, {
  label: 'Dark blue',
  value: '1',
}, {
  label: 'Black & white',
  value: '2',
} ];

export const destinationOptions = [ {
  label: 'Gameplay',
  value: '0',
}, {
  label: 'General',
  value: '1',
} ];

export const initOrder = [ '1', '2', '3', '4', '5', '6', '7' ];
export const itemsMap = {
  '1': 'picture_open',
  '2': 'pack_open',
  '3': 'category_open',
  '4': 'theme',
  '5': 'bonus_code',
  '6': 'settings_open',
  '7': 'external_link',
};
