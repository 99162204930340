import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Yup from '../../../../vendor/yup';



export const mkValidationSchema =  (bonusesConfig, initialValues = {}) => Yup.lazy((values) => Yup.object().shape({
  title: Yup.string()
    .min(3, 'Min 3 symbols required')
    .max(255, 'Max 255 symbols required')
    .required('Title field is required'),
  code: Yup.string()
    .min(3, 'Min 3 symbols required')
    .max(4, 'Max 4 symbols required')
    .matches(/^[\w\-_\d]{3,4}$/, 'Only alphabets, digits and _, - are allowed for this field')
    .required('Code field is required'),
  released_at: Yup.date()
    .when('is_published', {
      is: false,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required('Required to specify released at date'),
    })
    .test(
      'test_day_in_the_past',
      'Released at date must be same or after than yesterday',
      (value) => {
        if (!isEmpty(initialValues) && dayjs(value).isSame(dayjs(initialValues.released_at))) {
          return true;
        }

        return !dayjs(value).isBefore(dayjs()
          .subtract(1, 'days').startOf('day')
          .utc(false));
      },
    )
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return dayjs(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ),
  ...bonusesConfig?.release_range && {
    released_to: Yup.string().nullable()
      .test(
        'test_day_in_the_past',
        'Released to date must be same or after than yesterday',
        (value) => {
          return !dayjs(value).isBefore(dayjs()
            .subtract(1, 'days').startOf('day')
            .utc(false));
        },
      )
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return dayjs(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ),
  },
  is_published: Yup.bool().nullable().default(false),
  description: Yup.string()
    .max(1000, 'Max 1000 symbols required')
    .nullable(),
}));
