import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const DictionariesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Dictionaries' ],
  endpoints: (builder) => ({
    getDictionaries: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        return ({ url: `/${Config.VITE_HC_API_VERSION}/dictionaries${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Dictionaries' ],
    }),
    getTranslations: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        return ({ url: `/${Config.VITE_HC_API_VERSION}/translations${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Translations' ],
    }),
    getDictionary: builder.query({
      query: (params) => {
        const baseUrl = `/${Config.VITE_HC_API_VERSION}/dictionaries/${params.id}`;
        const includeParam = params?.include ? `?include=${params.include}` : '';

        return { url: baseUrl + includeParam };
      },
      transformResponse: (response) => response.data,
      providesTags: [ 'Dictionaries' ],
    }),
    getDictionariesFilter: builder.query({
      query: (params) => ({ url: `/${Config.VITE_HC_API_VERSION}/dictionaries/filters/options${makeQueryParams(params.queryParams)}` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'DictionaryFilter' ],
    }),
    createDictionary: builder.mutation({
      query: ({ values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (data) => [ { type: 'Dictionaries', id: data?.id } ],
    }),
    importDictionaries: builder.mutation({
      query: (data) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries/imports`,
        method: 'POST',
        body: data.formData,
      }),
      invalidatesTags: () => [ { type: 'Dictionaries' } ],
    }),
    importDictionary: builder.mutation({
      query: (data) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries/${data.id}/imports`,
        method: 'POST',
        body: data.formData,
      }),
      invalidatesTags: (dictionary) => [ { type: 'Dictionaries', id: dictionary?.id }, { type: 'Dictionary' } ],
    }),
    exportDictionary: builder.query({
      query: (params) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries/${params.id}/exports`,
      }),
    }),
    updateDictionary: builder.mutation({
      query: ({ dictionaryId, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries/${dictionaryId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (dictionary) => [ { type: 'Dictionaries', id: dictionary?.id }, { type: 'Dictionary' } ],
    }),
    deleteDictionary: builder.mutation({
      query: (id) => ({
        url: `${Config.VITE_HC_API_VERSION}/dictionaries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [ { type: 'Dictionaries' }, { type: 'Translations' } ],
    }),
    saveDictionaryTranslations: builder.mutation({
      query: ({ id, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/dictionaries/${id}/translations`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (data) => [ { type: 'Dictionaries', id: data?.id }, { type: 'News' }, { type: 'Features' }, { type: 'FeaturesItem' } ],
    }),
  }),
});

export const {
  useGetDictionariesQuery,
  useGetTranslationsQuery,
  useGetDictionaryQuery,
  useGetDictionariesFilterQuery,
  useCreateDictionaryMutation,
  useUpdateDictionaryMutation,
  useDeleteDictionaryMutation,
  useSaveDictionaryTranslationsMutation,
  useImportDictionariesMutation,
  useImportDictionaryMutation,
  useExportDictionaryQuery,
} = DictionariesApiSlice;
