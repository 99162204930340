import { TYPE_IMAGE } from '../domains/Template builder/const/templates';
import createFileFromURL from './createFileFromURL';



const applyPreset = async (value, values, setFieldValue, presets, templateFields = {}) => {
  await setFieldValue('preset_id', value);
  const preset = presets?.find((preset) => Number(preset.id) === Number(value)) || {};
  const valuesPars = Object.entries(values?.data);

  if (preset?.data) {
    Object.entries(preset?.data).forEach(([ _key, values ]) => {
      const formFieldValue = valuesPars.find((formFieldValue) => Number(formFieldValue[1]?.template_field_id) === Number(values?.template_field_id));

      if (formFieldValue) {
        const field = templateFields.find((field) => Number(field.id) === Number(formFieldValue[1].template_field_id));

        if (field?.type === TYPE_IMAGE) {
          if (values?.value) {
            const fileName = values.value?.split('/').pop();

            createFileFromURL(values?.value, fileName)
              .then((file) => {
                setFieldValue(`data["${formFieldValue[0]}"]`, {
                  template_field_id: formFieldValue[1].template_field_id,
                  value: [ {
                    uid: '-1',
                    name: values?.value,
                    thumbUrl: values?.value,
                    status: 'done',
                    originFileObj: file,
                    url: values?.value,
                  } ],
                });
              });
          } else {
            setFieldValue(`data["${formFieldValue[0]}"]`, {
              template_field_id: formFieldValue[1].template_field_id,
              value: [],
            });
          }
        } else {
          setFieldValue(`data["${formFieldValue[0]}"]`, {
            template_field_id: formFieldValue[1].template_field_id,
            value: values?.value,
          });
        }
      }
    });
  }
};

export default applyPreset;
