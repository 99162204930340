import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import getFeatureConfiguration from '../../utils/getFeatureConfiguration';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import transformErrors from '../../../../lib/transformErrors';
import handleResponse from '../../../../lib/handleResponse';
import FormCreateFeature from '../../forms/FormCreateFeature';
import { useCreateFeatureMutation } from '../../api/featuresApiSlice';
import urlPageFeaturesEdit from '../../../../urls/urlPageFeaturesEdit';



const PageFeatureCreate = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ errors, setFormErrors ] = useState({});

  const [ createFeature, { isLoading: isCreatingFeature } ] = useCreateFeatureMutation();

  const featureSource = getSourceFromUrl(pathname);
  const featureConfig = getFeatureConfiguration(featureSource);

  const onSubmit = (values) => {
    createFeature({ featureSource, values })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [
          () => navigate(urlPageFeaturesEdit({ featureSource, featureId: response?.data?.id })),
        ],
      ))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-create-failed',
          [ () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(transformErrors(error?.data?.errors));
            }
          } ],
          error,
        );
      });
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageFeatures({ featureSource })}>
              <IntlMessages id={getFeatureSourceTranslation(featureSource)} />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormCreateFeature
              initialErrors={errors}
              featureSource={featureSource}
              featureConfig={featureConfig}
              isSubmitting={isCreatingFeature}
              onCancel={() => {}}
              onSubmit={onSubmit}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageFeatureCreate;
