import React from 'react';
import PropTypes from 'prop-types';
import SelectDropdown from './SelectDropdown';
import IntlMessages from '../../../layout/components/lang/IntlMessages';



const ValidationRulesDropdownRender = ({ values, setFieldValue, errors }) => (
  <SelectDropdown
    className="hc-select-dropdown hc-select-dropdown--validation"
    errors={errors}
    value='validation'
    values={values}
    btnMessage={<IntlMessages id="templates-create-form-field-validation-option" />}
    setFieldValue={setFieldValue}
    inputValue='input_validation'
  />
);

ValidationRulesDropdownRender.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ValidationRulesDropdownRender;
