const makeObjectToQueryString = (params) => {
  const filteredParams = Object.entries(params).reduce((acc, [ key, value ]) => {
    // eslint-disable-next-line lodash/prefer-is-nil
    if (value !== null && value !== undefined && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});

  return new URLSearchParams(filteredParams).toString();
};


export default makeObjectToQueryString;
