const translations = {
  'translations-breadcrumbs': 'Переводы',
  'translations-updated-successfully': 'Переводы сохранены успешно',
  'translations-edit': 'Редактировать переводы',
  'translations-import-title': 'Импортировать словарь',
  'translations-import-warning': 'Внимание! Переводы словаря будут заменены на данные из импортируемого файла.',
  'translations-column-field-label': 'Поле',
  'translations-alert-notification': 'Поля автозаполнятся английским языком при смене статуса Draft в Ready, но если потом эти поля убрать либо заменить, то введенные вами данные останутся.',
};

export default translations;
