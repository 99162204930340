import React from 'react';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import HtmlEditor from '../../../../components/HtmlEditor';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';
import Spinner from '../../../../components/Spinner';



const validationSchema = Yup.object().shape({
  title: Yup.string().nullable(),
  text: Yup.string().nullable(),
});


const FormEditPushMessage = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await onSubmit({
          id: values.id,
          title: values.title,
          text: values.text,
        });
      }}
    >
      {({ values, isValid, dirty, setFieldValue }) => {
        return (
          <Form
            layout="vertical"
            className='w-full'
            onKeyDown={makePreventOnKeyDown}
          >
            <Spinner spinning={isSubmitting}>
              <FormItem
                label={intl.formatMessage({ id: 'ui-general-title' })}
                name='title'
                style={{ minHeight: '130px' }}
              >
                <HtmlEditor
                  onChange={(value) => {
                    setFieldValue('title', value);
                  }}
                  initialValue={values?.title ?? ''}
                  init={{
                    height: '125px',
                    plugins: 'preview autosave save directionality charmap pagebreak nonbreaking  wordcount emoticons',
                    toolbar: 'undo redo | bold italic fontsizeselect charmap emoticons',
                    toolbar_mode: 'sliding',
                    menubar: false,
                  }}
                />
              </FormItem>

              <FormItem
                label={intl.formatMessage({ id: 'push-icon-create-form-text-label' })}
                name='text'
                style={{ minHeight: '130px' }}
              >
                <HtmlEditor
                  onChange={(value) => {
                    setFieldValue('text', value);
                  }}
                  initialValue={values?.text ?? ''}
                  init={{
                    height: '125px',
                    plugins: 'preview autosave save directionality charmap pagebreak nonbreaking  wordcount emoticons',
                    toolbar: 'undo redo | bold italic fontsizeselect charmap emoticons',
                    toolbar_mode: 'sliding',
                    menubar: false,
                  }}
                />
              </FormItem>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <Button
                    type='secondary'
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    <IntlMessages id="ui-general-cancel" />
                  </Button>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditPushMessage.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormEditPushMessage;
