import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Avatar, Card, Col } from 'antd';
import dayjs from 'dayjs';
import { switchLanguage } from '../../../app/features/customise/customiseSlice';
import enLang from '../../../assets/images/languages/en.svg';
import ruLang from '../../../assets/images/languages/ru.svg';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';



const HeaderLanguages = () => {
  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();

  // Language
  const [ activeLanguage, setActiveLanguage ] = useState(customise.language);
  const languages = [ 'en', 'ru' ];

  const languageIcon = (lang) => {
    if (lang === 'ru') {
      return ruLang;
    }

    return enLang;
  };

  useEffect(() => {
    setActiveLanguage(customise.language);
  }, [ customise ]);

  return (
    <Col className="hp-languages">
      <div className="hp-d-flex-center">
        <Avatar size={24} src={languageIcon(activeLanguage)} />
        <span className="hp-languages-text hp-text-color-black-80 hp-text-color-dark-30 hp-text-uppercase hp-ml-4">{activeLanguage}</span>
      </div>

      <div className="hp-languages-list">
        <Card className="hp-border-color-black-40 hp-border-radius">
          <Menu>
            {languages.map((lang) => (
              lang !== activeLanguage && (

                <Menu.Item
                  key={lang}
                  onClick={() => {
                    dayjs.locale(lang);
                    dispatch(switchLanguage(lang));
                  }}
                >
                  <div className="hp-d-flex-center">
                    <Avatar size={24} src={languageIcon(lang)} />
                    <span className="hp-languages-text hp-text-color-black-80 hp-text-color-dark-30 hp-text-uppercase hp-ml-4">{lang}</span>
                  </div>
                </Menu.Item>
              )
            ))}
          </Menu>
        </Card>
      </div>
    </Col>
  );
};

export default HeaderLanguages;
