import isEmpty from 'lodash/isEmpty';
import React from 'react';
import dayjs from 'dayjs';
import {
  TYPE_BOOLEAN,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_DICTIONARY,
  TYPE_IMAGE,
  TYPE_OBJECT,
  TYPE_SELECT,
} from '../../Template builder/const/templates';
import IntlMessages from '../../../layout/components/lang/IntlMessages';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../const/system';
import PreviewDictionaryTranslation from '../../Translations/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import ColorPicker from '../../../components/ColorPicker';
import ImageFieldView from '../../Template builder/components/ImageFieldView';



const restoreTemplate = (templateFields = [], dynamicData = [], dictionary = {}, languages = []) => {
  const sortedFields = JSON.parse(JSON.stringify(templateFields)).sort((first, second) => first.position - second.position);

  const recursiveAssign = (templateFields, template = {}) => {
    if (!templateFields.length) return template;

    const [ field, ...restFields ] = templateFields;
    const dynamicField = dynamicData?.find((item) => item?.template_field_id === field?.id);
    const getElement = (dynamicField, field) => {
      let value = (<span className="hp-font-weight-600">{dynamicField?.value || EMPTY_VALUE_PLACEHOLDER}</span>);

      if (dynamicField?.type === TYPE_DATE) {
        value = (<span className="hp-font-weight-600">{!isEmpty(dynamicField?.value) ? dayjs(dynamicField?.value).format(DATE_TIME_FORMAT) : EMPTY_VALUE_PLACEHOLDER}</span>);
      }

      if (dynamicField?.type === TYPE_COLOR) {
        value = (
          <ColorPicker
            defaultValue={dynamicField?.value}
            disabled
          />
        );
      }

      if (dynamicField?.type === TYPE_SELECT) {
        const options = field?.value ? JSON.parse(field.value) : [];
        const selectedOption = options?.find((option) => option.value === dynamicField?.value);

        value = (<span className="hp-font-weight-600">{selectedOption?.label || EMPTY_VALUE_PLACEHOLDER}</span>);
      }

      if (dynamicField?.type === TYPE_IMAGE) {
        value = (<ImageFieldView src={dynamicField?.value} />);
      }

      if (dynamicField?.type === TYPE_BOOLEAN) {
        value = (
          <span className="hp-font-weight-600">
            <IntlMessages id={`${dynamicField?.value ? 'ui-general-on' : 'ui-general-off'}`} />
          </span>
        );
      }

      if (dynamicField?.type === TYPE_DICTIONARY) {
        value = (
          <div className="hp-ml-16">
            {dictionary?.name && (
              <h5>
                <IntlMessages id='ui-general-dictionary' />
                {`: ${dictionary.name}`}
              </h5>
            )}
            <PreviewDictionaryTranslation
              allowEdit={false}
              dictionaryDetails={dictionary}
              languages={languages}
            />
          </div>
        );
      }

      return isEmpty(dynamicField) ? field.name : value;
    };

    if (field.type !== TYPE_OBJECT) {
      template[field.display_name_en] = { ...field,
        key: field.id,
        title: getElement(dynamicField, field) };
    }
    return recursiveAssign(restFields, template);
  };

  return recursiveAssign(sortedFields);
};

export default restoreTemplate;
