import React from 'react';
import PropTypes from 'prop-types';



const DefaultFieldView = ({ value }) => {
  return (
    <span>
      {value}
    </span>
  );
};

DefaultFieldView.propTypes = {
  value: PropTypes.any.isRequired,
};

export default DefaultFieldView;
