import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import config from '../../configs/appConfig';



const HtmlEditor = ({ initialValue, onChange, init }) => {
  const editorRef = useRef(null);

  return (
    <Editor
      apiKey={config.VITE_TINYMCE_EDITOR_API_KEY}
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      value={initialValue}
      onEditorChange={onChange}
      init={init}
    />
  );
};

HtmlEditor.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  init: PropTypes.object,
};

HtmlEditor.defaultProps = {
  initialValue: '',
  init: {
    height: '500px',
    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    menubar: 'file edit view insert format tools table help',
    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  },
};

export default HtmlEditor;
