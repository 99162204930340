import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const rolesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Roles' ],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        return ({ url: `/${Config.VITE_HC_API_VERSION}/roles${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          roles: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Roles' ],
    }),

    getRole: builder.query({
      query: (roleId) => ({ url: `/${Config.VITE_HC_API_VERSION}/roles/${roleId}` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'Roles' ],
    }),

    getRolePermissions: builder.query({
      query: (roleId) => ({ url: `/${Config.VITE_HC_API_VERSION}/roles/${roleId}/permissions` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'Roles' ],
    }),

    createRole: builder.mutation({
      query: (values) => ({
        url: `/${Config.VITE_HC_API_VERSION}/roles`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (role) => [ { type: 'Roles', id: role?.id } ],
    }),

    updateRole: builder.mutation({
      query: ({ values, roleId }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/roles/${roleId}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (role) => [ { type: 'Roles', id: role?.id }, { type: 'Role' } ],
    }),

    updateRolePermissions: builder.mutation({
      query: ({ values, roleId }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/roles/${roleId}/permissions`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (role) => [ { type: 'Roles', id: role?.id }, { type: 'Role' } ],
    }),

    deleteRole: builder.mutation({
      query: (roleId) => ({
        url: `${Config.VITE_HC_API_VERSION}/roles/${roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (role) => [ { type: 'Roles', id: role?.id } ],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useGetRolePermissionsQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useUpdateRolePermissionsMutation,
  useDeleteRoleMutation,
} = rolesApiSlice;
