import isEmpty from 'lodash/isEmpty';
import isNumeric from './isNumeric';



const getDictionaryIdFromDynamicData = (data) => {
  if (!isEmpty(data)) {
    const dictionary = data.filter((item) => item.type === 'dictionary')[0];

    return isNumeric(dictionary?.value) ? Number(dictionary?.value) : null;
  }
  return null;
};


export default getDictionaryIdFromDynamicData;
