import React from 'react';
import IntlMessages from '../layout/components/lang/IntlMessages';



const makeTableLoadingObject = (isLoading) => {
  return {
    spinning: isLoading ?? false,
    tip: <IntlMessages id='ui-general-loading' />,
  };
};


export default makeTableLoadingObject;
