import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useToggle from '../../app/hooks/useToggle';
import IntlMessages from '../../layout/components/lang/IntlMessages';



const PresetPreview = ({ src }) => {
  const intl = useIntl();
  const [ isOpen, toggleOpen ] = useToggle();

  if (!src) {
    return null;
  }

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'ui-general-image-preview' })}
        width="70%"
        centered
        visible={isOpen}
        onCancel={toggleOpen}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <div className="flex hp-d-flex-justify-center hp-d-flex-center">
          <img className="preset-preview--full hp-cursor-pointer" src={src} alt="Preset preview" />
        </div>
      </Modal>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleOpen}>
        <figure>
          <img className="preset-preview hp-cursor-pointer" src={src} alt="Preset preview" />
          <figcaption className="hp-text-color-black-80"><IntlMessages id="presets-preview-image" /></figcaption>
        </figure>
      </div>
    </>
  );
};

PresetPreview.propTypes = {
  src: PropTypes.string,
};

export default PresetPreview;
