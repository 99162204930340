import React from 'react';
import PropTypes from 'prop-types';
import FieldProgress from '../FieldProgress/FieldProgress';
import TranslationInput from '../TranslationInput/TranslationInput';



const TranslationField = ({ field, progress, min, max, handleExpand, handleUpdateValue }) => {
  return (
    <>
      <TranslationInput field={field} handleExpand={handleExpand} handleUpdateValue={handleUpdateValue} />
      {progress && (min || max) ? (
        <FieldProgress text={field.text} min={min} max={max} />
      ) : ''}
    </>
  );
};


TranslationField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.any,
    language_id: PropTypes.number,
    structure_field_id: PropTypes.number,
    dictionary_id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  progress: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  handleExpand: PropTypes.func,
  handleUpdateValue: PropTypes.func,
};

TranslationField.defaultProps = {
  progress: true,
  min: 0,
  max: 0,
};

export default TranslationField;
