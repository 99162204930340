import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { itemsMap } from '../forms/FormCreateDeeplink/FormCreateDeeplink.const';



const prepareLink = (dataObject) => {
  let filled = false;

  const useAsIs = 'external_link';
  const defaultFor = [ 'target_tag', 'pack_open', 'category_open', 'picture_open' ]; // legacy names from old panel
  const defaultParams = '&timer=3&content_target=1&content_mode=soft_reset';

  if (Object.keys(dataObject).includes(useAsIs)) {
    return dataObject[useAsIs];
  }

  const paramStrings = Object.entries(dataObject)
    .filter(([ name, value ]) => value !== '' && name !== 'custom_code' && name !== 'count')
    .map(([ name, value ]) => {
      let paramString = `${name}=${value}`;

      if (defaultFor.includes(name) && !filled) {
        paramString += `${defaultParams}`;
        filled = true;
      }
      return paramString;
    });

  return `coloring://q?${paramStrings.join('&')}`;
};

export const prepareDeeplink = (values) => {
  const link = prepareLink(values);

  return {
    count: values?.count,
    code: values?.custom_code,
    link,
  };
};


export const sortDeeplinkFields = function (values, order) {
  const { count, custom_code } = values;

  const data = order
    .filter((item) => itemsMap[item] in values)
    .reduce((acc, item) => {
      acc[itemsMap[item]] = values[itemsMap[item]];
      return acc;
    }, {});

  if (count) {
    data.count = count;
  }

  if (custom_code) {
    data.custom_code = custom_code;
  }

  return data;
};


export const atLeastOneFieldIsFilled = (value, context) => {
  const { parent } = context;

  return value || parent?.pack_open || parent?.category_open || parent?.theme
    || parent?.bonus_code || parent?.settings_open || parent?.external_link;
};

export const matchUrlRegexp = (value) => {
  // eslint-disable-next-line lodash/prefer-is-nil
  if (isUndefined(value) || isNull(value) || value === '') {
    return true;
  }

  const urlRegex = new RegExp('^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.+)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%_.~+]*)*' + // path
    '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return urlRegex.test(value);
};
