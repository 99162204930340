import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { theme } from '../app/features/customise/customiseSlice';



const Router = () => {
  const routeElements = useRoutes(routes);
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();
  const excludeRoutesToScrollTop = [ 'attached-images' ];

  if (!excludeRoutesToScrollTop.some((item) => window.location.href.includes(item))) {
    window.scrollTo(0, 0);
  }

  // Dark Mode
  useEffect(() => {
    document.querySelector('body').classList.add(customise.theme);
    dispatch(theme(customise.theme));
  }, []);

  return routeElements;
};

export default Router;
