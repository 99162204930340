const achievements = {
  'achievements-breadcrumbs': 'Достижения',
  'achievements-title': 'Достижения',
  'achievements-attach-success': 'Картинка прикреплена к достижению',
  'achievements-attach-failed': 'Ошибка при прикреплении картинки к достижению',
  'achievements-attach-confirm-message': 'Вы уверены, что хотите прикрепить эту картинку к достижению?',
  'achievements-detach-confirm-message': 'Вы уверены, что хотите открепить эту картинку от достижения?',
  'achievements-create-sidebar': 'Создать достижение',
  'achievements-filter-sidebar': 'Фильтр достижений',
  'achievements-attached-images': 'Прикрепленные картинки',
  'achievements-no-attached-images': 'Картинки еще не прикреплены к достижению',
};

export default achievements;
