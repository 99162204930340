import React from 'react';
import PropTypes from 'prop-types';
import SelectDropdown from './SelectDropdown';



const DefaultValueDropdownRender = ({ values, setFieldValue, errors }) => (
  <SelectDropdown
    className="hc-select-dropdown hc-select-dropdown--default-value"
    errors={errors}
    value='default_value'
    values={values}
    btnMessage=''
    setFieldValue={setFieldValue}
    inputValue='input_default_value'
    customInputType
  />
);

DefaultValueDropdownRender.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default DefaultValueDropdownRender;
