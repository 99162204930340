import React, { useRef } from 'react';
import { Card, Col, Row, Table, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import { getLogsListTableColumns } from './PageLogs.const';
import useElementSize from '../../../../../app/hooks/useElementSize';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Sidebar from '../../../../../components/Sidebar';
import ActionButton from '../../../../../layout/components/action-button';
import FormLogsFilter from '../../../forms/FormLogsFilter';
import { useGetLogsQuery } from '../../../api/logsApiSlice';



const { Title } = Typography;

const PageLogs = () => {
  const intl = useIntl();
  const [ elementRef, size ] = useElementSize();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const timestampRef = useRef(Date.now()).current; //hack to reset cache and do force re-fetch

  const searchFilterFields = [ 'id', 'user_id', 'content_type', 'event', 'created_from', 'created_to', 'message' ];

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({ searchFilterFields });

  const {
    data = { data: [], pagination: {}, customOptions: {} },
    isFetching,
    error: formErrors = {},
  } = useGetLogsQuery({
    queryParams: searchParams.toString(),
    sessionId: timestampRef,
  });


  return (
    <Row gutter={32}>
      <Col className="hp-mb-32" span={24}>
        <Row
          gutter={[ 32, 32 ]}
          justify="space-between"
          className="hp-print-none"
        >
          <BreadCrumbs breadCrumbActive={<IntlMessages id='logs-breadcrumbs' />} />

          <Col md={16} span={24}>
            <Row
              gutter={[ 32, 32 ]}
              justify="end"
              align="middle"
            >
              <Col>
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'logs-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormLogsFilter
            initialValues={initFilterValues}
            formErrors={formErrors}
            isSubmitting={isFetching}
            optionsData={data?.customOptions}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Col>

      <Col span={24}>
        <Card className="hp-border-color-black-40 hp-card-6">
          <div ref={elementRef}>
            <Table
              sticky
              scroll={{ x: 860 }}
              loading={makeTableLoadingObject(isFetching)}
              rowKey="id"
              columns={getLogsListTableColumns(size)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};


export default PageLogs;
