const collections = {
  'collections-breadcrumbs': 'Коллекции',
  'collection-details-title': 'Коллекция',
  'collections-filter-title': 'Фильтр коллекций',
  'collection-create-sidebar': 'Создание коллекции',
  'collection-table-content-tooltip-label': 'Нажмите для просмотра всех прикрепленных изображений',
  'collection-form-categories-title': 'Категории',
  'collection-form-image-type-title': 'Тип изображений',
  'collection-form-specify-image-type': 'Выберите тип изображений',
  'collection-no-attached-images': 'Картинки еще не прикреплены к коллекции',
  'collection-attach-success': 'Картинка прикреплена к коллекции',
  'collection-detach-success': 'Картинка убрана из коллекции',
  'collection-attach-failed': 'Ошибка при прикреплении картинки к коллекции',
  'collection-attach-confirm-message': 'Вы уверены, что хотите прикрепить эту картинку к коллекции?',
  'collection-detach-confirm-message': 'Вы уверены, что хотите открепить эту картинку от коллекции?',
  'collection-edit-notification-note': 'Внимание: Если вы хотите отменить публикацию данной коллекции, пожалуйста, обратитесь к администратору.',
  'collection-publish-notification-note': 'Публикация коллекции запрещена, в случае когда к коллекции не прикреплено ни одного изображения.',
  'collections-priority-title': 'Установите правильный порядок коллекций',
  'collections-priority-filter-title': 'Выберите коллекции по дате',
  'collections-delete-notification': 'Удаление невозможно, данная запись уже опубликована или имеет прикрепленные изображения',
  'collection-autoupdate-image-release-dates-info': 'Вы прикрепили изображения с настроенными датами выпуска. Даты выхода изображений будут изменены с выходом новой коллекции. Хотите ли вы продолжить?',
};

export default collections;
