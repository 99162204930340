import React from 'react';
import { Popover, Typography } from 'antd';
import { PiInfo } from 'react-icons/pi';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const { Title } = Typography;

const CreateDeeplinkTitle = () => {
  const content = (
    <div style={{ width: '300px' }}>
      <h5>
        <IntlMessages id="deeplinks-popover-title" />
      </h5>
      <p>
        <IntlMessages id="deeplinks-popover-text-1" />
      </p>
      <p>
        <IntlMessages id="deeplinks-popover-text-2" />
      </p>
      <h5>
        <IntlMessages id="deeplinks-popover-description" />
      </h5>
      <p>
        <IntlMessages id="deeplinks-popover-description-text" />
      </p>
      <h5>
        <IntlMessages id="deeplinks-popover-order" />
      </h5>
      <p>
        <IntlMessages id="deeplinks-popover-order-text" />
      </p>
    </div>
  );

  return (
    <Title level={5} className="hp-d-flex hp-align-items-center">
      <IntlMessages id='deeplinks-create-title' />
      <Popover content={content}>
        <PiInfo size={20} color="blue" className="hp-ml-8" />
      </Popover>
    </Title>
  );
};

export default CreateDeeplinkTitle;
