const users = {
  'users-breadcrumbs': 'Пользователи',
  'user-details-breadcrumbs': 'Детали пользователя',
  'users-create-user-title': 'Создать пользователя',
  'users-search-by': 'Поиск по имени либо почте...',
  'users-table-email-col': 'Почта',
  'users-table-reg-date-col': 'Зарегистрирован',
  'users-table-log-date-col': 'Последний вход',
  'users-table-tooltip-edit-btn': 'Детали пользователя',
  'users-table-tooltip-delete-btn': 'Удалить пользователя',
  'users-table-delete-btn-confirm-message': 'Вы действительно хотите удалить пользователя?',
  'user-details-card-header': 'Детали пользователя',
  'user-details-id-field': 'ID пользователя',
  'user-details-email-field': 'Почтовый адрес',
  'user-details-last-log-field': 'Дата последнего входа',
  'user-details-roles-placeholder': 'У пользователя нет ролей',
  'user-details-permissions-placeholder': 'У пользователя нет прав',
  'user-details-activate-btn': 'Разблокировать',
  'user-details-deactivate-btn': 'Заблокировать',
  'user-details-deactivate-confirm-message': 'Вы действительно хотите заблокировать пользователя?',
  'user-details-activate-confirm-message': 'Вы действительно хотите разблокировать пользователя?',
  'form-create-user-email-placeholder': 'Укажите почтовый адрес пользователя',
  'form-create-user-name-placeholder': 'Укажите имя пользователя',
  'form-create-user-permissions-placeholder': 'Выберите права доступа',
  'form-create-user-roles-placeholder': 'Выберите роли',
};

export default users;
