import React from 'react';
import { Popconfirm, Row } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { UPDATED_IMAGE_STATUSES } from '../../pages/Feeds/PageFeeds/PageFeeds.const';
import ButtonDanger from '../../../../components/ButtonDanger';
import ButtonDraft from '../../../../components/ButtonDraft';



const ImageModalCardFooterUnpublish = ({ handleUpdateImageStatus, releaseDate }) => {
  const unpublishDeadlineTime = dayjs.utc(releaseDate).add(12, 'hour');
  const isAvailableToReturnToDraft = dayjs().isBefore(unpublishDeadlineTime);

  return (
    <>
      <Row key='unpublish'>
        {/* Trash button */}
        {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED ]) && (
          <Popconfirm
            title={<IntlMessages id='feeds-unpublish-modal-trash-confirm' />}
            onConfirm={() => handleUpdateImageStatus(UPDATED_IMAGE_STATUSES.TRASH)}
            okText={<IntlMessages id='ui-general-yes' />}
            cancelText={<IntlMessages id='ui-general-no' />}
          >
            <ButtonDanger
              className='feed-modal__button feed-modal__button--danger hp-mr-16'
              key='trash'
              text={<IntlMessages id='ui-general-trash' />}
            />
          </Popconfirm>
        )}
        {/* Archive button */}
        {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED ]) && (
          <Popconfirm
            title={<IntlMessages id='feeds-unpublish-modal-archive-confirm' />}
            onConfirm={() => handleUpdateImageStatus(UPDATED_IMAGE_STATUSES.ARCHIVE)}
            okText={<IntlMessages id='ui-general-yes' />}
            cancelText={<IntlMessages id='ui-general-no' />}
          >
            <ButtonDanger
              className='feed-modal__button feed-modal__button--danger'
              key='archive'
              text={<IntlMessages id='ui-general-archive' />}
            />
          </Popconfirm>
        )}
      </Row>
      {/* Draft button */}
      {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT ]) && (
        isAvailableToReturnToDraft ? (
          <Popconfirm
            title={<IntlMessages id='feeds-trash-modal-return-to-draft-confirm' />}
            onConfirm={() => handleUpdateImageStatus(UPDATED_IMAGE_STATUSES.DRAFT)}
            okText={<IntlMessages id='ui-general-yes' />}
            cancelText={<IntlMessages id='ui-general-no' />}
          >
            <ButtonDraft
              key='draft'
              className='feed-modal__button feed-modal__button--draft'
            />
          </Popconfirm>
        ) : (
          <ButtonDraft
            hasTooltip={!isAvailableToReturnToDraft}
            key='draft'
            className='feed-modal__button'
          />
        )
      )}
    </>
  );
};

ImageModalCardFooterUnpublish.propTypes = {
  handleUpdateImageStatus: PropTypes.func,
  releaseDate: PropTypes.string,
};

export default ImageModalCardFooterUnpublish;
