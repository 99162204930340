import isEmpty from 'lodash/isEmpty';



export const getFieldKeysOnCurrentTreeLevel = (tree, isEdit, currentNodeHashKey) => {
  if (!currentNodeHashKey) {
    return Object.keys(tree);
  }

  const getFieldKeys = (nodes, currentNodeHashKey) => {
    let targetNode;

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax
    for (const value of Object.values(nodes)) {
      if (value?.hashKey === currentNodeHashKey) {
        targetNode = value;
      }
    }

    if (isEdit && targetNode) {
      return Object.keys(nodes);
    }

    if (targetNode && !isEmpty(targetNode.items)) {
      return Object.keys(targetNode.items);
    }

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax
    for (const key of Object.keys(nodes)) {
      if (nodes[key] && !isEmpty(nodes[key].items)) {
        return getFieldKeys(nodes[key].items, currentNodeHashKey);
      }
    }

    return [];
  };

  return getFieldKeys(tree, currentNodeHashKey);
};
