import appColors from '../../../../const/colors';



export const getLabelsColors = (label) => {
  switch (label) {
    case 'fb': return appColors.blue;
    case 'achv': return appColors.orange;
    case 'hidden': return appColors.darkGray;
    case 'myst': return appColors.lightIndigo;
    case 'cargo': return appColors.info;
    case 'daily': return appColors.darkGold;
    case 'blend': return appColors.blueLink;
    case 'aspca': return appColors.mediumGreen;
    case 'battersea': return appColors.brown;
    case 'make-a-wish': return appColors.pinkRed;
    default: return appColors.gray;
  }
};
