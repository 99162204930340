import React from 'react';
import { Layout, Button, Row, Col } from 'antd';
import { RiMenuFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import HeaderUser from './HeaderUser';
import HeaderLanguages from './HeaderLanguages';
import HeaderText from './HeaderText';



const { Header } = Layout;

const MenuHeader  = ({ setVisible = () => {} }) => {
  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            type="none"
            ghost
            className="hp-mobile-sidebar-button hp-border-none"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
              />
            }
          />
        </Col>

        <HeaderText />

        <Col>
          <Row align="middle">
            <HeaderLanguages />
            <HeaderUser />
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: 'spring', duration: 0.5, delay: 0.1 }}
            className="hp-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
};

MenuHeader.propTypes = {
  setVisible: PropTypes.func,
};

export default MenuHeader;
