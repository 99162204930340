import React from 'react';
import { Badge, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { PiDownload, PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye, Chart } from 'iconsax-react';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import { HasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import urlPageTranslationDetails from '../../../../../urls/urlPageTranslationDetails';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const DICTIONARY_STATUS = {
  DRAFT: 'draft',
  READY: 'ready',
};

export const getDictionariesTableColumns = (handeDelete, handleView, handleChangeStatus, onDownload, navigationSource, multipleSources) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-name" />,
    dataIndex: 'name',
    align: 'center',
    width: '150px',
    sorter: true,
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-dictionaries-source-field" />,
    align: 'center',
    dataIndex: 'structure',
    width: '100px',
    render: (structure) => (<IntlMessages id={`structures-source-${structure.data.source}`} />),
  },
  {
    title: <IntlMessages id="ui-dictionaries-version-field" />,
    align: 'center',
    width: '110px',
    render: (item) => {
      return (
        <div>
          {item.is_upgradable ? (
            <Badge
              color='success'
              count={(
                <Tooltip placement="top" title={<IntlMessages id="dictionaries-new-version-available" />}>
                  <Chart
                    size={12}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                  />
                </Tooltip>
              )}
            >
              <span className="hp-p-12">{item.structure.data.version}</span>
            </Badge>
          ) : (<span>{item.structure.data.version}</span>)}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-status" />,
    align: 'center',
    width: '90px',
    sorter: true,
    dataIndex: 'status',
    render: (value, row) => {
      const isDictionaryReady = value === DICTIONARY_STATUS.READY;

      return (
        <div>
          {isDictionaryReady ? (
            <Tag color="processing"><IntlMessages id='dictionaries-status-ready' /></Tag>
          ) : (
            <Popconfirm
              title={
                <div>
                  <IntlMessages id="dictionaries-update-status-confirm-message" />
                  <br />
                  <span className="hp-input-description"><IntlMessages id="dictionaries-update-status-confirm-message-danger" /></span>
                </div>
              }
              placement="top"
              onConfirm={() => {
                handleChangeStatus(row.id, { status: DICTIONARY_STATUS.READY });
              }}
              okText={<IntlMessages id="ui-general-yes" />}
              cancelText={<IntlMessages id="ui-general-no" />}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            >
              <Tag className='hp-cursor-pointer'><IntlMessages id='dictionaries-status-draft' /></Tag>
            </Popconfirm>
          )}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    width: '110px',
    sorter: true,
    render: (value) => getFormattedDate(value),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '90px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ], true) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Eye
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  onClick={() => {
                    handleView(item);
                  }}
                />
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.EDIT ]) && (
            <Tooltip placement="top" title={<IntlMessages id="translations-edit" />}>
              <div className="hp-text-right">
                <Link to={urlPageTranslationDetails({ id: item.id, source: navigationSource, multipleSources })}>
                  <PiPencil
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.TRANSLATIONS.DICTIONARIES.FILES.EXPORT ]) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-export" />}>
              <div className="hp-text-right">
                <PiDownload
                  onClick={() => {
                    onDownload(item.id);
                  }}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}

          <PrivateComponent allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.DELETE ]}>
            {item.is_removable ? (
              <Popconfirm
                title={<IntlMessages id="ui-general-delete-confirm-message" />}
                placement="top"
                onConfirm={() => {
                  handeDelete(item.id);
                }}
                okText={<IntlMessages id="ui-general-yes" />}
                cancelText={<IntlMessages id="ui-general-no" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                okButtonProps={{ danger: true }}
              >
                <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                  <div className="hp-text-right">
                    <Delete
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            ) : (
              <Tooltip placement="top" title={<IntlMessages id="dictionaries-delete-notification" />}>
                <div className="hp-text-right">
                  <Delete
                    disabled
                    size={20}
                    className="hp-opacity-4 hp-cursor-pointer hp-transition hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            )}
          </PrivateComponent>
        </Row>
      );
    },
  },
];
