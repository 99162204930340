import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Col, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import { useNavigate, useSearchParams } from 'react-router-dom';
import basePagination from '../../../../../const/pagination';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPagePopupCreate from '../../../../../urls/urlPagePopupCreate';
import { Permissions } from '../../../../../const/permissions';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import useToggle from '../../../../../app/hooks/useToggle';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useElementSize from '../../../../../app/hooks/useElementSize';
import getConfigurations from '../../../../../lib/getConfigurations';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import applyFilter from '../../../../../lib/applyFilter';
import handleResponse from '../../../../../lib/handleResponse';
import { getPopupsTableColumns, POPUPS_SOURCE } from './PagePopups.const';
import resetFilter from '../../../../../lib/resetFilter';
import DocumentationAlert from '../../../../../components/DocumentationAlert/DocumentationAlert';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import PreviewDictionaryTranslation from '../../../../Translations/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import FormPopupsFilter from '../../../forms/FormPopupsFilter';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import { useDeletePopupMutation, useGetPopupsQuery } from '../../../api/popupsApiSlice';



const { Title } = Typography;

const PagePopups = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ elementRef, size ] = useElementSize();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ openDictionaryModal, toggleDictionaryModal ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ previewDictionaryId, setPreviewDictionaryId ] = useState({});
  const [ searchQueryParams, setSearchParams ] = useSearchParams();

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'is_published', 'template', 'published_at', 'released_at', 'released_to' ];

  const source = POPUPS_SOURCE;
  const popupsConfig = getConfigurations(source);

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useQueryParams({ searchFields, searchFilterFields });

  const {
    data = { data: [], pagination: {} },
    isFetching,
    error: formErrors = {},
  } = useGetPopupsQuery({ queryParams: searchParams.toString() });

  const { data: languages = { languages: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });
  const [ deletePopup, { isLoading: isDeletingPopup } ] = useDeletePopupMutation();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setResetManuallyTriggered(false);
  };

  const handlePreviewDictionary = (dictionaryId) => {
    setPreviewDictionaryId(dictionaryId);
    toggleDictionaryModal();
  };

  const handleDelete = (popupId) => {
    deletePopup({ popupId })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleClickImage = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  return (
    <>
      <Modal
        width={500}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <img alt="icon" src={previewImage} />
      </Modal>

      <Modal
        title={<IntlMessages id="dictionaries-translation-preview" />}
        width="60%"
        centered
        destroyOnClose
        visible={openDictionaryModal}
        onCancel={toggleDictionaryModal}
        footer={null}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <PreviewDictionaryTranslation
          dictionaryId={previewDictionaryId}
          languages={languages?.languages}
        />
      </Modal>

      <DocumentationAlert link='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/847315004/Popups' />
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='popups-breadcrumbs' />} />

        <Col md={16} span={24}>
          <Row
            gutter={[ 32, 32 ]}
            justify="end"
            align="middle"
          >
            <Col sm={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.CREATE ]}>
                  <ActionButton
                    title={<IntlMessages id='ui-general-create' />}
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                    onClick={() => {
                      navigate(urlPagePopupCreate());
                    }}
                  />
                </PrivateComponent>
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          key={source}
          title={<Title level={5}>{intl.formatMessage({ id: 'popups-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormPopupsFilter
            initialValues={initFilterValues}
            formErrors={formErrors}
            isSubmitting={isFetching}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                setSearchTerm,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <div ref={elementRef}>
              <Table
                sticky
                scroll={{ x: 860 }}
                loading={makeTableLoadingObject(isFetching || isDeletingPopup)}
                rowKey="id"
                columns={
                  getPopupsTableColumns(
                    handlePreviewDictionary,
                    handleDelete,
                    handleClickImage,
                    popupsConfig,
                    size,
                  )
                }
                dataSource={data.data}
                onChange={handleChangeTableParams}
                pagination={{
                  ...basePagination,
                  current: pagination.page,
                  pageSize: pagination.limit,
                  total: data.pagination?.total,
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PagePopups;
