import { Empty } from "antd";
import React from "react";
import { EMPTY_VALUE_PLACEHOLDER } from "../../const/system";


const EmptyDataPlaceholder = ({ placeholder }) => {
  return (
    <div className='centered-item w-full'>
      <Empty
        description={
          placeholder ? (<div>{placeholder}</div>) : EMPTY_VALUE_PLACEHOLDER
        }
      />
    </div>
  )
};

export default EmptyDataPlaceholder;
