const structures = {
  'structures-breadcrumbs-item': 'Edit structure',
  'structures-details-title': 'Edit structure',
  'structures-details-preview': 'Preview structure',
  'structures-preview-warning': 'Structure is not available!',
  'structures-source-field': 'Source',
  'structures-version-field': 'Version',
  'form-structures-description-placeholder': 'Describe the source',
  'form-structures-add-field': 'Add field',
  'validation-structures-min-fields': 'At least 1 field is required',
  'validation-structures-fields-validation-min-number': 'Min must be a number',
  'validation-structures-fields-validation-max-number': 'Max must be a number',
  'structures-fields-delete-confirm-message': 'Are you sure you want to delete the field?',
  'structures-source-old news': 'Old news',
  'structures-source-captions': 'Captions',
  'structures-source-news': 'News',
  'structures-source-daily': 'Daily',
  'structures-source-categories': 'Categories',
  'structures-source-editors-choice': 'Editor\'s choice',
  'structures-source-external-promo': 'External promo',
  'structures-source-btn-featured': 'Button featured',
  'structures-source-popups': 'Popups',
  'structures-source-popup': 'Popups',
  'structures-source-btn-cta': 'Cta button',
  'structures-source-btn-yes-no': 'Yes/No buttons',
  'structures-source-stories': 'Stories',
  'structures-source-collections': 'Collections',
  'structures-source-collection': 'Collection',
  'structures-source-charity': 'Charity',
};

export default structures;
