import restoreTemplate from './restoreTemplate';
import { TYPE_DICTIONARY } from '../../Template builder/const/templates';



const mkPreparedTemplateData = ({
  templateData = [],
  dynamicData = [],
  dictionary = {},
  languages = [],
}) => {
  const result = { templateValues: [], additionalValues: [], dictionaryValues: [] };
  const template = restoreTemplate(templateData, dynamicData, dictionary, languages);

  const templateArr = Object.values(template);

  if (templateArr.length > 0) {
    templateArr.map((item) => {
      if (item.type !== TYPE_DICTIONARY) {
        if (item.is_main) {
          return result.templateValues.push(item);
        }
        return result.additionalValues.push(item);
      }
      return result.dictionaryValues.push(item);
    });
  }
  return result;
};

export default mkPreparedTemplateData;
