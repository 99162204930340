import React from 'react';
import { Form, FormItem, Input, InputNumber, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import { InfoCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { imageTypeOptions } from '../../../../const/imageTypes';
import { imageStatusOptions } from '../../../../const/imageStatuses';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import useCategoriesOptions from '../../../../app/hooks/useCategoriesOptions';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';
import Spinner from '../../../../components/Spinner';
import { useGetCategoriesQuery } from '../../api/categoriesApiSlice';
import { useGetTagsQuery } from '../../api/tagsApiSlice';



const FormImagesFilter = ({
  initialValues,
  formErrors = {},
  onSubmit,
  onCancel,
  isSubmitting,
  excludedFields,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  const { data: categories = { data: [] }, isLoading: isCategoriesLoading } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });

  const { data: tags = { data: [] }, isLoading: isTagsLoading } = useGetTagsQuery({
    queryParams: 'limit=0',
  });

  const categoryOptions = useCategoriesOptions(categories?.data);

  if (isCategoriesLoading || isTagsLoading) {
    return (<div className="h-mt-6 text-bold"><IntlMessages id="content-image-filter-loading" /></div>);
  }

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        const { is_main_category, ...restValues } = values;

        onSubmit(is_main_category === 0 ? restValues : values);
      }}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form
            className="images-filter w-full"
            layout="vertical"
            onKeyDown={makePreventOnKeyDown}
          >
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    name='id'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="id"
                      placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                      disabled={!isEmpty(values.folder)}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    name='folder'
                    className="ant-form-item-col stretched"
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-bee-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="features-filter-bee-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                  >
                    <Input
                      name="folder"
                      placeholder={intl.formatMessage({ id: 'content-images-folder-name-placeholder' })}
                      disabled={!isEmpty(values.id)}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-category-label' })}
                    name="category"
                    className="hp-mb-8"
                  >
                    <Select
                      name="category"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'ui-general-choose-categories' })}
                      options={categoryOptions}
                      loading={isCategoriesLoading}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    />
                  </FormItem>

                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-only-main-category-label' })}
                    name="main_category"
                    style={{
                      flexDirection: 'row-reverse',
                      alignItems: 'end',
                    }}
                  >
                    <Input
                      checked={values?.main_category ?? false}
                      id="main_category"
                      type="checkbox"
                      name="main_category"
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-format-label' })}
                    name="image_type"
                  >
                    <Select
                      name="image_type"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'content-images-format-label-placeholder' })}
                      options={imageTypeOptions}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    />
                  </FormItem>
                </Col>
                {!excludedFields?.includes('status') && (
                  <Col span={24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-status' })}
                      name="status"
                    >
                      <Select
                        name="status"
                        mode="multiple"
                        allowClear
                        showSearch
                        placeholder={intl.formatMessage({ id: 'content-images-status-placeholder' })}
                        options={imageStatusOptions}
                        filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                      />
                    </FormItem>
                  </Col>
                )}

                <Col span={24}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-hashtags-label' })}
                    name="tags"
                  >
                    <Select
                      name="tags"
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder={intl.formatMessage({ id: 'content-images-hashtags-label-placeholder' })}
                      loading={isTagsLoading}
                      options={tags.data.map((item) => {
                        return {
                          label: capitalize(item.tag),
                          value: item.id,
                        };
                      })}
                      filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-taps-from-label' })}
                    name='taps_from'
                  >
                    <InputNumber
                      min={0}
                      name="taps_from"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-min' })}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-taps-to-label' })}
                    name='taps_to'
                  >
                    <InputNumber
                      min={0}
                      name="taps_to"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-max' })}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-colors-from-label' })}
                    name='colors_from'
                  >
                    <InputNumber
                      min={0}
                      name="colors_from"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-min' })}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-colors-to-label' })}
                    name='colors_to'
                  >
                    <InputNumber
                      min={0}
                      name="colors_to"
                      className="w-full"
                      placeholder={intl.formatMessage({ id: 'ui-general-max' })}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-imported-from-label' })}
                    name='imported_from'
                  >
                    <DatePicker
                      name="imported_from"
                      value={values?.imported_from ? moment(values.imported_from) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('imported_from', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await onBlur('imported_from', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'content-images-imported-to-label' })}
                    name='imported_to'
                  >
                    <DatePicker
                      name="imported_to"
                      value={values?.imported_to ? moment(values.imported_to) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('imported_to', dateString);
                      }}
                      onBlur={async ({ target }) => {
                        await onBlur('imported_to', target.value, setFieldValue);
                      }}
                    />
                  </FormItem>
                </Col>

                {!excludedFields?.includes('released_from') && (
                  <Col span={12}>
                    <FormItem
                      label={intl.formatMessage({ id: 'content-images-released-from-label' })}
                      name='released_from'
                    >
                      <DatePicker
                        name="imported_from"
                        value={values?.released_from ? moment(values.released_from) : null}
                        onChange={(date, dateString) => {
                          setFieldValue('released_from', dateString);
                        }}
                        onBlur={async ({ target }) => {
                          await onBlur('released_from', target.value, setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                )}

                {!excludedFields?.includes('released_to') && (
                  <Col span={12}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-released-to' })}
                      name='released_to'
                    >
                      <DatePicker
                        name="released_to"
                        value={values?.released_to ? moment(values.released_to) : null}
                        onChange={(date, dateString) => {
                          setFieldValue('released_to', dateString);
                        }}
                        onBlur={async ({ target }) => {
                          await onBlur('released_to', target.value, setFieldValue);
                        }}
                      />
                    </FormItem>
                  </Col>
                )}

              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormImagesFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  excludedFields: PropTypes.array,
};

export default FormImagesFilter;
