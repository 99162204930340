import makeUniq from 'lodash/uniq';



const makePreparedAuthData = (data) => {
  const { roles, permissions, ...userData } = data;
  const userRolePermissions = roles?.data?.map((role) => role.permissions.data).flat() || [];
  const userPermissions = permissions?.data || [];
  const permissionsArr = makeUniq([ ...userRolePermissions, ...userPermissions ]);

  return {
    user: userData || {},
    roles: roles?.data?.map((role) => role.name).flat() || [],
    permissions: permissionsArr?.map((item) => item.name) || [],
  };
};

export default makePreparedAuthData;
