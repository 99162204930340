import React from 'react';
// eslint-disable-next-line lodash/import-scope
import { cloneDeep, isNull } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { DictionariesApiSlice } from '../../../api/dictionariesApiSlice';
import appColors from '../../../../../const/colors';



export const getTranslationListTableColumns = (languages, handleRenderTranslationCell) => {
  return [
    {
      title: <IntlMessages id='translations-column-field-label' />,
      dataIndex: 'field',
      width: '150px',
      fixed: 'left',
      render: (val, field) => {
        return (
          <>
            {val}
            {field?.validation?.required && (
              <span style={{ color: appColors.error }}> *</span>
            )}
          </>
        );
      },
    },
    ...(languages?.map((language) => {
      return {
        title: language.name,
        dataIndex: language.code,
        width: language.code === 'ENG' ? 220 : 200,
        render: (val, field) => handleRenderTranslationCell(language, field),
      };
    }) ?? []),
  ];
};

export const handleUpdateTranslationValue = (newValue, field, setTranslationData) => {
  setTranslationData((prevTranslationData) => {
    const data = cloneDeep(prevTranslationData);
    const condition = (el) => el.structure_field_id === field.field_id && el.language_id === field.language_id;
    const elIndex = data.findIndex((el) => condition(el));
    const translationItem = elIndex !== -1 ? data?.splice(elIndex, 1)[0] : null;

    if (!translationItem) {
      prevTranslationData = [
        ...data,
        {
          id: null,
          structure_field_id: field.field_id,
          language_id: field.language_id,
          text: newValue,
          label: field.label,
        },
      ];
    } else {
      translationItem.text = newValue;
      prevTranslationData = [ ...data, translationItem ];
    }

    return prevTranslationData;
  });
};



export const handleSelectPrevTranslation = (dictionary_id, field, dispatch, setTranslationData, callback) => {
  dispatch(DictionariesApiSlice.endpoints.getDictionary.initiate({ id: dictionary_id, include: 'translations' })).then((output) => {

    const translations = !isEmpty(output?.data?.translations) ? output?.data.translations?.data.filter((tr) => tr.label === field?.label) : [];

    setTranslationData((translateData) => {
      // Clear current translations
      const clearedTranslations = translateData
        .filter((tr) => tr.label === field.label)
        .map((tr) => {
          return {
            dictionary_id: tr.dictionary_id,
            structure_field_id: tr.structure_field_id,
            language_id: tr.language_id,
            id: tr.id,
            text: null,
            label: tr.label,
          };
        });

      // Set translations to fields
      translations.forEach((tr) => {
        const trField = clearedTranslations.find((otr) => otr.language_id === tr.language_id);

        if (trField) {
          trField.text = tr.text;
        } else {
          clearedTranslations.push({
            dictionary_id: field.dictionary_id,
            language_id: tr.language_id,
            structure_field_id: field.field_id,
            label: tr.label,
            text: tr.text,
          });
        }
      });

      translateData = [
        ...translateData.filter((tr) => tr.label !== field.label),
        ...clearedTranslations,
      ].filter((data) => !isNull(data.text));


      return translateData;
    });
    callback();
  });
};

export const getDataSource = (dictionary, structure, languages) => structure.fields.data.map((structureField) => {
  const sourceField = {
    id: structureField.id,
    key: structureField.id,
    field: structureField.label,
    validation: structureField.validation,
  };

  dictionary.translations.data
    .filter((translation) => translation.structure_field_id === structureField.id)
    .forEach((translation) => {
      sourceField[languages.find((lng) => lng.id === translation.language_id)?.code] = {
        text: translation.text,
        id: translation.id,
        label: translation.label,
        dictionary_id: translation.dictionary_id,
      };
    });

  return sourceField;
});
