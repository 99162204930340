import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Popconfirm, Row } from 'antd';
import { RiErrorWarningLine } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import avatar from '../../../../assets/images/memoji/memoji-2.svg';
import UserDetailsRow from '../UserDetailsRow';
import { useApp } from '../../../../app/context/AppContext';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import UserStatus from '../UserStatus';



const UserDetailsCard = ({ userDetails, handleChangeStatus = () => {}, toggleEditUserModal = () => {} }) => {
  const { userId } = useApp();
  const currentUser = userDetails.id === userId;

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col>
          <h3><IntlMessages id="user-details-card-header" /></h3>
        </Col>

        <Col>
          {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_STATUS ]) && !currentUser && (
            <Popconfirm
              title={<IntlMessages id={userDetails.is_active ? 'user-details-deactivate-confirm-message' : 'user-details-activate-confirm-message'} />}
              okText={<IntlMessages id="ui-general-yes" />}
              onConfirm={handleChangeStatus}
              okButtonProps={{ danger: true }}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            >
              <Button
                ghost
                className='hp-mr-8'
                type="primary"
              >
                <IntlMessages
                  id={userDetails.is_active ? 'user-details-deactivate-btn' : 'user-details-activate-btn'}
                />
              </Button>
            </Popconfirm>
          )}

          {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER ]) && (
            <Button
              type="primary"
              onClick={toggleEditUserModal}
            >
              <IntlMessages id='ui-general-edit' />
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <img
            src={userDetails?.avatar ?? avatar} alt='user-avatar-img'
            className='user-details-img hp-my-24'
          />
        </Col>

        <Col sm={{ offset: 2 }} className="hp-profile-content-list hp-mt-32">
          <ul>
            <UserDetailsRow title={<IntlMessages id="user-details-id-field" />}>
              {userDetails?.id || EMPTY_VALUE_PLACEHOLDER}
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="ui-general-name" />}>
              {userDetails?.name || EMPTY_VALUE_PLACEHOLDER}
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="user-details-email-field" />}>
              <a href={`mailto:${userDetails?.email}`}>
                {userDetails?.email || EMPTY_VALUE_PLACEHOLDER}
              </a>
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="ui-general-status" />}>
              <UserStatus status={userDetails?.is_active} />
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="ui-general-created-at" />}>
              {getFormattedDate(userDetails.created_at, DATE_TIME_FORMAT)}
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="ui-general-updated-at" />}>
              {getFormattedDate(userDetails.updated_at, DATE_TIME_FORMAT)}
            </UserDetailsRow>
            <UserDetailsRow title={<IntlMessages id="user-details-last-log-field" />}>
              {getFormattedDate(userDetails.last_login, DATE_TIME_FORMAT)}
            </UserDetailsRow>
          </ul>
        </Col>
      </Row>
    </>
  );
};


UserDetailsCard.propTypes = {
  userDetails: PropTypes.shape({
    is_active: PropTypes.number,
    avatar: PropTypes.any,
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    last_login: PropTypes.string,
  }).isRequired,
  handleChangeStatus: PropTypes.func,
  toggleEditUserModal: PropTypes.func,
};

export default UserDetailsCard;
