import React from 'react';
import PropTypes from 'prop-types';
import { imageStatuses } from '../../../../const/imageStatuses';



const ImageBadge = ({ image }) => {
  const { is_copy, is_reopened, status }  = image;
  const isBadgeVisible = is_copy || is_reopened || status === imageStatuses.archive;

  const imageStatus = () => {
    if (is_copy) {
      return 'Copy';
    }
    if (is_reopened) {
      return 'Reopened';
    }
    if (status === imageStatuses.archive) {
      return 'Image archived';
    }
    return '';
  };

  if (!isBadgeVisible) {
    return null;
  }

  return (
    <div className="card-top-cover-badge">{imageStatus()}</div>
  );
};

ImageBadge.propTypes = {
  image: PropTypes.shape({
    is_copy: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]).isRequired,
    is_reopened: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]).isRequired,
    status: PropTypes.string.isRequired,
  }),
};

export default ImageBadge;
