import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Row, Col, Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { Setting } from 'react-iconly';
import Config from '../configs/appConfig';
import { FEATURE_SHOW_TEST_MODE } from '../const/features';
import getTestModeParams from '../lib/getTestModeParams';
import Sidebar from './components/menu/Sidebar';
import MenuHeader from './components/header';
import MenuFooter from './components/footer';
import ScrollTop from './components/scroll-to-top';
import ModalTestersMode from '../domains/Auth/components/ModalTestersMode';
import { refreshUserData } from '../domains/Auth/features/authSlice';
import { useRefreshUserMutation } from '../domains/Auth/api/authApiSlice';
import { useGetUsersQuery } from '../domains/RBAC/api/usersApiSlice';



const { Content } = Layout;

const VerticalLayout = () => {
  const dispatch = useDispatch();
  const testModeParams = getTestModeParams();
  const developerId = testModeParams?.developer_id ?? null;
  const isActiveTestMode = !!developerId;
  const [ refreshUser, { isLoading: isRefreshUserLoading } ] = useRefreshUserMutation();
  const [ visible, setVisible ] = useState(false);
  const [ isActiveTestersModeModal, setToggleTestersModeModal ] = useState(false);

  // Redux
  const customise = useSelector((state) => state.customise);

  const { data = { data: [], pagination: {} }, isLoading: isLoadingUsers } = useGetUsersQuery({
    queryParams: 'limit=0',
    customHeaders: { 'x-api-developer': Config.VITE_TEST_MODE_TOKEN },
  }, { skip: !FEATURE_SHOW_TEST_MODE });


  const toggleTestersModeModal = () => {
    setToggleTestersModeModal(!isActiveTestersModeModal);
  };

  const handleRefreshUserData = () => {
    refreshUser().unwrap().then((res) => {
      dispatch(refreshUserData(res));
      toggleTestersModeModal();
    });
  };

  const handleStopTestMode = () => {
    localStorage.removeItem('test_mode_params');
    handleRefreshUserData();
  };

  const handleApplyTestMode = (values) => {
    const requestParams = { is_developer: Config.VITE_TEST_MODE_TOKEN, developer_id: values.developer_id };

    localStorage.setItem('test_mode_params', JSON.stringify(requestParams));
    handleRefreshUserData();
  };

  return (
    <Layout className="hp-app-layout">
      <ModalTestersMode
        isActiveModal={isActiveTestersModeModal}
        onCancel={toggleTestersModeModal}
        initialValues={{ developer_id: developerId ?? null }}
        formErrors={[]}
        data={data?.users}
        isActiveTestMode={isActiveTestMode}
        handleApplyTestMode={handleApplyTestMode}
        handleStopTestMode={handleStopTestMode}
        isSubmitting={isRefreshUserLoading}
      />

      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout className="hp-bg-black-20 hp-bg-color-dark-90">
        <MenuHeader setVisible={setVisible} />

        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth === 'full' && (
              <Col xxl={17} xl={22} span={24}>
                <Outlet />
              </Col>
            )}

            {customise.contentWidth === 'boxed' && (
              <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                <Outlet />
              </Col>
            )}
          </Row>
        </Content>

        {!isActiveTestersModeModal && FEATURE_SHOW_TEST_MODE && (
          <Tooltip placement="left" title='Test mode'>
            <div className='test-settings'>
              <Button
                type="secondary"
                shape="circle"
                icon={<Setting />}
                loading={isLoadingUsers || isRefreshUserLoading}
                disabled={isLoadingUsers || isRefreshUserLoading}
                className={`ant-btn-icon-only ant-btn-circle da-primary-shadow ${isActiveTestMode ? 'progress-btn-circle' : 'warning-btn-circle'}`}
                onClick={toggleTestersModeModal}
              />
            </div>
          </Tooltip>
        )}

        <MenuFooter />
      </Layout>

      <ScrollTop />
    </Layout>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.any,
};

export default VerticalLayout;
