import React from 'react';
import { Descriptions } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageTemplateDetails from '../../../../urls/urlPageTemplateDetails';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { Permissions } from '../../../../const/permissions';
import { HasRights } from '../../../../components/HasRights/HasRights';
import useCheckMobileScreen from '../../../../app/hooks/useCheckMobileScreen';
import getFormattedDate from '../../../../lib/getFormattedDate';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import getFeatureSourceTranslation from '../../utils/getFeatureSourceTranslation';
import PublishedTag from '../../../../components/PublishedTag';
import StatusTag from '../../../../components/StatusTag';



const FeaturedDetailsCard = ({ data, featureConfig }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const featureSource = getSourceFromUrl(pathname);
  const isMobile = useCheckMobileScreen(600);

  return (
    <Descriptions
      title={intl.formatMessage({ id: getFeatureSourceTranslation(featureSource) })}
      layout={isMobile ? 'vertical' : 'horizontal'}
      bordered
      className="hp-mb-32"
      column={1}
    >
      <Descriptions.Item className='width-20-vw' label="ID">{data?.id}</Descriptions.Item>
      <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>
        {data?.title ?? EMPTY_VALUE_PLACEHOLDER}
      </Descriptions.Item>
      <Descriptions.Item label={<IntlMessages id="ui-general-description" />}>
        {data?.description ?? EMPTY_VALUE_PLACEHOLDER}
      </Descriptions.Item>
      <Descriptions.Item label={<IntlMessages id="ui-general-template" />}>
        {!isEmpty(data?.template?.data?.title) ? (
          <div>
            {HasRights([ Permissions.CONSTRUCTORS.TEMPLATES.FIND ]) ? (
              <Link to={urlPageTemplateDetails({ templateId: data?.template_id })} target="_blank">
                {data?.template?.data?.title}
              </Link>
            ) : (
              <div>{data?.template?.data?.title}</div>
            )}
          </div>
        ) : EMPTY_VALUE_PLACEHOLDER}
      </Descriptions.Item>
      <Descriptions.Item label={<IntlMessages id="ui-general-published" />}><PublishedTag published={Boolean(data?.is_published)} publishedDate={data?.published_at} /></Descriptions.Item>
      {featureConfig?.config?.show_in_featured_enabled && (
        <Descriptions.Item label={<IntlMessages id="features-table-featured-column" />}><StatusTag active={Boolean(data?.is_in_featured)} /></Descriptions.Item>
      )}
      <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>
        {getFormattedDate(data?.created_at, DATE_TIME_FORMAT)}
      </Descriptions.Item>
      <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>
        {getFormattedDate(data?.updated_at, DATE_TIME_FORMAT)}
      </Descriptions.Item>
      {!isEmpty(data?.published_at) && (
        <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
          {getFormattedDate(data?.published_at, DATE_TIME_FORMAT)}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<IntlMessages id="ui-general-released-at" />}>
        {getFormattedDate(data?.released_at, DATE_TIME_FORMAT)}
      </Descriptions.Item>
      {featureConfig?.config?.release_range && (
        <Descriptions.Item label={<IntlMessages id="ui-general-released-to" />}>
          {getFormattedDate(data?.released_to, DATE_TIME_FORMAT)}
        </Descriptions.Item>
      )}
    </Descriptions>
  );

};

FeaturedDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  featureConfig: PropTypes.object.isRequired,
};

export default FeaturedDetailsCard;
