import { Link } from 'react-router-dom';
import { RiInformationLine } from 'react-icons/ri';
import { Alert } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import appColors from '../../const/colors';



const DocumentationAlert = ({ link = '#', message = '' }) => {
  const intl = useIntl();

  return (
    <Alert
      className="hp-mb-32"
      style={{ marginTop: '-20px' }}
      message={message}
      description={
        <span>
          {intl.formatMessage({ id: 'features-title-notification' })}
          &nbsp;
          <Link
            style={{ color: appColors.blueLink }}
            to={link}
            target="_blank"
          >
            https://x-flow-ltd.atlassian.net/wiki/...
          </Link>
        </span>
      }
      type="info"
      showIcon
      icon={<RiInformationLine className="remix-icon" size={24} />}
    />
  );
};

DocumentationAlert.propTypes = {
  link: PropTypes.string,
  message: PropTypes.string,
};


export default DocumentationAlert;
