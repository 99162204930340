const getPriorityUpdateButtonTooltipMessage = (hasNoOrderChanges = false, isPrevPriority = false) => {
  if (isPrevPriority) {
    return 'features-priority-update-past-disabled';
  }
  if (hasNoOrderChanges) {
    return 'features-priority-update-no-order-changed';
  }
  return 'ui-general-restricted';
};

export default getPriorityUpdateButtonTooltipMessage;
