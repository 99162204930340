import { EMPTY_VALUE_PLACEHOLDER } from '../../../const/system';
import { FEATURES_SOURCE_MAP } from '../pages/PageFeatures/PageFeatures.const';



const getFeatureNotificationLink = (source) => {
  switch (source) {
    case FEATURES_SOURCE_MAP.DAILY: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/714735625/Daily';
    case FEATURES_SOURCE_MAP.EDITOR_CHOICE: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/714997766/Editor%27s+choice';
    case FEATURES_SOURCE_MAP.EXTERNAL_PROMO: return 'https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/715030535/External+promo';

    default: return EMPTY_VALUE_PLACEHOLDER;
  }
};

export default getFeatureNotificationLink;
