const sortTreeDataByParentProperty = (prev, next) => {
  if (!prev.hasOwnProperty('parent') && next.hasOwnProperty('parent')) {
    return -1;
  }

  if (prev.hasOwnProperty('parent') && !next.hasOwnProperty('parent')) {
    return 1;
  }

  if (prev.hasOwnProperty('parent') && next.hasOwnProperty('parent')) {
    return prev.parent.data.id - next.parent.data.id;
  }

  return prev.name - next.name;
};

export default sortTreeDataByParentProperty;
