import { useEffect, useState } from 'react';



const useDebounce = (value, milliSeconds) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds || 500);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, milliSeconds ]);

  return debouncedValue;
};

export default useDebounce;
