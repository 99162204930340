import isEmpty from 'lodash/isEmpty';



const getFeatureConfiguration = (sourceName) => !isEmpty(localStorage.getItem('featured_sources')) ?
  JSON.parse(localStorage.getItem('featured_sources'))
    .filter((item) => item.source_name === sourceName)[0] : {};


export default getFeatureConfiguration;
