import { FEEDS_SOURCE_MAP, MODAL_MODE } from '../domains/Content/pages/Feeds/PageFeeds/PageFeeds.const';



const getModalTitleByModalMode = (modalMode) => {
  const width = 1200;

  if ([ MODAL_MODE.EDIT ].includes(modalMode)) {
    return 1400;
  }

  if ((FEEDS_SOURCE_MAP.TRASHED || FEEDS_SOURCE_MAP.ARCHIVED) && modalMode === MODAL_MODE.REMOVE) {
    return 800;
  }

  return width;
};

export default getModalTitleByModalMode;
