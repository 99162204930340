import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';



// eslint-disable-next-line react/jsx-props-no-spreading
const InjectMassage = (props) => <FormattedMessage {...props} />;

export default injectIntl(InjectMassage, {
  withRef: false,
});
