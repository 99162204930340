import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Yup from '../../../../vendor/yup';



export const makeValidationSchema = (collectionConfig, collection = {}) => Yup.lazy((values) => Yup.object().shape({
  use_utc_zero_time: Yup.boolean().nullable(),
  released_at: Yup.string().required('Required to specify released at date')
    .test(
      'test_date_after',
      'Released at date must be same or before than released to',
      (value) => {
        if (!isEmpty(values?.released_to) && !isEmpty(value)) {
          return dayjs(values?.released_to).isSameOrAfter(value);
        }
        return true;
      },
    ).test(
      'test_date_in_collection_bounds_release_dates',
      'Image released at date cannot be less than actual collection released at date',
      (value) => {
        if (!isEmpty(collection?.released_at) && !isEmpty(value)) {
          return dayjs(value).isSameOrAfter(dayjs(collection?.released_at));
        }

        return true;
      },
    ),
  ...collectionConfig?.config?.release_range && {
    released_to: Yup.string()
      .test(
        'test_date_before',
        'Released to date must be same or after than released at',
        (value) => {
          if (!isEmpty(values?.released_at) && !isEmpty(value)) {
            return dayjs(values?.released_at).isSameOrBefore(value);
          }
          return true;
        },
      ).test(
        'test_date_in_collection_bounds_release_dates',
        'Image released to date cannot be more than actual collection released to date',
        (value) => {
          if (!isEmpty(collection?.released_to) && !isEmpty(value)) {
            return dayjs(value).isSameOrBefore(dayjs(collection?.released_to));
          }

          return true;
        },
      ),
  },
}));
