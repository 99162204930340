const getPacksOptions = (packs) => {
  let packsData = [ ...packs.data ];

  packsData = packsData.map((item) => {
    return {
      label: item.label,
      value: item.id,
    };
  });

  return packsData;
};

export default getPacksOptions;
