import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const FeedsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Feeds' ],
  endpoints: (builder) => {
    return ({
      getFeeds: builder.query({
        query: (params) => {
          const { feedSource, queryParams } = params;
          const queryData = makeQueryParams(queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/feeds/${feedSource}${queryData}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            infiniteScroll: {
              has_more: response.meta.custom.has_more,
              offset_date: response.meta.custom.offset_date,
              offset_id: response.meta.custom.offset_id,
            },
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Feeds' ],
      }),
    });
  },
});

export const {
  useGetFeedsQuery,
} = FeedsApiSlice;
