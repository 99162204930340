import layout_en_US from '../../layout/translations/layout_en_US';
import sidebar_en_US from '../../layout/components/menu/translations/sidebar_en_US';
import users_en_US from '../../domains/RBAC/translations/Users/users_en_US';
import roles_en_US from '../../domains/RBAC/translations/Roles/roles_en_US';
import permissions_en_US from '../../domains/RBAC/translations/Permissions/permissions_en_US';
import profile_en_US from '../../domains/Profile/translations/Profile/profile_en_US';
import languages_en_US from '../../domains/Translations/translations/languages_en_US';
import structures_en_US from '../../domains/Translations/translations/structures_en_US';
import dictionaries_en_US from '../../domains/Translations/translations/dictionaries_en_US';
import translations_en_US from '../../domains/Translations/translations/translations_en_US';
import pushIcons_en_US from '../../domains/Other/translations/pushIcons_en_US';
import content_en_US from '../../domains/Content/translations/content_en_US';
import deeplinks_en_US from '../../domains/Other/translations/deeplinks_en_US';
import news_en_US from '../../domains/Content/translations/news_en_US';
import newsPriority_en_US from '../../domains/Content/translations/newsPriority_en_US';
import templates_en_US from '../../domains/Template builder/translations/templates_en_US';
import dynamicNews_en_US from '../../domains/Content/translations/dynamicNews_en_US';
import categories_en_US from '../../domains/Content/translations/categories_en_US';
import achievements_en_US from '../../domains/Content/translations/achievements_en_US';
import bonuses_en_US from '../../domains/Content/translations/bonuses_en_US';
import features_en_US from '../../domains/Featured/translations/features_en_US';
import featuresPriority_en_US from '../../domains/Featured/translations/featuresPriority_en_US';
import presets_en_US from '../../domains/Template builder/translations/presets_en_US';
import popups_en_US from '../../domains/Other/translations/popups_en_US';
import logs_en_US from '../../domains/RBAC/translations/Logs/logs_en_US';
import collections_en_US from '../../domains/Content/translations/collections_en_US';
import feeds_en_US from '../../domains/Content/translations/feeds_en_US';



const en_EN = {
  'ui-general-search': 'Search...',
  'ui-general-search-by-title': 'Search by title...',
  'ui-general-action': 'Action',
  'ui-general-back': 'Back',
  'ui-general-show': 'Show',
  'ui-general-back-to-list': 'Back to list',
  'ui-general-bee-id': 'Bee ID',
  'ui-general-id': 'ID',
  'ui-general-ids-placeholder': 'Specify Id(s)',
  'ui-general-id-placeholder': 'Specify Id',
  'ui-general-template-data': 'Template data',
  'ui-general-additional-parameters': 'Additional parameters',
  'ui-general-optional-data': 'Optional data',
  'ui-general-min': 'Min',
  'ui-general-max': 'Max',
  'ui-general-min-version': 'Min version',
  'ui-general-max-version': 'Max version',
  'ui-general-platform': 'Platform',
  'ui-general-choose-platform': 'Choose platform',
  'ui-general-countries': 'Countries',
  'ui-general-choose-countries': 'Choose countries',
  'ui-general-preview': 'Preview',
  'ui-general-content': 'Content',
  'ui-general-title': 'Title',
  'ui-general-edit': 'Edit',
  'ui-general-save': 'Save',
  'ui-general-save-and-publish': 'Save & Publish',
  'ui-general-update': 'Update',
  'ui-general-view': 'View',
  'ui-general-delete': 'Delete',
  'ui-general-create': 'Create',
  'ui-general-remove': 'Remove',
  'ui-general-status': 'Status',
  'ui-general-content-type': 'Content type',
  'ui-general-content-type-placeholder': 'Specify content type',
  'ui-general-draft-images': 'Draft',
  'ui-general-action-draft': 'Return to draft',
  'ui-general-ready-release-on': 'Released',
  'ui-general-priority': 'Priority',
  'ui-general-main-info': 'Main info',
  'ui-general-template': 'Template',
  'ui-general-user': 'User',
  'ui-general-specify-title': 'Specify title',
  'ui-general-choose-template': 'Choose template',
  'ui-general-choose-status': 'Choose status',
  'ui-general-choose-content-type': 'Choose content type',
  'ui-general-preset': 'Preset',
  'ui-general-choose-preset': 'Choose preset',
  'ui-general-choose-labels': 'Choose labels',
  'ui-general-type': 'Type',
  'ui-general-format': 'Format',
  'ui-general-dictionary': 'Dictionary',
  'ui-general-categories': 'Categories',
  'ui-general-choose-categories': 'Choose categories',
  'ui-general-choose-type': 'Choose type',
  'ui-general-choose-hashtags': 'Choose hashtags',
  'ui-general-specify-value': 'Specify value',
  'ui-general-cancel': 'Cancel',
  'ui-general-submit': 'Submit',
  'ui-general-apply': 'Apply',
  'ui-general-copy': 'Copy',
  'ui-general-reset': 'Reset',
  'ui-general-copied': 'Copied',
  'ui-general-download': 'Download',
  'ui-general-reopen': 'Reopen',
  'ui-general-clone': 'Clone',
  'ui-general-import': 'Import',
  'ui-general-export': 'Export',
  'ui-general-ok': 'Ok',
  'ui-general-name': 'Name',
  'ui-general-specify-name': 'Specify name',
  'ui-general-not-published': 'Not published',
  'ui-general-display-name': 'Display name',
  'ui-general-description': 'Description',
  'ui-general-specify-description': 'Specify description',
  'ui-general-logout': 'Log out',
  'ui-general-yes': 'Yes',
  'ui-general-no': 'No',
  'ui-general-created-successfully': 'Data is successfully created',
  'ui-general-create-failed': 'Failed to create data',
  'ui-general-updated-successfully': 'Data is successfully updated',
  'ui-general-update-failed': 'Failed to update data',
  'ui-general-deleted-successfully': 'Data is successfully deleted',
  'ui-general-delete-failed': 'Failed to delete data',
  'ui-general-validation-required': 'Field is required',
  'ui-general-required': 'Required',
  'ui-general-optional': 'Optional',
  'ui-general-label': 'Label',
  'ui-general-labels': 'Labels',
  'ui-general-hashtags': 'Hashtags',
  'ui-general-created-from': 'Created from',
  'ui-general-created-to': 'Created to',
  'ui-general-created-at': 'Created at',
  'ui-general-updated-at': 'Updated at',
  'ui-general-published-at': 'Published at',
  'ui-general-roles': 'Roles',
  'ui-general-permissions': 'Permissions',
  'ui-general-code': 'Code',
  'ui-general-specify-code': 'Specify code',
  'ui-general-specify-codes': 'Specify code(s)',
  'ui-general-home': 'Home',
  'ui-general-languages': 'Languages',
  'ui-general-structures': 'Structures',
  'ui-general-dictionaries': 'Dictionaries',
  'ui-general-list': 'List',
  'ui-general-delete-confirm-message': 'Are you sure you want to delete this data?',
  'ui-general-filter': 'Filter',
  'ui-general-total': 'Total',
  'ui-general-loading': 'Loading. Please wait...',
  'ui-general-success': 'Success',
  'ui-general-error': 'Error',
  'ui-general-all': 'All',
  'ui-general-on': 'On',
  'ui-general-off': 'Off',
  'ui-general-restricted': 'You don\'t have access rights to make this action',
  'ui-general-attach-image': 'Attach Image',
  'ui-general-attach-image-click': 'Click on image to attach',
  'ui-general-detach-image': 'Detach Image',
  'ui-general-no-images-found': 'No images found',
  'ui-general-published-from': 'Published from',
  'ui-general-published-to': 'Published to',
  'ui-general-released-at': 'Released at',
  'ui-general-released-to': 'Released to',
  'ui-general-release-date': 'Release date',
  'ui-general-published': 'Published',
  'ui-general-invalid-date': 'Invalid date format',
  'ui-general-documentation-link': 'Documentation link',
  'ui-general-without-category': 'Without category',
  'ui-general-select-all': 'Select all',
  'ui-general-filter-id-key-help': 'Allowed to specify IDs separated by commas or spaces',
  'ui-general-filter-code-key-help': 'Allowed to specify Codes separated by commas or spaces',
  'ui-general-upload-image': 'Upload image',
  'ui-general-was-not-published': 'Data has never been published',
  'ui-general-last-published-at': 'Last published at',
  'ui-general-unpublish': 'Unpublish',
  'ui-general-trash': 'Trash',
  'ui-general-archive': 'Archive',
  'ui-general-unpublished-successfully': 'Unpublish successfully',
  'ui-general-unpublish-failed': 'Failed to unpublish',
  'ui-general-unpublished-tooltip-title': 'Has unpublished changes',
  'ui-general-image-preview': 'Image preview',
  'ui-general-no-attached-images': 'There is no attached images for this data',
  'ui-general-targets': 'Targets',
  'ui-general-comment': 'Comment',
  'ui-general-not-selected': 'Not selected',
  ...sidebar_en_US,
  ...layout_en_US,
  ...news_en_US,
  ...features_en_US,
  ...users_en_US,
  ...roles_en_US,
  ...permissions_en_US,
  ...profile_en_US,
  ...languages_en_US,
  ...structures_en_US,
  ...dictionaries_en_US,
  ...translations_en_US,
  ...pushIcons_en_US,
  ...deeplinks_en_US,
  ...content_en_US,
  ...newsPriority_en_US,
  ...templates_en_US,
  ...dynamicNews_en_US,
  ...categories_en_US,
  ...achievements_en_US,
  ...featuresPriority_en_US,
  ...presets_en_US,
  ...popups_en_US,
  ...bonuses_en_US,
  ...logs_en_US,
  ...collections_en_US,
  ...feeds_en_US,
};

export default en_EN;
