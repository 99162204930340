import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const PopupsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Popups', 'PopupsPriority', 'PopupsItem' ],
  endpoints: (builder) => {
    return ({
      getPopups: builder.query({
        query: (params) => {
          const queryData = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/popups${queryData}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Popups' ],
      }),
      getSpecificPopup: builder.query({
        query: (params) => {
          const baseUrl = `/${Config.VITE_HC_API_VERSION}/popups/${params.id}`;
          const includeParam = params?.include ? `?include=${params.include}` : '';

          return { url: baseUrl + includeParam };
        },
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'PopupsItem' } ],
      }),
      createPopup: builder.mutation({
        query: ({ values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/popups`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Popups', 'PopupsPriority', 'Templates', 'Template' ],
      }),
      updatePopup: builder.mutation({
        query: ({ popupId, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/popups/${popupId}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Popups', 'PopupsPriority', 'PopupsItem', 'Template' ],
      }),
      deletePopup: builder.mutation({
        query: ({ popupId }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/popups/${popupId}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Popups', 'PopupsPriority', 'Template' ],
      }),
      unpublishPopup: builder.mutation({
        query: ({ popupId }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/popups/${popupId}/unpublish`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'Popups', 'PopupsPriority', 'PopupsItem' ],
      }),
      getPopupsPriority: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/popups/settings/priorities${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'PopupsPriority' ],
      }),
      updatePopupsPriority: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/popups/settings/priorities`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ { type: 'PopupsPriority' } ],
      }),
    });
  },
});

export const {
  useGetPopupsQuery,
  useGetSpecificPopupQuery,
  useCreatePopupMutation,
  useUpdatePopupMutation,
  useDeletePopupMutation,
  useUnpublishPopupMutation,
  useGetPopupsPriorityQuery,
  useUpdatePopupsPriorityMutation,
} = PopupsApiSlice;
