import React from 'react';
import { makeNotification, notificationTypes } from './makeNotification';



const handleResponse = (responseType, intl, messageId, callbacks = [], error = {}) => {
  let message;

  switch (responseType) {
    case 'error':
      message = (
        <>
          <p className="hp-mb-4">
            {intl.formatMessage({ id: messageId })}
          </p>
          {error?.data?.message && <p>{ error.data.message }</p>}
        </>
      );

      break;
    case 'success':
      message = intl.formatMessage({ id: messageId });

      break;
    default:
      message = intl.formatMessage({ id: messageId });
  }

  makeNotification(
    notificationTypes[responseType],
    intl.formatMessage({ id: `ui-general-${responseType}` }),
    message,
  );

  callbacks.forEach((callback) => callback());
};

export default handleResponse;
