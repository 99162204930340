import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Divider, Modal, Row, Space, Spin, Typography } from 'antd';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import { RiCloseFill } from 'react-icons/ri';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageFeeds from '../../../../../urls/urlPageFeeds';
import useToggle from '../../../../../app/hooks/useToggle';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import encodeUnsafeCharacters from '../../../../../lib/encodeUnsafeCharacters';
import getFeedsNotificationLink from '../../../utils/getFeedsNotificationLink';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Sidebar from '../../../../../components/Sidebar';
import DocumentationAlert from '../../../../../components/DocumentationAlert/DocumentationAlert';
import FormFeedsFilter from '../../../forms/FormFeedsFilter';
import Spinner from '../../../../../components/Spinner';
import ContentCard from '../../../components/ContentCard';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { DATE_FORMAT } from '../../../../../const/system';
import {
  CONTENT_TYPE_MAP,
  FEEDS_SOURCE_MAP,
  getFeedsSourceTranslation,
  getTypeContentGradient,
  getTypeContentRedirectUrl,
  MODAL_MODE,
} from './PageFeeds.const';
import ButtonDanger from '../../../../../components/ButtonDanger';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import ImageModalCard from '../../../components/ImageModalCard';
import getModalTitleByModalMode from '../../../../../lib/getModalTitleByModalMode';
import getModalWidthByModalMode from '../../../../../lib/getModalWidthByModalMode';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';



const { Title } = Typography;

const PageFeeds = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ isRefetch, setIsRefetch ] = useState(false);

  const searchFilterFields = [ 'id', 'bee_id', 'categories', 'is_main_category', 'layouts', 'hashtags', 'formats', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'released_from', 'released_to' ];
  const feedSource = getSourceFromUrl(pathname);
  const prevSourceRef = useRef(feedSource);
  const isTrashOrArchiveSource = [ FEEDS_SOURCE_MAP.ARCHIVED, FEEDS_SOURCE_MAP.TRASHED ].includes(feedSource);

  const { data: {
    data,
    infiniteScroll: scrollParams = {},
  } = { data: [] },
  isFetching,
  error: formErrors = {},
  } = useGetFeedsQuery({ feedSource, queryParams });

  const {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    scrollParams,
    searchFilterFields,
    source: feedSource,
    isRefetch,
  });

  const isLoading = isFetching;

  const handleSetFilterToCurrentDate = () => {
    setInitFilterValues({ released_to: moment().format(DATE_FORMAT) });
  };

  useEffect(() => {
    const prevSource = prevSourceRef.current;

    if (!resetIsManuallyTriggered || prevSource !== feedSource) {
      prevSourceRef.current = feedSource;

      if (!isTrashOrArchiveSource) {
        if (!isEmpty(initFilterValues) && prevSource === feedSource) {
          const searchQuery = Object.entries(initFilterValues)
            .map(([ key, value ]) => `${key}:${encodeUnsafeCharacters(value)}`)
            .join(';');

          setSearchParams({ search: searchQuery }, { replace: true });
        } else {
          handleSetFilterToCurrentDate();
        }
      }
    }
  }, [ initFilterValues, resetIsManuallyTriggered, feedSource ]);

  useEffect(() => {
    setQuery(!isEmpty(combinedQueryParams) ? combinedQueryParams : '');
  }, [ combinedQueryParams ]);

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  const actions = (item) => {
    if (feedSource === FEEDS_SOURCE_MAP.RELEASED && item.source_type) {
      return [ (
        <div key='source'>
          {item.source_id && item.source_type !== CONTENT_TYPE_MAP.FEED ? (
            <Link to={getTypeContentRedirectUrl(item.source_type, item.source_id)} target="_blank">
              <div
                className="card-type-content-source-action"
                style={{ background: getTypeContentGradient(item.source_type) }}
              >
                {item.source_type}
              </div>
            </Link>
          ) : (
            <div
              className="card-type-content-source-action"
              style={{ background: getTypeContentGradient(item.source_type), cursor: 'default' }}
            >
              {item.source_type}
            </div>
          )}
        </div>
      ) ];
    }

    if (feedSource === FEEDS_SOURCE_MAP.LIST) {
      return [
        HasRights([
          Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
          Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
          Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
        ]) && (
          <ButtonDanger
            disabled={!isEmpty(item?.used_in_collections) || !isEmpty(item?.used_in_editor_choices)}
            className='btn-danger'
            key='unpublish'
            onClick={() => {
              setPreviewImage(item);
              setModalMode(MODAL_MODE.UNPUBLISH);
              togglePreviewModal();
            }}
            hasTooltip={!isEmpty(item?.used_in_collections) || !isEmpty(item?.used_in_editor_choices)}
          />
        ),
      ];
    }

    if (feedSource === FEEDS_SOURCE_MAP.ARCHIVED || feedSource === FEEDS_SOURCE_MAP.TRASHED) {
      const permission = feedSource === FEEDS_SOURCE_MAP.ARCHIVED
        ? [ Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED ]
        : [ Permissions.CONTENT.FEEDS.IMAGE.SET_DELETE ];

      return [
        HasRights(permission) && (
          <ButtonDanger
            className='btn-danger'
            key='remove'
            text={<IntlMessages id='ui-general-remove' />}
            onClick={() => {
              setPreviewImage(item);
              setModalMode(MODAL_MODE.REMOVE);
              togglePreviewModal();
            }}
          />
        ),
      ];
    }

    return [];
  };

  return (
    <>
      <Modal
        className='feed-modal'
        width={getModalWidthByModalMode(modalMode)}
        title={getModalTitleByModalMode(modalMode, intl)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <ImageModalCard
          data={previewImage}
          modalMode={modalMode}
          handleModalClose={handlePreviewModalClose}
          handleRefetch={(isRefetch) => setIsRefetch(isRefetch)}
          handleModalMode={(mode) => handleChangeModalMode(mode)}
          isFeeds
        />
      </Modal>

      <DocumentationAlert link={getFeedsNotificationLink(feedSource)} />
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={feedSource !== FEEDS_SOURCE_MAP.LIST ? (
            <Link to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}>
              <IntlMessages id='sidebar-content-feeds' />
            </Link>
          ) : ''}
          breadCrumbActive={<IntlMessages id={getFeedsSourceTranslation(feedSource)} />}
        />

        <Col>
          <Space>
            <ActionButton
              onClick={toggleShowContour}
              title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
            />
            <ActionButton
              title=""
              isActive={!isEmpty(searchQueryParams.toString())}
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          key={feedSource}
          title={<Title level={5}>{intl.formatMessage({ id: 'ui-general-filter' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFeedsFilter
            feedSource={feedSource}
            isSubmitting={isFetching}
            formErrors={formErrors}
            initialValues={initFilterValues}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                callbacks: [ () => {
                  if (!isTrashOrArchiveSource && isEmpty(initFilterValues)) {
                    handleSetFilterToCurrentDate();
                  }
                } ],
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      {isLoading && isEmpty(groupedItemsList) ? (
        <div className="spin-overlay">
          <div className="spin-wrapper">
            <Spinner spinning={isLoading} />
          </div>
        </div>
      ) : (
        <div id="scrollableDiv" style={{ height: window.innerHeight - 330, overflow: 'auto' }}>
          {!isEmpty(groupedItemsList) ? (
            <InfiniteScroll
              dataLength={itemsLength}
              next={handleLoadNextData}
              scrollableTarget="scrollableDiv"
              scrollThreshold='200px'
              hasMore={hasMore}
              loader={isFetching && (
                <div className='centered-item'><Spin /></div>
              )}
            >
              {!isTrashOrArchiveSource ? (
                <>
                  {Object.keys(groupedItemsList).map((date) => {
                    return (
                      <div key={date} className='hp-mb-32'>
                        <h4>{date}</h4>
                        <Divider />
                        <div className='content-card-grid'>
                          {groupedItemsList[date].map((item) => (
                            <ContentCard
                              key={item.id}
                              data={item}
                              showContour={showContour}
                              actions={actions(item)}
                              onClick={handleCardClick}
                              allowImageClick
                            />
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className='content-card-grid'>
                  {itemsArray.map((item) => (
                    <ContentCard
                      key={item.id}
                      data={item}
                      showContour={showContour}
                      onClick={handleCardClick}
                      actions={actions(item)}
                      allowImageClick
                    />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <div className='hp-mt-64'>
              <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PageFeeds;
