const presets = {
  'presets-breadcrumbs': 'Пресеты',
  'presets-filter-title': 'Фильтр пресетов',
  'presets-is-not-actual': 'Пресет должен быть обновлен',
  'presets-table-author-column': 'Автор',
  'presets-form-id-placeholder': 'Укажите id(s)',
  'presets-form-title-placeholder': 'Укажите название пресета',
  'presets-form-template-type': 'Тип контента шаблона',
  'presets-form-template-type-placeholder': 'Выберите тип контента шаблона',
  'presets-template-details': 'Данные пресета',
  'presets-template-hint': 'Шаблонные поля будут заполнены данными из выбранного пресета',
  'presets-is-actual': 'Актуально',
  'presets-form-preview-hint': 'Загрузите изображение, на котором демонстрируется как выглядит данный пресет в приложении',
  'presets-form-preview-label': 'Загрузите файл изображение',
  'presets-preview-image': '* Изображение является приблизительным представлением того, как оно будет выглядеть в приложении.',
};

export default presets;
