import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const LanguagesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Languages' ],
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        return ({ url: `/${Config.VITE_HC_API_VERSION}/languages${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          languages: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Languages' ],
    }),

    createLanguage: builder.mutation({
      query: (values) => ({
        url: `/${Config.VITE_HC_API_VERSION}/languages`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (language) => [ { type: 'Languages', id: language?.id } ],
    }),

    updateLanguage: builder.mutation({
      query: ({ id, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/languages/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (language) => [ { type: 'Languages', id: language?.id } ],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
} = LanguagesApiSlice;
