const achievements = {
  'bonuses-breadcrumbs': 'Bonuses',
  'bonus-details-title': 'Bonus',
  'bonuses-filter-title': 'Filter bonuses',
  'bonus-create-sidebar': 'Create bonus',
  'bonus-table-content-tooltip-label': 'Click to view all attached images',
  'bonus-no-attached-images': 'Images does not attached to current bonus',
  'bonus-attach-success': 'Image attached to bonus',
  'bonus-attach-failed': 'Attach image to bonus failed',
  'bonus-attach-confirm-message': 'Are you sure you want to attach this image to bonus?',
  'bonus-detach-confirm-message': 'Are you sure you want to detach this image from bonus?',
  'bonus-edit-notification-note': 'Note: If you want to unpublish current bonus, please contact the administrator.',
  'bonus-publish-notification-note': 'Bonus publish is not allowed in case when there is no any attached images.',
};

export default achievements;
