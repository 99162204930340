import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const usersApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Users' ],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        return {
          url: `/${Config.VITE_HC_API_VERSION}/users${queryParams}`,
          headers: params.customHeaders,
        };
      },
      transformResponse: (response) => {
        return {
          users: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Users' ],
    }),

    getUser: builder.query({
      query: (queryArg) => {
        let queryParameters = Object.entries(queryArg.queryParams)
          .map(([ key, value ]) => `${key}=${value}`)
          .join('&');

        if (queryParameters.length > 0) {
          queryParameters = `?${queryParameters}`;
        }

        return { url: `/${Config.VITE_HC_API_VERSION}/users/${queryArg.userId}${queryParameters}` };
      },
      transformResponse: (response) => response.data,
      providesTags: [ 'User' ],
    }),

    getUserPermissions: builder.query({
      query: (userId) => ({ url: `/${Config.VITE_HC_API_VERSION}/users/${userId}/permissions?limit=0` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'Users' ],
    }),

    getUserRoles: builder.query({
      query: (userId) => ({ url: `/${Config.VITE_HC_API_VERSION}/users/${userId}/roles?limit=0` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'Users' ],
    }),

    createUser: builder.mutation({
      query: (values) => ({
        url: `/${Config.VITE_HC_API_VERSION}/users`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id } ],
    }),

    updateUser: builder.mutation({
      query: (values) => ({
        url: `/${Config.VITE_HC_API_VERSION}/users/${values.userId}`,
        method: 'PUT',
        body: values.data,
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id }, { type: 'User' } ],
    }),

    updateUserStatus: builder.mutation({
      query: (values) => ({
        url: `/${Config.VITE_HC_API_VERSION}/users/${values.userId}/statuses`,
        method: 'PUT',
        body: values.data,
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id }, { type: 'User' } ],
    }),

    updateUserRoles: builder.mutation({
      query: ({ userId, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/users/${userId}/roles?include=permissions`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id }, { type: 'User' } ],
    }),

    updateUserPermissions: builder.mutation({
      query: ({ userId, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/users/${userId}/permissions`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id }, { type: 'User' } ],
    }),

    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${Config.VITE_HC_API_VERSION}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (user) => [ { type: 'Users', id: user?.id } ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
  useUpdateUserRolesMutation,
  useUpdateUserPermissionsMutation,
  useDeleteUserMutation,
} = usersApiSlice;
