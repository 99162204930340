import React from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { Form, FormItem, Input, ResetButton, SubmitButton, Radio } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { validationSchema } from './FormFeaturesFilter.const';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { DATE_FORMAT } from '../../../../const/system';
import { FEATURES_SOURCE_MAP } from '../../pages/PageFeatures/PageFeatures.const';



const FormFeaturesFilter = ({
  initialValues = {},
  formErrors = {},
  onSubmit,
  onCancel,
  isSubmitting = false,
  featureConfig = {},
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();
  const location = useLocation();
  const { pathname } = location;
  const featureSource = getSourceFromUrl(pathname);

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={formErrors}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form layout="vertical" >
            <FormItem
              name='id'
              className="ant-form-item-col stretched"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="ui-general-id" />
                  <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              }
            >
              <Input
                name="id"
                placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
              />
            </FormItem>
            {featureSource !== FEATURES_SOURCE_MAP.EXTERNAL_PROMO && (
              <>
                <FormItem
                  name='image_id'
                  className="ant-form-item-col stretched"
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="features-form-image-id-label" />
                      <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name="image_id"
                    placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                    disabled={!isEmpty(values.source_id)}
                  />
                </FormItem>
                <FormItem
                  name='source_id'
                  className="ant-form-item-col stretched"
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="ui-general-bee-id" />
                      <Tooltip placement="topRight" title={<IntlMessages id="features-filter-bee-id-key-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name="source_id"
                    placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                    disabled={!isEmpty(values.image_id)}
                  />
                </FormItem>
              </>
            )}
            <FormItem
              label={intl.formatMessage({ id: 'ui-general-title' })}
              name='title'
            >
              <Input
                name="title"
                placeholder={intl.formatMessage({ id: 'ui-general-specify-title' })}
              />
            </FormItem>

            <Row gutter={[ 16, 16 ]}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-released-at' })}
                  name='released_at'
                >
                  <DatePicker
                    name='released_at'
                    format={DATE_FORMAT}
                    showToday
                    value={values?.released_at ? moment(values.released_at) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('released_at', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await setFieldTouched('released_at', true);
                      await onBlur('released_at', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-released-to' })}
                  name='released_to'
                >
                  <DatePicker
                    name='released_to'
                    format={DATE_FORMAT}
                    value={values?.released_to ? moment(values.released_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('released_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await setFieldTouched('released_to', true);
                      await onBlur('released_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='start'>
              {featureConfig?.config?.show_in_featured_enabled && (
                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'features-form-show-featured-label' })}
                    name='is_in_featured'
                  >
                    <Radio.Group
                      size='small'
                      name='is_in_featured'
                      defaultValue={null}
                    >
                      <Radio.Button value={null}>{intl.formatMessage({ id: 'ui-general-all' })}</Radio.Button>
                      <Radio.Button value={1}>{intl.formatMessage({ id: 'ui-general-yes' })}</Radio.Button>
                      <Radio.Button value={0}>{intl.formatMessage({ id: 'ui-general-no' })}</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              )}

              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published' })}
                  name='is_published'
                >
                  <Radio.Group
                    size='small'
                    name='is_published'
                    defaultValue={null}
                  >
                    <Radio.Button value={null}>{intl.formatMessage({ id: 'ui-general-all' })}</Radio.Button>
                    <Radio.Button value={1}>{intl.formatMessage({ id: 'ui-general-yes' })}</Radio.Button>
                    <Radio.Button value={0}>{intl.formatMessage({ id: 'ui-general-no' })}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-apply" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormFeaturesFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  featureConfig: PropTypes.object.isRequired,
};


export default FormFeaturesFilter;
